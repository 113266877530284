<template>
  <div>
    <div class="header">
      <div class="filters">
        <a-row type="flex" :gutter="12">
          <a-col :span="24" :lg="4" class="5">
            <a-form-item label="Имя клиента">
              <a-input placeholder="Имя клиента" v-model="filters.customerName"/>
            </a-form-item>
            <a-form-item label="Номер телефона">
              <a-input placeholder="Номер телефона" v-model="filters.customerPhone"/>
            </a-form-item>
          </a-col>

          <a-col :span="24" :lg="3" class="mb-5">
            <a-form-item label="Мин. заказов">
              <a-input placeholder="Мин. заказов" v-model="filters.min_orders"/>
            </a-form-item>
            <a-form-item label="Макс. заказов">
              <a-input placeholder="Макс. заказов" v-model="filters.max_orders"/>
            </a-form-item>
          </a-col>

          <a-col :span="24" :lg="3" class="mb-5">
            <a-form-item label="Мин. выручка">
            <a-input placeholder="Мин. выручка" v-model="filters.min_proceeds"/>
            </a-form-item>
            <a-form-item label="Макс. выручка">
            <a-input placeholder="Макс. выручка" v-model="filters.max_proceeds"/>
            </a-form-item>
          </a-col>

          <a-col :span="24" :lg="3" class="mb-5">
            <a-form-item label="Мин. средний чек">
              <a-input placeholder="Мин. средний чек" v-model="filters.min_avg_cost"/>
            </a-form-item>
            <a-form-item label="Макс. средний чек">
              <a-input placeholder="Макс. средний чек" v-model="filters.max_avg_cost"/>
            </a-form-item>
          </a-col>

          <a-col :span="24" :lg="3" class="mb-5">
            <a-form-item label="Первый заказ с">
              <a-input type="date" placeholder="Первый заказ с" v-model="filters.min_first_order"/>
            </a-form-item>
            <a-form-item label="Первый заказ по">
              <a-input type="date" placeholder="Первый заказ по" v-model="filters.max_first_order"/>
            </a-form-item>
          </a-col>

          <a-col :span="24" :lg="4" class="mb-5">
            <a-form-item label="Последний заказ от">
              <a-input placeholder="Последний заказ от" v-model="filters.min_last_order_days"/>
            </a-form-item>
            <a-form-item label="Последний заказ до">
              <a-input placeholder="Последний заказ до" v-model="filters.max_last_order_days"/>
            </a-form-item>
          </a-col>

<!--          <a-col :span="24" :lg="4" class="mb-5">-->
<!--            <a-form-item label="Последние посещение от">-->
<!--              <a-input placeholder="Последние посещение от" v-model="filters.customerName"/>-->
<!--            </a-form-item>-->
<!--            <a-form-item label="Последний посещение до">-->
<!--              <a-input placeholder="Последний посещение до" v-model="filters.customerName"/>-->
<!--            </a-form-item>-->
<!--          </a-col>-->

        </a-row>
      </div>
    </div>
    <div class="content_flex">
      <div>
        <table>
          <tr>
<!--            <th>№</th>-->
            <th>Имя</th>
            <th>Телефон</th>
            <th>Заказов</th>
            <th>Выручка</th>
            <th>Первый заказ</th>
            <th>Последний заказ</th>
            <th>Retention</th>
          </tr>
          <tbody>
          <customer v-for="(customer, customerKey) in FilteredCustomers" :data="customer" :index="customerKey" :key="customerKey"/>
          </tbody>
        </table>
      </div>
      <div>
        <segments :default-segments="defaultSegments" :segments="segments"/>
      </div>
    </div>
  </div>
</template>

<script>
import customer from '@/components/customer'
import segments from '@/components/Marketing/segments'
import MarketingAPI from "../../../../api/MarketingAPI";
import PartnerID from '@/mixins/partnerID';

export default {
  mixins: [PartnerID],
  data(){
    return{
      partner_id: null,
      customers: [],
      segments: [],
      defaultSegments: [],
      FilteredCustomers: [],
      filters: {
        customerName: undefined,
        customerPhone: undefined,
        min_orders: undefined,
        max_orders: undefined,
        min_proceeds: undefined,
        max_proceeds: undefined,
        min_avg_cost: undefined,
        max_avg_cost: undefined,
        min_first_order: undefined,
        max_first_order: undefined,
        min_last_order_days: undefined,
        max_last_order_days: undefined,
      }
    }
  },
  components:{
    customer,
    segments
  },
  watch: {
    customers: {
      handler(val){
        this.filterCustomers();
      },
      immediate: true
    },
    filters: {
      handler(val){
        this.filterCustomers();
      },
      deep: true,
    }
  },
  methods: {
    filterCustomers: function(){
      let customers = this.customers;

      if (this.filters.customerPhone){
        customers = customers.filter(customer => ((customer.phone).toLowerCase()).indexOf((this.filters.customerPhone).toLowerCase()) !== -1);
      }

      if (this.filters.customerName){
        customers = customers.filter(customer => ((customer.name).toLowerCase()).indexOf((this.filters.customerName).toLowerCase()) !== -1);
      }

      if(this.filters.min_orders){
        customers = customers.filter(customer => customer.orders_count >= this.filters.min_orders)
      }

      if(this.filters.max_orders){
        customers = customers.filter(customer => customer.orders_count <= this.filters.max_orders)
      }

      if(this.filters.min_proceeds){
        customers = customers.filter(customer => customer.orders_amount >= this.filters.min_proceeds)
      }

      if(this.filters.max_proceeds){
        customers = customers.filter(customer => customer.orders_amount <= this.filters.max_proceeds)
      }

      if(this.filters.min_avg_cost){
        customers = customers.filter(customer => ((customer.orders_amount / customer.orders_count) >= this.filters.min_avg_cost))
      }

      if(this.filters.max_avg_cost){
        customers = customers.filter(customer => ((customer.orders_amount / customer.orders_count) <= this.filters.max_avg_cost))
      }

      if(this.filters.min_first_order){
        customers = customers.filter(customer => customer.first_order_date >= this.filters.min_first_order)
      }

      if(this.filters.max_first_order){
        customers = customers.filter(customer => customer.first_order_date <= this.filters.max_first_order)
      }

      if(this.filters.min_last_order_days){
        customers = customers.filter(customer => Math.floor((new Date ().getTime() - (new Date (customer.last_order_date)).getTime())/(1000*60*60*24)) >= this.filters.min_last_order_days)
      }

      if(this.filters.max_last_order_days){
        customers = customers.filter(customer => Math.floor((new Date ().getTime() - (new Date (customer.last_order_date)).getTime())/(1000*60*60*24)) <= this.filters.max_last_order_days)
      }

      this.FilteredCustomers = customers;
    },
    makeFilter: function (data) {
      this.filters = {
        customerName: null,
        customerPhone: null,
        min_orders: data.min_orders,
        max_orders: data.max_orders,
        min_proceeds: data.min_proceeds,
        max_proceeds: data.max_proceeds,
        min_avg_cost: data.min_avg_cost,
        max_avg_cost: data.max_avg_cost,
        min_first_order: data.min_first_order,
        max_first_order: data.max_first_order,
        min_last_order_days: data.min_last_order_days,
        max_last_order_days: data.max_last_order_days,
      }
    },
    getCustomersBase: function (){
      MarketingAPI.get_customers_base()
          .then(response => {
            response.data.status === 401 ? this.$store.commit('User/EXIT_USER') : null;
            this.customers = response.data.data;
          })
          .catch((e) => {
            console.log(e);
          })
      this.filterCustomers();
    },
    getDefaultSegment: function () {
      MarketingAPI.get_default_segments()
          .then(response => {
            response.data.status === 401 ? this.$store.commit('User/EXIT_USER') : null;
            this.defaultSegments = response.data.data;
          })
          .catch((e) => {
            console.log(e);
          })
    },
    getSegment: function (partner_id) {
      MarketingAPI.get_custom_segments({partner_id: partner_id})
          .then(response => {
            this.segments = response.data.data;
          })
          .catch((e) => {
            console.log(e);
          })
    }
  },
  async mounted() {
    this.getSegment(this.partner_id);
    this.getDefaultSegment();
    this.getCustomersBase();
  },
  created() {
    this.$on('makeFilter', this.makeFilter);
  }
}
</script>

<style scoped lang="scss">
.filters{
  margin-top: -5px;
}
.filters input.ant-input{
  margin-bottom: 15px;
}
.header{
  width: 100%;
  display: flex;
  & > * {
    flex: auto;
  }

}
table{
  width: 100%;
  & th {
    font-size: 14px;
    font-weight: bold;
    color: #000;
    border-right: 1px solid #eee;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    position: relative;
    text-align: center;
    user-select: none;
    background: #ddd;
    padding: 10px 0;
  }
}
.content_flex{
  display: flex;
  & > *{
    flex: auto;
    &:nth-child(2){
      width: 350px;
      flex: none;
      padding: 0 10px;
    }
  }
}

</style>