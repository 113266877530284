<template>
  <div>
    <a-form-model :model="form">
      <a-form-model-item :label="this.TextLocalization.MinOrder[this.language]">
        <a-input v-model="form.delivery__min_order"  @focusout="editSettings"/>
      </a-form-model-item>
      <a-form-model-item :label="this.TextLocalization.DeliveryFee[this.language]">
        <a-input v-model="form.delivery__fee" @focusout="editSettings"/>
      </a-form-model-item>
      <a-form-model-item :label="this.TextLocalization.FreeOrderStartFrom[this.language]">
        <a-input v-model="form.delivery__free_order_start_from" @focusout="editSettings"/>
      </a-form-model-item>
    </a-form-model>
  </div>
</template>

<script>
import FormPartnerID from '@/mixins/form-partnerID';
import ApplicationApi from "../../../api/ApplicationApi";

export default {
  mixins: [FormPartnerID],
  data() {
    return {
      form: {
        id: null,
        partner_id: null,
        delivery__min_order: null,
        delivery__fee: null,
        delivery__free_order_start_from: null
      }
    }
  },

  created() {
    this.getSettings();
  },
  methods: {
    getSettings: async function () {
      let settings = (await ApplicationApi.get_settings(this.form.partner_id)).data.data;

      this.form = {
        id: settings.id,
        partner_id: this.form.partner_id,
        delivery__min_order: settings.delivery__min_order,
        delivery__fee: settings.delivery__fee,
        delivery__free_order_start_from: settings.delivery__free_order_start_from,
      };
    },
    editSettings: async function(){
      await ApplicationApi.edit_settings(this.form);
      this.$root.$emit('createAlertGood');
    }
  }
}
</script>

<style scoped>

</style>