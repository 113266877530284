<template>
  <AddEditModifiersGroup></AddEditModifiersGroup>
</template>
<script>
import AddEditModifiersGroup from '../../../../components/Menu/add-edit-modifiers-group'
export default {
  components: {
    AddEditModifiersGroup
  }
}
</script>

