<template>
  <div class="mb-15">

    <div>
      <a-form-item label="Стандартный процент кещбека" >
        <a-input type="number"  min="0" v-model="data.default_cashback_amount" @change="editData"/>
      </a-form-item>
      <p class="mb-35 font-italic">Процент применяется в случае не попадания ни в одну из категорий ниже</p>
    </div>


    <div v-for="(cashBackString, key) in data.info" :key="key" class="card">
      <a-row type="flex" :gutter="36">
        <a-col :span="24" :lg="5" class="mb-5">
          <a-form-item label="Название">
            <a-input type="text" v-model="data.info[key].name" @change="editData"/>
          </a-form-item>
        </a-col>
        <a-col :span="24" :md="6" :lg="5" class="mb-5">
          <a-form-item label="Мин. кол-во заказов">
            <a-input type="number" step="1" min="0" v-model="data.info[key].min_orders" @change="editData"/>
          </a-form-item>
        </a-col>
        <a-col :span="24" :md="6" :lg="5" class="mb-5">
          <a-form-item label="Макс. кол-во заказов">
            <a-input type="number" step="1" min="0" v-model="data.info[key].max_orders" @change="editData"/>
          </a-form-item>
        </a-col>
        <a-col :span="24" :md="6" :lg="5" class="mb-5">
          <a-form-item label="Мин. выручка">
            <a-input type="number" step="0.01" min="0" v-model="data.info[key].min_proceeds" @change="editData"/>
          </a-form-item>
        </a-col>
        <a-col :span="24" :sm="6" :lg="5" class="mb-5">
          <a-form-item label="Макс. выручка">
            <a-input type="number" step="0.01" min="0" v-model="data.info[key].max_proceeds" @change="editData"/>
          </a-form-item>
        </a-col>
        <a-col :span="24" :sm="6" :lg="5" class="mb-5">
          <a-form-item label="Мин. сумма заказа">
            <a-input type="number" step="0.01" min="0" v-model="data.info[key].min_order_cost" @change="editData"/>
          </a-form-item>
        </a-col>
        <a-col :span="24" :sm="6" :lg="5" class="mb-5">
          <a-form-item label="Макс. сумма заказа">
            <a-input type="number" step="0.01" min="0" v-model="data.info[key].max_order_cost" @change="editData"/>
          </a-form-item>
        </a-col>
        <a-col :span="24" :sm="6" :lg="5" class="mb-20">
          <a-form-item label="Процент кешбека">
            <a-input type="number" step="0.01" min="0" v-model="data.info[key].cashback" @change="editData"/>
          </a-form-item>
        </a-col>
      </a-row>
      <a-button type="danger" @click="deleteGroup(key)">Удалить группу</a-button>
    </div>

    <a-button type="primary" class="mt-20" style="width: 100%;" @click="addString">
      Добавить группу
    </a-button>
  </div>
</template>

<script>
import {debounce} from "@/helpers";

export default {
  props: ['data'],
  methods: {
    deleteGroup: function (key){
      (this.data.info).splice(key, 1);
      this.editData();
    },
    editData: debounce(function (e) {
      this.$root.$emit('changeDataCashback');
    }, 750),
    addString: function (){
      (this.data.info).push({
        name: null,
        min_orders: null,
        max_orders: null,
        min_proceeds: null,
        max_proceeds: null,
        min_order_cost: null,
        max_order_cost: null,
        cashback: null
      });
      this.editData();
    }
  }
}
</script>

<style scoped lang="scss">
.card{
  padding: 29px 25px;
  background: #fafafa;
  border-radius: 28px;
  box-shadow: 0 2px 15px rgb(0 0 0 / 25%);
  margin-top: 25px;
}
</style>