<template>
  <a-row type="flex" :gutter="24">
    <a-col :span="24" :md="7">
      <a-select v-model="day">
        <a-select-option v-for="(option, key) in days" v-bind:value="option.day" :key="option.day">
          {{ option.name }}
        </a-select-option>
      </a-select>
    </a-col>
    <a-col :span="24" :md="7">
      <a-input type="time" v-model="openTime"/>
    </a-col>
    <a-col :span="24" :md="7">
      <a-input type="time" v-model="closeTime"/>
    </a-col>
    <a-col :span="24" :md="3">
     <div class="delete_button" @click="deleteTime">Удалить</div>
    </a-col>
  </a-row>
</template>

<script>
let days = [
  {name: 'Понедельник', day: 1},
  {name: 'Вторник', day: 2},
  {name: 'Среда', day: 3},
  {name: 'Четверг', day: 4},
  {name: 'Пятница', day: 5},
  {name: 'Суббота', day: 6},
  {name: 'Воскресенье', day: 0}
]
export default {
  name: "date-time",
  props: ['day', 'openTime', 'closeTime', 'timeId'],
  data(){
    return {
      days
    }
  },
  methods: {
    deleteTime: function () {
      this.$root.$emit('deleteTimeElement', this.timeId);
    }
  },
  watch:{
    day: function (newVal){this.$emit('update:day', newVal)},
    openTime: function (newVal){this.$emit('update:openTime', newVal)},
    closeTime: function (newVal){this.$emit('update:closeTime', newVal)},
  }
}
</script>

<style lang="scss">
.ant-select{
  width: 100%;
}
.delete_button{
  color: #fff;
  background-color: #ff4d4f;
  border-color: #ff4d4f;
  text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
  box-shadow: 0px 2px 4px rgb(0 0 0 / 7%);
  border-radius: 6px;
  text-align: center;
  cursor: pointer;
  height: calc(100% - 8px);
  margin: 2px 0;

  &:hover{
  color: #000;
   }
}
</style>
