import BaseApi from "./BaseApi";

class ApplicationContactsApi extends BaseApi {

    get(id) {
        return this.axios.get('/application/contact/'+id);
    }

    edit(data) {
        delete data.token;
        return this.axios.post('/application/contact/edit', data);
    }

    add(data) {
        return this.axios.post('/application/contact/add', data);
    }

    delete(data) {
        return this.axios.post('/application/contact/delete', data);
    }
}

export default new ApplicationContactsApi();
