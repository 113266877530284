<template>
  <CompletedWork></CompletedWork>
</template>

<script>
import CompletedWork from '@/components/Reports/CompletedWork';

export default {
  components: {
    CompletedWork
  }
}
</script>

<style scoped>

</style>