import BaseApi from "./BaseApi";

class MenuApi extends BaseApi {

    get_menu(id) {
        return this.axios.get('/Menu/'+id);
    }
    add_category(data) {
        return this.axios.post('/Menu/category/add',data );
    }
    get_category(id) {
        return this.axios.get('/Menu/category/'+id);
    }
    edit_category(data) {
        return this.axios.post('/Menu/category/edit', data);
    }

    delete_category(data) {
        return this.axios.post('/Menu/category/delete', data);
    }

}

export default new MenuApi();
