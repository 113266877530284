<template>
  <div>
    <autoPushContainer v-for="(pushContainer, key) in data" :partner_id="partner_id" :data="pushContainer" key="key"></autoPushContainer>
  </div>
</template>

<script>
import PartnerID from "@/mixins/partnerID";
import MarketingAPI from "../../../../api/MarketingAPI";
import autoPushContainer from '@/components/Marketing/AutoPush/AutoPushContainer'

export default {
  mixins: [PartnerID],
  name: "MarketingAutoPush",
  components: {
    autoPushContainer
  },
  data() {
    return {
      partner_id: null,
      data: []
    }
  },
  methods: {
    getData: function (){
      MarketingAPI.auto_push({
        partner_id: this.partner_id
      })
          .then(response => {
            response.data.status === 401 ? this.$store.commit('User/EXIT_USER') : null;
            this.data = response.data.data;
          })
          .catch((e) => {
            console.log(e);
          })
    }
  },
  async mounted() {
    this.getData();
    this.$root.$on('updatePushes', this.getData);
  }
}
</script>

<style scoped lang="scss">
</style>