<template>
  <a-row type="flex" :gutter="24">
    <a-col :span="24" :md="24">
      <div v-for="(error, errorKey) in errors" class="error__container">
        {{error}}
      </div>
    </a-col>
    <a-col :span="24" :md="24">
      <a-input type="hidden" v-model="form.partner_id"/>
      <a-form-model-item :label="TextLocalization.Login[language]" v-if="this.$route.params.contactID !== undefined">
        <a-input v-model="form.login" disabled/>
      </a-form-model-item>

      <a-form-model-item :label="TextLocalization.Surname[language]">
        <a-input v-model="form.surname" />
      </a-form-model-item>

      <a-form-model-item :label="TextLocalization.Name[language]">
        <a-input v-model="form.name" />
      </a-form-model-item>
      <a-form-model-item :label="TextLocalization.Lastname[language]">
        <a-input v-model="form.lastname" />
      </a-form-model-item>

      <a-form-model-item label="Email">
        <a-input v-model="form.email" />
      </a-form-model-item>

      <a-form-model-item :label="TextLocalization.PhoneNumber[language]">
        <a-input v-model="form.phone" />
      </a-form-model-item>

      <a-form-model-item :label="TextLocalization.Position[language]">
        <a-input v-model="form.position" />
      </a-form-model-item>


      <a-form-model-item class="buttons_container">
        <a-row type="flex" :gutter="24" class="buttons_container">
          <a-col :span="24" :lg="this.$route.params.contactID !== undefined ? 12 : 24" :md="24">
            <a-button type="primary" @click="onSubmit">
              {{this.$route.params.contactID === undefined ? this.TextLocalization.Add[this.language] : this.TextLocalization.Edit[this.language]}}
            </a-button>
          </a-col>
          <a-col :span="24" :lg="12" :md="24" v-if="this.$route.params.contactID !== undefined">
            <a-button type="danger" @click="showModalDelete">
              {{ TextLocalization.Delete[language] }}
            </a-button>
          </a-col>
        </a-row>
      </a-form-model-item>
    </a-col>
    <a-modal
        :title="TextLocalization.AreYouSure[language]"
        :visible="visible"
        :confirm-loading="confirmLoading"
        @ok="handleOk"
        @cancel="handleCancel"
    >
      {{ TextLocalization.AreYouSureYouWantToDelete[language] }}
    </a-modal>
  </a-row>
</template>
<script>

import ApplicationUsersApi from "../../../../api/ApplicationUsersApi";
import UserApi from "../../../../api/UserApi";


export default {
  components:{
  },
  data() {
    return {
      editor: '',
      errors: [],
      visible: false,
      confirmLoading: false,
      form: {
        partner_id: null,
        name: '',
        surname: '',
        lastname: '',
        email: '',
        phone: ''
      },
    };
  },
  methods: {
    onSubmit() {
      this.errors = [];
      if (this.$route.params.contactID === undefined){
        this.add(this.form);
      } else {
        this.edit(this.form);
      }
    },
    get_data(contact_id){
      if(this.$route.meta.layoutClass === 'SystemUsers'){
        switch (this.editor){
          case 0:
            UserApi.getSystemUser(contact_id)
                .then(response => {
                  response.data.status === 401 ? this.$store.commit('User/EXIT_USER') : null;
                  switch (response.data.status){
                    case 200:
                      this.form = response.data.data;
                      break;
                    default:
                      this.data = {};
                      break;
                  }
                })
                .catch((e) => {
                  console.log(e);
                })
            break;
          case 1:
            UserApi.getRestaurantUser(contact_id)
                .then(response => {
                  response.data.status === 401 ? this.$store.commit('User/EXIT_USER') : null;
                  switch (response.data.status){
                    case 200:
                      this.form = response.data.data;
                      break;
                    default:
                      this.data = {};
                      break;
                  }
                })
                .catch((e) => {
                  console.log(e);
                })
            break;
        }

      }else {
        ApplicationUsersApi.get(contact_id)
            .then(response => {
              response.data.statuss === 401 ? this.$store.commit('User/EXIT_USER') : null;
              switch (response.data.status){
                case 200:
                  this.form = response.data.data;
                  break;
                default:
                  this.data = {};
                  break;
              }
            })
            .catch((e) => {
              console.log(e);
            })
      }

    },
    edit(data){
      if(this.$route.meta.layoutClass === 'SystemUsers'){
        switch (this.editor){
          case 0:
            UserApi.editSystemUser(data)
                .then(response => {
                  response.status === 401 ? this.$store.commit('User/EXIT_USER') : null;
                  switch (response.data.status){
                    case 200:
                      this.form = response.data.data;
                      this.$root.$emit('createAlertGood');
                      break;
                    default:
                      this.data = {};
                      break;
                  }
                })
                .catch((e) => {
                  console.log(e);
                })
            break;
          case 1:
            UserApi.editRestaurantUser(data)
                .then(response => {
                  response.status === 401 ? this.$store.commit('User/EXIT_USER') : null;
                  switch (response.data.status){
                    case 200:
                      this.form = response.data.data;
                      this.$root.$emit('createAlertGood');
                      break;
                    default:
                      this.data = {};
                      break;
                  }
                })
                .catch((e) => {
                  console.log(e);
                })
            break;
        }

      }else {
        ApplicationUsersApi.edit(data)
            .then(response => {
              response.status === 401 ? this.$store.commit('User/EXIT_USER') : null;
              switch (response.data.status){
                case 200:
                  this.form = response.data.data;
                  this.$root.$emit('createAlertGood');
                  break;
                default:
                  this.data = {};
                  break;
              }
            })
            .catch((e) => {
              console.log(e);
            })
      }

    },
    add(data){
      if(this.$route.meta.layoutClass === 'SystemUsers'){
        switch (this.editor){
          case 0:
            UserApi.addSystemUser(data)
                .then(response => {
                  response.status === 401 ? this.$store.commit('User/EXIT_USER') : null;
                  switch (response.data.status){
                    case 200:
                      this.form = response.data.data;
                      this.$store.dispatch('BackButton/setGoBack', -2);
                      this.$router.push('/users/' + response.data.data.id);
                      this.$root.$emit('createAlertGood');
                      break;
                    case 400:
                      this.errors.push('Возникла неожиданная ошибка!');
                      this.$root.$emit('createAlertError');
                      break;
                    case 409:
                      this.errors.push('Данный логин уже занят другим пользователем! Пожалуйста используйте другой email');
                      this.$root.$emit('createAlertError');
                      break;
                    default:
                      this.data = {};
                      break;
                  }
                })
                .catch((e) => {
                  console.log(e);
                })
            break;
          case 1:
            UserApi.addRestaurantUser(data)
                .then(response => {
                  response.data.status === 401 ? this.$store.commit('User/EXIT_USER') : null;
                  switch (response.data.status){
                    case 200:
                      this.form = response.data.data;
                      this.$store.dispatch('BackButton/setGoBack', -2);
                      this.$router.push('/users/' + response.data.data.id);
                      this.$root.$emit('createAlertGood');
                      break;
                    case 400:
                      this.errors.push('Возникла неожиданная ошибка!');
                      this.$root.$emit('createAlertError');
                      break;
                    case 409:
                      this.errors.push('Данный логин уже занят другим пользователем! Пожалуйста используйте другой email');
                      this.$root.$emit('createAlertError');
                      break;
                    default:
                      this.data = {};
                      break;
                  }
                })
                .catch((e) => {
                  console.log(e);
                })
            break;
        }

      }else {
        ApplicationUsersApi.add(data)
            .then(response => {
              response.data.status === 401 ? this.$store.commit('User/EXIT_USER') : null;
              switch (response.data.status) {
                case 200:
                  this.form = response.data.data;
                  this.$store.dispatch('BackButton/setGoBack', -2);
                  this.$router.push('/applications/' + data.partner_id + '/user/' + response.data.data.id);
                  this.$root.$emit('createAlertGood');
                  break;
                case 400:
                  this.errors.push('Возникла неожиданная ошибка!');
                  this.$root.$emit('createAlertError');
                  break;
                case 409:
                  this.errors.push('Данный логин уже занят другим пользователем! Пожалуйста используйте другой email');
                  this.$root.$emit('createAlertError');
                  break;
                default:
                  this.data = {};
                  break;
              }
            })
            .catch((e) => {
              console.log(e);
            })
      }
    },
    delete(data){
      if(this.$route.meta.layoutClass === 'SystemUsers'){
        switch (this.editor){
          case 0:
            UserApi.deleteSystemUser(data)
                .then(response => {
                  response.data.status === 401 ? this.$store.commit('User/EXIT_USER') : null;
                  switch (response.data.status){
                    case 200:
                      this.form = response.data.data;
                      this.$root.$emit('createAlertGood');
                      this.$router.push('/users/');
                      break;
                    default:
                      this.data = {};
                      break;
                  }
                })
                .catch((e) => {
                  console.log(e);
                })
            break;
          case 1:
            UserApi.deleteRestaurantUser(data)
                .then(response => {
                  response.data.status === 401 ? this.$store.commit('User/EXIT_USER') : null;
                  switch (response.data.status){
                    case 200:
                      this.form = response.data.data;
                      this.$root.$emit('createAlertGood');
                      this.$router.push('/users/');
                      break;
                    default:
                      this.data = {};
                      break;
                  }
                })
                .catch((e) => {
                  console.log(e);
                })
            break;
        }

      }else {
        ApplicationUsersApi.delete(data)
            .then(response => {
              response.data.status === 401 ? this.$store.commit('User/EXIT_USER') : null;
              switch (response.data.status) {
                case 200:
                  switch (this.$store.getters['BackButton/goBack']) {
                    case -1:
                      this.$store.dispatch('BackButton/setGoBack', -3);
                      break;
                    case -2:
                      this.$store.dispatch('BackButton/setGoBack', -4);
                      break;
                  }

                  this.$router.push('/applications/' + this.$route.params.id);
                  this.$root.$emit('createAlertGood');
                  break;
                default:
                  this.data = {};
                  break;
              }
            })
            .catch((e) => {
              console.log(e);
            })
      }
    },
    showModalDelete() {
      this.visible = true;
    },
    handleOk(e) {
      this.confirmLoading = true;
      this.delete({id: this.form.id, partner_id: this.$route.params.id});
      this.visible = false;
      this.confirmLoading = false;
    },
    handleCancel(e) {
      this.visible = false;
    },
  },
  mounted(){
    if (this.$route.params.contactID !== undefined){
      this.get_data(this.$route.params.contactID);
    }
  },
  created() {
    this.form.partner_id = this.$route.params.id;
    let AuthInfo = (this.$store.getters["User/getUserInfo"]);
    this.editor = AuthInfo.type;

    if(this.editor === 1){
      this.form.partner_id = AuthInfo.partner_id;
    }

    console.log(this.form);
  }
};
</script>

<style>
.ant-form-item-label{
  line-height: inherit!important;
}
.ant-form-item{
  margin-bottom: 0;
}
.buttons_container .ant-btn-primary, .buttons_container .ant-btn-danger{
  width: 100%!important;
  margin: 0!important;
}
.error__container{
  box-sizing: border-box;
  margin: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  padding: 8px 15px 8px 37px;
  word-wrap: break-word;
  border-radius: 4px;
  background-color: #fff1f0;
  border: 1px solid #ffa39e;
}
</style>
