<template>
  <div>
    <table>
      <tr>
        <th>№</th>
        <th>Наименование услуги</th>
        <th>Единица измерения</th>
        <th>Цена без учета НДС</th>
      </tr>
      <TarifRow v-if="priceList.length > 0" v-for="(priceInfo, priceInfoKey) in priceList" :data="priceInfo" :key="priceInfoKey"/>
    </table>
  </div>
</template>

<script>
import TarifRow from './TarifRow';
import LegalApi from "../../../api/LegalApi";

export default {
  data(){
    return {
      priceList: []
    }
  },
  components: {
    TarifRow
  },
  methods: {
    getData: function () {
      LegalApi.get_price_list()
          .then(response => {
            this.priceList = response.data.data;
          })
          .catch((e) => {
            console.log(e);
          });
    }
  },
  mounted() {
    this.getData();
  }

}
</script>

<style scoped lang="scss">
table{
  width: 100%;
  & th {
    font-size: 14px;
    font-weight: bold;
    color: #000;
    border-right: 1px solid #eee;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    position: relative;
    text-align: center;
    user-select: none;
    background: #ddd;
    padding: 10px 0;
  }
}
</style>