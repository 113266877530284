<template>
  <div>
    <a-form-model :model="form">
      <a-form-model-item label="Instagram">
        <a-input v-model="form.smm_instagram"/>
      </a-form-model-item>
      <a-form-model-item label="Вконтакте">
        <a-input v-model="form.smm_vk"/>
      </a-form-model-item>
      <a-form-model-item label="Facebook">
        <a-input v-model="form.smm_facebook"/>
      </a-form-model-item>
      <a-form-model-item label="Twitter">
        <a-input v-model="form.smm_twitter"/>
      </a-form-model-item>
      <a-form-model-item label="TikTok">
        <a-input v-model="form.smm_tiktok"/>
      </a-form-model-item>
      <a-form-model-item label="YouTube">
        <a-input v-model="form.smm_youtube"/>
      </a-form-model-item>
    </a-form-model>
  </div>
</template>

<script>
import ApplicationApi from "../../../api/ApplicationApi";
import FormPartnerID from '@/mixins/form-partnerID';
import {debounce} from "@/helpers";

export default {
  mixins: [FormPartnerID],
  data() {
    return {
      form: {
        id: null,
        partner_id: null,
        smm_instagram: '',
        smm_vk: '',
        smm_facebook: '',
        smm_twitter: '',
        smm_tiktok: '',
        smm_youtube: ''
      }
    }
  },
  watch: {
    form: {
      handler(){
        this.debounceEditSettings();
      },
      deep: true
    }
  },
  async created() {
    await this.getSettings();
  },
  methods: {
    getSettings: async function () {
      let settings = (await ApplicationApi.get_settings(this.form.partner_id)).data.data;

      this.form = {
        id: settings.id,
        partner_id: this.form.partner_id,
        smm_instagram: settings.smm_instagram,
        smm_vk: settings.smm_vk,
        smm_facebook: settings.smm_facebook,
        smm_twitter: settings.smm_twitter,
        smm_tiktok: settings.smm_tiktok,
        smm_youtube: settings.smm_youtube
      };
    },
    editSettings: async function(){
      await ApplicationApi.edit_settings(this.form);
      this.$root.$emit('createAlertGood');
    },
    debounceEditSettings: debounce(function (){
      this.editSettings();
    }, 750)
  }
}
</script>

<style scoped>

</style>