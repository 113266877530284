import BaseApi from "./BaseApi";

class ModifiersGroupApi extends BaseApi {

    get(id) {
        return this.axios.get('/Menu/category/dish/modifier-group/'+id);
    }

    edit(data) {
        delete data.token;
        return this.axios.post('/Menu/category/dish/modifier-group/edit', data);
    }

    add(data) {
        return this.axios.post('/Menu/category/dish/modifier-group/add', data);
    }

    delete(data) {
        return this.axios.post('/Menu/category/dish/modifier-group/delete', data);
    }
}

export default new ModifiersGroupApi();
