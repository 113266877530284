<template>
  <a-row type="flex" :gutter="12">
    <a-col :span="24" class="mb-24">
      <img src="@/assets/cashbackBanner.png" width="100%">
    </a-col>
    <a-col :span="24" :lg="24" class="">
      <div>
        <a-form-item label="Условия программы лояльности" >
          <a-radio-group v-model="loyaltyType">
            <a-radio :style="radioStyle" :value="0">
              Нет
            </a-radio>
            <a-radio :style="radioStyle" :value="1">
              Фиксированный процент кешбека
            </a-radio>
            <a-radio :style="radioStyle" :value="2">
              Мульти-кешбек
            </a-radio>
          </a-radio-group>
        </a-form-item>
        <p class="mb-35 font-italic">Крайне не рекомендуем часто менять условия начисления кешбека пользователя!</p>
      </div>

      <a-row type="flex" :gutter="12">
        <a-col :span="24" :xl="8" class="">
          <div v-if="loyaltyType > 0">
        <a-form-item label="Системно списывать бонусные рубли" >
          <a-radio-group v-model="loyaltyRemoveCashback">
            <a-radio :style="radioStyle" :value="false">
              Не списывать
            </a-radio>
            <a-radio :style="radioStyle" :value="true">
              Списывать <span v-if="loyaltyRemoveCashback === true">через сколько дней отсутвия заказов: </span>
              <a-input v-model.lazy="cashback_systematically_write_off_bonuses_period" type="number" step="1" min="0" v-if="loyaltyRemoveCashback === true" style="margin-left: 20px; width: 70px"  />
            </a-radio>
          </a-radio-group>
        </a-form-item>
        <p class="mb-35 font-italic">Крайне не рекомендуем часто менять условия начисления кешбека пользователя!</p>
      </div>
        </a-col>
        <a-col :span="24" :xl="8" class="">
          <div v-if="loyaltyType > 0 && loyaltyRemoveCashback">
        <a-form-item label="Системно списывать бонусные рубли">
          <a-radio-group v-model="loyaltyReportBeforeCashbackRemove">
            <a-radio :style="radioStyle" :value="false">
              Не уведомлять заранее
            </a-radio>
            <a-radio :style="radioStyle" :value="true">
              Уведомить <span v-if="loyaltyReportBeforeCashbackRemove">за (дней): </span>
              <a-input v-model.lazy="cashback_report_systematically_write_off_bonuses_period" type="number" step="1" min="0" v-if="loyaltyReportBeforeCashbackRemove" style="margin-left: 20px; width: 70px" />
            </a-radio>
          </a-radio-group>
        </a-form-item>
        <p class="mb-35 font-italic" >Крайне не рекомендуем часто менять условия начисления кешбека пользователя!</p>
      </div>
        </a-col>
        <a-col :span="24" :xl="8" class="">
           <div v-if="loyaltyType > 0 && loyaltyRemoveCashback && loyaltyReportBeforeCashbackRemove">
        <a-form-item label="Текст уведомления" >
          <a-textarea v-model.lazy="cashback_report_systematically_write_off_bonuses_text"/>
        </a-form-item>
        <p class="mt-10 mb-35 font-italic">Крайне не рекомендуем часто менять условия начисления кешбека пользователя!</p>
      </div>
        </a-col>
      </a-row>

    </a-col>
    <a-col :span="24" :lg="24" >
        <MarketingLoyaltyDoesntWork v-if="loyaltyType === 0"/>
        <MarketingLoyaltyFixPercent :data.sync="FixPercentData" v-if="loyaltyType === 1"/>
        <MarketingLoyaltyFlexCashBack :data.sync="FlexCashBackData" v-if="loyaltyType === 2"/>
    </a-col>
    <a-col :span="24" :lg="24" class="mb-24" v-if="loyaltyType > 0">
      <a-form-item label="Текстовое описание кешбек системы" >
        <a-textarea v-model.lazy="cashback_report_systematically_write_off_bonuses_text"/>
      </a-form-item>
    </a-col>
  </a-row>

</template>


<script>

import MarketingLoyaltyFixPercent from '@/components/Marketing/Loyalty/MarketingLoyaltyFixPercent';
import MarketingLoyaltyFlexCashBack from '@/components/Marketing/Loyalty/MarketingLoyaltyFlexCashBack';
import MarketingLoyaltyDoesntWork from '@/components/Marketing/Loyalty/MarketingLoyaltyDoesntWork';
import ApplicationApi from "../../../../api/ApplicationApi";
import partnerIDMixin from '@/mixins/partnerID'
import {debounce} from "@/helpers";

export default {
  mixins: [partnerIDMixin],
  components:{
    MarketingLoyaltyDoesntWork,
    MarketingLoyaltyFixPercent,
    MarketingLoyaltyFlexCashBack,
  },
  watch: {
    loyaltyType: {
      handler(){
        this.debouncedEditSettings()
      },
    },
    loyaltyRemoveCashback: {
      handler(){
        this.debouncedEditSettings()
      },
    },
    loyaltyReportBeforeCashbackRemove: {
      handler(){
        this.debouncedEditSettings()
      },
    },
    cashback_report_systematically_write_off_bonuses_text: {
      handler(){
        this.debouncedEditSettings()
      },
    },
    cashback_report_systematically_write_off_bonuses_period: {
      handler(){
        this.debouncedEditSettings()
      },
    },
    cashback_systematically_write_off_bonuses_period: {
      handler(){
        this.debouncedEditSettings()
      },
    },
  },
  data() {
    return {
      id: null,
      partner_id: null,
      loyaltyType: null,
      loyaltyRemoveCashback: true,
      loyaltyReportBeforeCashbackRemove: true,
      cashback_report_systematically_write_off_bonuses_text: null,
      cashback_report_systematically_write_off_bonuses_period: null,
      cashback_systematically_write_off_bonuses_period: null,
      cashback_description: "",
      radioStyle: {
        display: 'block',
        height: '30px',
        lineHeight: '30px',
      },
      FixPercentData: {
        amount: null
      },
      FlexCashBackData: {
        info: []
      }
    };
  },
  created() {
    this.getSettings();
    this.$root.$on('changeDataCashback', this.editSettings);
  },
  methods: {
    getSettings: function () {
      ApplicationApi.get_settings(this.partner_id)
          .then(response => {
            response.data.status === 401 ? this.$store.commit('User/EXIT_USER') : null;
              let settings = response.data.data;

              this.id = settings.id;
              this.loyaltyType = settings.cashback_type;
              this.loyaltyRemoveCashback = settings.cashback_systematically_write_off_bonuses;
              this.loyaltyReportBeforeCashbackRemove = settings.cashback_report_systematically_write_off_bonuses;
              this.cashback_report_systematically_write_off_bonuses_text = settings.cashback_report_systematically_write_off_bonuses_text;
              if(settings.cashback_multi_data === null){
                settings.cashback_multi_data = {
                  info: []
                }
              }
              this.cashback_description = settings.cashback_description;
              this.FixPercentData.amount = Number(settings.cashback_fix_percent);
              this.FlexCashBackData = settings.cashback_multi_data;
          })
          .catch((e) => {
            console.log(e);
          })
    },
    editSettings(){
      ApplicationApi.edit_settings({
        id: this.id,
        partner_id: this.partner_id,
        cashback_type: this.loyaltyType,
        cashback_systematically_write_off_bonuses: this.loyaltyRemoveCashback,
        cashback_report_systematically_write_off_bonuses: this.loyaltyReportBeforeCashbackRemove,
        cashback_report_systematically_write_off_bonuses_text: this.cashback_report_systematically_write_off_bonuses_text,
        cashback_report_systematically_write_off_bonuses_period: this.cashback_report_systematically_write_off_bonuses_period,
        cashback_systematically_write_off_bonuses_period: this.cashback_systematically_write_off_bonuses_period,
        cashback_multi_data: this.FlexCashBackData,
        cashback_fix_percent: Number(this.FixPercentData.amount),
        cashback_description: this.cashback_description
      })
          .then(response => {
            response.data.status === 401 ? this.$store.commit('User/EXIT_USER') : null;
            this.$root.$emit('createAlertGood');
            this.getSettings();
          })
          .catch((e) => {
            console.log(e);
          })
    },
    debouncedEditSettings: debounce(function (e) {
      this.editSettings();
    }, 750)
  }
};
</script>

<style>
.ant-form-item-label > label{
  font-weight: bold;
}
p.font-italic{
  font-style: italic;
}
</style>
