<template>
  <a-row type="flex" :gutter="24">
    <a-col :span="24" :md="24">
      <a-form-model-item label="Режим работы (День недели / Время открытия / Время закрытия)">
        <DateTime v-for="(time, timeKey) in this.timesheet" :key="timeKey"  v-bind:day.sync="time.day" v-bind:openTime.sync="time.open_time" v-bind:closeTime.sync="time.close_time" v-bind:timeId="timeKey"></DateTime>
      </a-form-model-item>
      <a-form-model-item>
        <a-button @click="add_timesheet()" style="width: 100%!important;margin-bottom: 50px">Добавить строку в режим работы</a-button>
      </a-form-model-item>
    </a-col>
  </a-row>
</template>

<script>
import DateTime from "@/components/date-time";
import PartnerIDMixin from '@/mixins/partnerID';
import ApplicationApi from "../../../../api/ApplicationApi";
import {debounce} from "@/helpers";

export default {
  mixins: [PartnerIDMixin],
  components:{
    DateTime
  },
  data() {
    return {
      id: null,
      partner_id: null,
      timesheet: []
    };
  },
  watch: {
    timesheet: {
      handler(){
        this.debounceEdit();
      },
      deep: true
    }
  },
  created() {
    this.get_data();
    this.$root.$on('deleteTimeElement', this.delete_time_string);
  },
  methods: {
    get_data(){
      ApplicationApi.get_settings(this.partner_id)
          .then(response => {
            response.data.status === 401 ? this.$store.commit('User/EXIT_USER') : null;
            this.id = response.data.data.id;
            this.timesheet = response.data.data.operation_time.timesheet;
          })
          .catch((e) => {
            console.log(e);
          })
    },
    delete_time_string(key){
      (this.timesheet).splice(key, 1);
    },
    debounceEdit: debounce(function (){
      this.edit();
    }, 1500),
    edit(){
      ApplicationApi.editOperationTime({id: this.id, partner_id: this.partner_id, timesheet: this.timesheet})
          .then(response => {
            response.data.status === 401 ? this.$store.commit('User/EXIT_USER') : null;
            this.form = response.data;
            this.$root.$emit('createAlertGood');
          })
          .catch((e) => {
            console.log(e);
          })
    },
    add_timesheet: function (){
      this.timesheet.push({})
    }
  }
}
</script>

<style scoped>

</style>