<template>
  <div>
    <h6 class="mb-15">Уведомление о новых заказах</h6>
    <p class="mb-10">
      Каждый раз когда клиент оформляет новый заказ система отправляет POST запрос на URL указанный ниже с информацией о заказе
    </p>
    <a-form-model-item :label="'URL уведомления о поступивщем заказе '">
      <a-input v-model="form.delivery__min_order"/>
    </a-form-model-item>
  </div>
</template>

<script>
export default {
  data(){
    return {
      form: {
        delivery__min_order: ''
      }
    }
  }
}
</script>

<style scoped>

</style>