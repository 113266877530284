<template>
  <a-row type="flex" :gutter="24">
    <a-col :span="24">
      <div v-for="(status, key) in statusOk" :key="key" class="ant-alert-success">
        {{status}}
      </div>
    </a-col>
    <a-col :span="14">
      <a-form-model-item :label="TextLocalization.Partner[language]">
        <a-input-group compact style="width: 100%">
          <a-select v-model="form.partner_id">
            <a-select-option v-for="(partner, partnerKey) in partners" :value="partner.id" :key="partnerKey">
              {{partner.application.name }} - {{partner.company.name}}
            </a-select-option>
          </a-select>
        </a-input-group>
      </a-form-model-item>
      <a-form-model-item :label="TextLocalization.AmountToBeCredited[language]">
        <a-input type="number" step="0.01" v-model="form.amount" />
      </a-form-model-item>
      <a-form-model-item :label="TextLocalization.Description[language]">
        <a-textarea rows="4" v-model="form.description"/>
      </a-form-model-item>
    </a-col>
    <a-col :span="10">
      <table>
        <tr>
          <td class="bold">{{ TextLocalization.actualBalance[language] }}:</td>
          <td :class="actualPartnerBalance > 0?'ok':'budget_error'">{{ formatter(actualPartnerBalance) }}</td>
        </tr>
        <tr>
          <td class="bold">{{ TextLocalization.AmountToBeCredited[language] }}:</td>
          <td>{{ formatter(form.amount) }}</td>
        </tr>
        <tr>
          <td class="bold">{{ TextLocalization.FinalBalance[language] }}:</td>
          <td :class="(actualPartnerBalance + form.amount) > 0?'ok':'budget_error'">{{ formatter((actualPartnerBalance + form.amount)) }}</td>
        </tr>
      </table>
      <div>
      </div>
    </a-col>
    <a-col :span="24" class="buttons_container_wrapper">
      <a-form-model-item>
        <a-row type="flex" :gutter="24" class="buttons_container">
          <a-col :span="24" :lg="24" :md="24">
            <a-button type="primary" @click="onSubmit">
              {{ TextLocalization.AddPayment[language] }}
            </a-button>
          </a-col>
        </a-row>
      </a-form-model-item>
    </a-col>
  </a-row>
</template>

<script>
import ApplicationApi from "../../../api/ApplicationApi";
import FinanceApi from "../../../api/FinanceAPI";

export default {
  data(){
    return {
      partners: [],
      statusOk: [],
      actualPartnerBalance: null,
      form: {
        partner_id: null,
        amount: null,
        description: null
      }
    }
  },
  watch: {
    form: {
      handler(val){
        this.changeActualPartnerBalance(val);
      },
      deep: true
    }
  },
  methods: {
    onSubmit: function (){
      this.addPayment(this.form);
    },
    changeActualPartnerBalance: function (val){
      this.form.amount = Number(val.amount);
      this.actualPartnerBalance = null;

      this.partners.forEach(function (partner){
        if(partner.id === val.partner_id){
          this.actualPartnerBalance = partner.budget
        }
      }.bind(this));
    },
    get_all_partners: function (){
      ApplicationApi.get_all()
          .then(response => {
            response.status === 401 ? this.$store.commit('User/EXIT_USER') : null;
            switch (response.data.status){
              case 200:
                this.partners = response.data.data;
                break;
              case 404:
                this.partners = [];
                break;
              default:
                this.partners = [];
                break;
            }
          })
          .catch((e) => {
            console.log(e);
          });
    },
    addPayment: function (data){
      FinanceApi.addPayment(data)
          .then(response => {
            response.status === 401 ? this.$store.commit('User/EXIT_USER') : null;
            this.statusOk = [];
            switch (response.data.status){
              case 200:
                this.get_all_partners();
                this.form.amount = null;
                this.form.description = null;

                this.statusOk.push(this.TextLocalization.PaymentWasSuccessfullyRegistered[this.language])
                break;
              default:
                this.partners = [];
                break;
            }
          })
          .catch((e) => {
            console.log(e);
          });
    }
  },
  mounted() {
    this.get_all_partners();
  }
}
</script>

<style scoped lang="scss">
table{
  width: 100%;
  margin-top: 20px;
}
tr{
  &:hover{
    & td{
      background: #fff5ee;
    }
  }

  & td{
    border: 1px solid #eee;
    font-size: 14px;
    padding: 10px;
    &.budget_error{
      color: red;
      font-weight: bold;
    }
    &:nth-child(1){
      width: 65%;
    }
    &.bold{
      font-weight: bold;
    }
  }
}

.buttons_container_wrapper{
  margin-top: 20px;
}
.ant-alert-success{
  padding: 20px;
  margin-bottom: 20px;
}
</style>