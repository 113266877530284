<template>
  <a-row type="flex" :gutter="24">
    <a-col :span="24">
      <div v-for="(status, key) in statusOk" :key="key" class="ant-alert-success">
        {{status}}
      </div>
    </a-col>
    <a-col :span="14">
      <a-form-model-item :label="TextLocalization.Partner[language]">
        <a-input-group compact style="width: 100%">
          <a-select v-model="form.partner_id">
            <a-select-option v-for="(partner, partnerKey) in partners" :value="partner.id" :key="partnerKey">
              {{partner.application.name }} - {{partner.company.name}}
            </a-select-option>
          </a-select>
        </a-input-group>
      </a-form-model-item>
    </a-col>
    <a-col :span="10">
      <table>
        <tr>
          <td class="bold">{{ TextLocalization.actualBalance[language] }}:</td>
          <td :class="actualPartnerBalance > 0?'ok':'budget_error'">{{ formatter(actualPartnerBalance) }}</td>
        </tr>
        <tr>
          <td class="bold">{{ TextLocalization.TotalAmountOfTheDocument[language] }}:</td>
          <td>{{ formatter(form.fullPrice) }}</td>
        </tr>
        <tr>
          <td class="bold">{{ TextLocalization.FinalBalance[language] }}:</td>
          <td :class="(actualPartnerBalance - form.fullPrice) > 0?'ok':'budget_error'">{{ formatter((actualPartnerBalance - form.fullPrice)) }}</td>
        </tr>
      </table>
      <div>
      </div>
    </a-col>
    <a-col :span="24" style="margin-top: 25px">
      <div class="actTable">
        <table>
          <tr>
            <th>№</th>
            <th>Наименование работ / услуг</th>
            <th>Единица измерения</th>
            <th>Цена</th>
            <th>Кол-во.</th>
            <th>Стоимость</th>
            <th></th>
          </tr>
          <actCompletedWorkActString v-for="(content, keyContent) in form.act_content" :data.sync="content" :index="keyContent" :key="keyContent"></actCompletedWorkActString>
        </table>
      </div>
      <a-button style="width: 100%" @click="addCompletedWorkActString">
        Добавить строку в акт выполненных работ
      </a-button>
    </a-col>
    <a-col :span="24" class="buttons_container_wrapper">
      <a-form-model-item>
        <a-row type="flex" :gutter="24" class="buttons_container">
          <a-col :span="24" :lg="24" :md="24">
            <a-button type="primary" @click="onSubmit">
              {{ TextLocalization.AddCompletedAct[language] }}
            </a-button>
          </a-col>
        </a-row>
      </a-form-model-item>
    </a-col>
  </a-row>
</template>

<script>
import ApplicationApi from "../../../api/ApplicationApi";
import FinanceApi from "../../../api/FinanceAPI";
import actCompletedWorkActString from '@/components/elements/actCompletedWorkActString'

export default {
  components: {
    actCompletedWorkActString
  },
  data(){
    return {
      partners: [],
      statusOk: [],
      actualPartnerBalance: null,
      form: {
        partner_id: null,
        fullPrice: 0,
        act_content: [{
          price: 0,
          quantity: 0,
          unit: '',
          name: ''
        }]
      }
    }
  },
  watch: {
    form: {
      handler(val){
        this.changeActualPartnerBalance(val);
      },
      deep: true
    }
  },
  methods: {
    addCompletedWorkActString: function (){
      (this.form.act_content).push({
        price: 0,
        quantity: 0,
        unit: '',
        name: ''
      });
    },
    onSubmit: function (){
      this.addCompletedAct(this.form);
    },
    addCompletedAct: function (data){
      FinanceApi.addCompletedAct(data)
          .then(response => {
            response.status === 401 ? this.$store.commit('User/EXIT_USER') : null;
            this.statusOk = [];
            switch (response.data.status){
              case 200:
                this.get_all_partners();
                this.form.act_content = []
                this.addCompletedWorkActString();
                this.changeActualPartnerBalance(this.form);

                this.statusOk.push(this.TextLocalization.PaymentWasSuccessfullyRegistered[this.language])
                break;
              default:
                this.partners = [];
                break;
            }
          })
          .catch((e) => {
            console.log(e);
          });
    },
    get_all_partners: function (){
      ApplicationApi.get_all()
          .then(response => {
            response.status === 401 ? this.$store.commit('User/EXIT_USER') : null;
            switch (response.data.status){
              case 200:
                this.partners = response.data.data;
                break;
              case 404:
                this.partners = [];
                break;
              default:
                this.partners = [];
                break;
            }
          })
          .catch((e) => {
            console.log(e);
          });
    },
    changeActualPartnerBalance: function (val){
      this.form.amount = Number(val.amount);
      this.actualPartnerBalance = null;

      this.partners.forEach(function (partner){
        if(partner.id === val.partner_id){
          this.actualPartnerBalance = partner.budget
        }
      }.bind(this));

      let act_price = 0;
      (this.form.act_content).forEach(function (act_string){
        act_price += act_string.price * act_string.quantity;
      }.bind(this));

      this.form.fullPrice = act_price;
    },
    delete_act_string: function (id){
      (this.form.act_content).splice(id, 1);
    }
  },
  mounted() {
    this.get_all_partners();
  },
  created() {
    this.$root.$on('deleteElement', this.delete_act_string);
  }
}
</script>

<style scoped lang="scss">
table{
  width: 100%;
  margin-top: 20px;
}
tr{
  &:hover{
    & td{
      background: #fff5ee;
    }
  }

  & td{
    border: 1px solid #eee;
    font-size: 14px;
    padding: 10px;
    &.budget_error{
      color: red;
      font-weight: bold;
    }
    &:nth-child(1){
      width: 65%;
    }
    &.bold{
      font-weight: bold;
    }
  }
}

.buttons_container_wrapper{
  margin-top: 20px;
}
.ant-alert-success{
  padding: 20px;
  margin-bottom: 20px;
}
.actTable{
  table{
    width: 100%;
    margin-bottom: 25px;


    & th {
      font-size: 14px;
      font-weight: bold;
      color: #000;
      border-right: 1px solid #eee;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      position: relative;
      text-align: center;
      user-select: none;
      background: #ddd;
      padding: 10px 0;
    }
  }
}
</style>