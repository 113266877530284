import BaseApi from "./BaseApi";

class MarketingAPI extends BaseApi {

    get_auto_push_templates(){
        return this.axios.get('/marketing/auto-push-templates',);
    }

    get_auto_push_template(id){
        return this.axios.get('/marketing/auto-push-template/'+id,);
    }

    edit_auto_push_template(data){
        return this.axios.post('/marketing/auto-push-template/edit', data);
    }

    add_auto_push_template(data){
        return this.axios.post('/marketing/auto-push-template/add', data);
    }

    get_push_history(id){
        if(id !== null){
            return (this.axios).post('/marketing/get-push', {partner_id: id});
        }else {
            return {status: 500};
        }
    }

    add_auto_push(data){
        return this.axios.post('/marketing/add-auto-push', data);
    }

    edit_auto_push(data){
        return this.axios.post('/marketing/edit-auto-push', data);
    }

    delete_auto_push(id){
        return this.edit_auto_push({
            id: id,
            is_deleted: true
        });
    }

    auto_push(data){
        return this.axios.post('/marketing/auto-marketing', data);
    }

    add_push(data){
        return this.axios.post('/marketing/add-push', data);
    }

    get_customers_base(){
        return this.axios.get('/marketing/get-customers');
    }

    get_default_segments(p_id){
        return this.axios.get('/marketing/default-segments');
    }

    get_default_segment(id){
        return this.axios.get('/marketing/default-segments/'+id);
    }

    edit_default_segments(data){
        return this.axios.post('/marketing/edit-default-segments', data);
    }

    add_default_segment(data){
        return this.axios.post('/marketing/add-default-segments', data);
    }

    get_custom_segments(data){
        return this.axios.post('/marketing/segments', data);
    }

    get_custom_segment(id, data){
        return this.axios.post('/marketing/segments/'+id, data);
    }

    edit_custom_segments(data){
        return this.axios.post('/marketing/edit-custom-segments', data);
    }

    add_custom_segments(data){
        return this.axios.post('/marketing/add-custom-segments', data);
    }

    get_push_segment_info(data){
        return this.axios.post('/marketing/segment-info', data);
    }

    get_statistics(data){
        return this.axios.post('/marketing/statistics', data);
    }

    get_promocodes(){
        return this.axios.get('/marketing/promocodes');
    }

}

export default new MarketingAPI();
