<template>
  <Settings></Settings>
</template>
<script>
import Settings from '../../components/settings'
export default {
  components: {
    Settings
  }
}
</script>

