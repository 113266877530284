<template>
  <div>
    <wrapper_navigation
        ElementHeader="Шаблоны аудитории"
        Description="Устанавливайте стандартные шаблоны аудитории которые будут отображаться у всех клиентов"
        @click.native="open_page('templates-segments')"
    />
<!--    <wrapper_navigation-->
<!--        ElementHeader="Шаблоны специальных предложений"-->
<!--        Description="Устанавливайте стандартные шаблоны специальных предложений будут отображаться у всех клиентов"-->
<!--        @click.native="open_page('templates-special-offers')"-->
<!--    />-->
    <wrapper_navigation
        ElementHeader="Шаблоны автоматических пуш уведомлений"
        Description="Устанавливайте стандартные шаблоны автоматических пуш уведомлений будут отображаться у всех клиентов"
        @click.native="open_page('templates-automatic-push')"
    />
  </div>
</template>

<script>
import wrapper_navigation from '@/components/elements/wrapper_navigation';

export default {
  components: {
    wrapper_navigation
  },
  methods: {
    open_page: function (pageName){
      this.$router.push(this.$route.path+'/'+pageName);
    }
  }
}
</script>
