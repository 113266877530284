<template>
  <div class="container__auto_push__class">
    <h2 class="container__auto_push__class_h2">{{ data.template_class_name }}</h2>
    <div class="container__auto_push__class_content">
      <div class="container__auto_push__class_content__wrapper">
        <AutoPushCard  v-for="(card, key) in data.content" :ActivatorText="data.activator_text" :cardInfo="card" key="key"/>
        <div class="add_auto_push" @click="createAutoPushCard(data.id)">
          <span>Добавить</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AutoPushCard from '@/components/Marketing/AutoPush/AutoPushCard';
import MarketingAPI from "../../../../api/MarketingAPI";
export default {
  props: ['partner_id', 'data'],
  components: {
    AutoPushCard
  },
  methods: {
    createAutoPushCard: function (template_id){
      MarketingAPI.add_auto_push({template_id: template_id, partner_id: this.partner_id})
          .then(response => {
            this.$root.$emit('updatePushes');
          })
          .catch((e) => {
            console.log(e);
          })
    }
  }
}
</script>

<style scoped lang="scss">
.container__auto_push__class{
  margin-bottom: 40px;

  & .container__auto_push__class_h2{
    font-size: 16px;
  }
  & .container__auto_push__class_content{
    margin: 15px 0;
    width: 100%;
    overflow: hidden;
    overflow-x: scroll;

    & .container__auto_push__class_content__wrapper{
      display: flex;

      width: max-content;
    }

    & .add_auto_push{
      margin: 4px;
      background-color: #fafafa;
      border: 1px dashed #d9d9d9;
      border-radius: 4px;
      transition: border-color 0.3s ease;
      padding: 15px;
      position: relative;
      vertical-align: middle;
      font-weight: bold;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      &:hover {
        background-color: rgba(24, 144, 255, 0.15);
        border: 1px dashed #1890FF;
      }
    }
  }
}
</style>