<template>
  <div>


    <div class="wrapper">
      <div>
        <div @click="form.transfer_orders_to_telegram = !form.transfer_orders_to_telegram; editSettings()">
          <h6>{{ TextLocalization.TelegramTransfer[language] }}</h6>
          <p>{{ TextLocalization.TelegramTransferDescribeText[language] }}</p>
        </div>
        <a-input v-show="form.transfer_orders_to_telegram" class="textBanner" v-model="form.telegram_chat_id" @focusout="this.editSettings"></a-input>
      </div>
      <div>
        <a-switch default-checked v-model="form.transfer_orders_to_telegram" @change="this.editSettings"/>
      </div>
    </div>

    <div class="wrapper">
      <div>
        <div @click="form.transfer_orders_to_email = !form.transfer_orders_to_email; editSettings()">
          <h6>{{ TextLocalization.EmailTransfer[language] }}</h6>
          <p>{{ TextLocalization.EmailTransferDescribeText[language] }}</p>
        </div>
        <a-input v-show="form.transfer_orders_to_email" class="textBanner" v-model="form.transfer_email" @focusout="this.editSettings"></a-input>
      </div>
      <div>
        <a-switch default-checked v-model="form.transfer_orders_to_email" @change="this.editSettings"/>
      </div>
    </div>

  </div>
</template>

<script>
import ApplicationApi from "../../../api/ApplicationApi";

export default {

  data(){
    return {
      form: {
        id: null,
        partner_id: null,
        transfer_orders_to_telegram: false,
        transfer_orders_to_email: false,
        telegram_chat_id: null,
        transfer_email: null
      }
    }
  },
  created() {
    let AuthInfo = (this.$store.getters["User/getUserInfo"]);
    this.editor = AuthInfo.type;


    switch(this.editor){
      case 0:
        this.form.partner_id = Number(this.$route.params.id);
        break;
      case 1:
        this.form.partner_id = AuthInfo.partner_id;
        break;
    }
    this.getSettings();
  },
  methods: {
    getSettings: async function () {
      let settings = (await ApplicationApi.get_settings(this.form.partner_id)).data.data;

      this.form = {
        id: settings.id,
        partner_id: this.form.partner_id,
        transfer_orders_to_telegram: settings.transfer_orders_to_telegram,
        telegram_chat_id: settings.telegram_chat_id,
        transfer_orders_to_email: settings.transfer_orders_to_email,
        transfer_email: settings.transfer_email
      };
    },
    editSettings: async function(){
      await ApplicationApi.edit_settings(this.form);
      this.$root.$emit('createAlertGood');
    }
  }
}
</script>

<style scoped lang="scss">
.wrapper{
  background-color: #fafafa;
  border: 1px dashed #d9d9d9;
  padding: 15px 10px;
  margin: 10px 0 ;
  cursor: pointer;
  display: flex;

  & > div{
    flex: auto;
    &:nth-child(2){
      flex: none;
    }
  }

  & textarea{
    margin-bottom: 10px;
    padding: 10px;
    &.textBanner{
      margin-top: 10px;
      margin-bottom: 0;
      width: 100%;
    }
  }

  & input{
    margin-bottom: 10px;
    padding: 10px;
    margin-top: 10px;
  }

  & h6{
    margin-bottom: 10px;
  }
}
</style>