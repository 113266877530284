<template>
  <SpecialOffersIndex></SpecialOffersIndex>
</template>

<script>
import SpecialOffersIndex from '../../../../components/Marketing/SpecialOffer'
export default {
  components:{
    SpecialOffersIndex
  }
}
</script>