<template>
  <div class="statistics">
    <div class="statistics_filters">
      <a-row type="flex" :gutter="12">
        <a-col  :span="24" :lg="8">
          <a-form-model-item label="Дата начала отчета">
            <a-input type="date" v-model="filters.from"/>
          </a-form-model-item>
        </a-col>
        <a-col  :span="24" :lg="8">
          <a-form-model-item label="Дата начала отчета">
            <a-input type="date" v-model="filters.to"/>
          </a-form-model-item>
        </a-col>
        <a-col  :span="24" :lg="8">
          <a-form-model-item label="Группировать по">
            <a-select v-model="filters.group_by">
              <a-select-option value="day">День</a-select-option>
<!--              <a-select-option value="week">Неделя</a-select-option>-->
<!--              <a-select-option value="month">Месяц</a-select-option>-->
            </a-select>
          </a-form-model-item>
        </a-col>
      </a-row>
    </div>
    <a-row type="flex" :gutter="12">
      <a-col :span="24" :lg="12" class="mb-40 statistics_block">
        <h5>Количество заказов</h5>
        <BarChart :datacollection="orders"></BarChart>
      </a-col>
      <a-col :span="24" :lg="12" class="mb-40 statistics_block">
        <h5>Количество открытий приложения</h5>
        <BarChart :datacollection="app_views"></BarChart>
      </a-col>

      <a-col :span="24" :lg="12" class="mb-40 statistics_block">
        <h5>Количество пользователей открывших приложение</h5>
        <BarChart :datacollection="app_views_unique"></BarChart>
      </a-col>

      <a-col :span="24" :lg="12" class="mb-40 statistics_block">
        <h5>Выручка из приложения</h5>
        <BarChart :datacollection="app_revenue"></BarChart>
      </a-col>


    </a-row>
  </div>
</template>

<script>
import BarChart from '@/components/elements/BarChart'
import MarketingAPI from "../../../../api/MarketingAPI";

export default {
  components: {
    BarChart
  },
  watch: {
    filters: {
      handler(){
        this.getData();
      },
      deep: true
    }
  },
  data(){
    return {
      filters: {
        from: null,
        to: null,
        group_by: 'day'
      },
      orders: null,
      app_views: null,
      app_views_unique: null,
      app_revenue: null
    }
  },
  methods: {
    getData: function (){
      MarketingAPI.get_statistics(this.filters)
          .then(response => {
            response.data.status === 401 ? this.$store.commit('User/EXIT_USER') : null;

            this.orders = response.data.data.orders;
            this.app_views = response.data.data.app_views;
            this.app_views_unique = response.data.data.app_views_unique;
            this.app_revenue = response.data.data.app_revenue;
          })
          .catch((e) => {
            console.log(e);
          });
    },
    formatDate: function (date) {
      var dd = date.getDate();
      if (dd < 10) dd = '0' + dd;
      var mm = date.getMonth() + 1;
      if (mm < 10) mm = '0' + mm;
      var yy = date.getFullYear();

      return yy + '-' + mm + '-' + dd;
    }
  },
  mounted() {
    let today = new Date();
    let WeekAgo = new Date();
    WeekAgo.setDate(today.getDate()-8);

    this.filters = {
      from: this.formatDate(WeekAgo),
      to: this.formatDate(today),
      group_by: 'day'
    }
    this.getData();
  }
}
</script>

<style lang="scss">
.statistics_block{
    & h5{
      margin-left: 10px;
      margin-bottom: 20px;
      font-size: 16px;
    }
}
.statistics_filters{
  background: #dbdbdb;
  padding: 20px;
  margin-bottom: 35px;
}
</style>