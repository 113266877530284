<template>
  <div class="segments_container">
    <h6>Шаблоны:</h6>
    <div class="segments">
      <div v-for="(dSegment, key) in defaultSegments "
           @click="makeFilter(dSegment.min_orders,dSegment.max_orders,dSegment.min_proceeds,dSegment.max_proceeds,dSegment.min_avg_cost,dSegment.max_avg_cost,dSegment.min_first_order,dSegment.max_first_order,dSegment.min_last_order_days,dSegment.max_last_order_days)"
      >{{ dSegment.name }}
      </div>
      <div v-for="(dSegment, key) in segments "
           @click="makeFilter(dSegment.min_orders,dSegment.max_orders,dSegment.min_proceeds,dSegment.max_proceeds,dSegment.min_avg_cost,dSegment.max_avg_cost,dSegment.min_first_order,dSegment.max_first_order,dSegment.min_last_order_days,dSegment.max_last_order_days)"
      >{{ dSegment.name }}
        - <router-link :to="'/clients/segment/'+dSegment.id">редактировать</router-link>
      </div>
      <div class="add_segment" @click="$router.push('/clients/segment/add')">добавить шаблон</div>
    </div>
  </div>
</template>

<script>
export default {
  props: [ 'defaultSegments', 'segments'],
  methods: {
    makeFilter: function (min_orders,max_orders,min_proceeds,max_proceeds,min_avg_cost,max_avg_cost,min_first_order,max_first_order,min_last_order_days,max_last_order_days) {
      this.$parent.$emit('makeFilter', {
        min_orders: min_orders,
        max_orders: max_orders,
        min_proceeds: min_proceeds,
        max_proceeds: max_proceeds,
        min_avg_cost: min_avg_cost,
        max_avg_cost: max_avg_cost,
        min_first_order: min_first_order,
        max_first_order: max_first_order,
        min_last_order_days: min_last_order_days,
        max_last_order_days: max_last_order_days,
      });
    }
  }
}
</script>

<style scoped lang="scss">
.segments_container{
  vertical-align: top;
  background-color: #fafafa;
  border: 1px dashed #d9d9d9;
  border-radius: 4px;
  transition: border-color 0.3s ease;
  width: 100%;
  position: relative;
  padding: 5px;
  & > h6{
    margin: 5px 5px 10px 5px;
    color: #000;
    font-weight: bold;
    font-size: 14px;
  }
  & > .segments > *{
    cursor: pointer;
    margin: 8px 0;
    vertical-align: top;
    background-color: #ffffff;
    border: 1px dashed #d9d9d9;
    border-radius: 4px;
    transition: border-color 0.3s ease;
    padding: 10px 15px;
    //font-weight: bold;
    line-height: 1.25;
    font-size: 13px;
    position: relative;
    color: #000;
    &:nth-child(1){
      margin-top: 0;
    }
    &:last-child{
      margin-bottom: 0;
    }
    &:hover{
      background-color: rgba(24, 144, 255, 0.15);
      border: 1px dashed #1890FF;
    }
    &.add_segment{
      font-weight: bold;
      text-transform: uppercase;
      text-align: center;
      border: 1px dashed #1890FF;
      background: #1890FF;
      color: #fff;
      &:hover{
        text-decoration: underline;
      }
    }
  }
}
</style>