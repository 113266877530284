<template>
  <templatesSegments></templatesSegments>
</template>

<script>
import templatesSegments from '@/components/Marketing/index-admin-page/templates-segments/index';

export default {
  components: {
    templatesSegments
  }
}
</script>

<style scoped>

</style>