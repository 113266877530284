z<template>
  <Menu></Menu>
</template>
<script>
import Menu from '../../components/Menu/menu'
export default {
  components: {
    Menu
  }
}
</script>

