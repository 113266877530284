<template>
  <a-row type="flex" :gutter="24">
    <a-col :span="24" :md="24">
      <a-input type="hidden" v-model="form.partner_id"/>
      <a-form-model-item :label="TextLocalization.CategoryName[language]">
        <a-input v-model="form.name" />
      </a-form-model-item>
<!--      <a-form-model-item :label="TextLocalization.CategoryDescription[language]">-->
<!--        <a-textarea rows="4" v-model="form.description"/>-->
<!--      </a-form-model-item>-->
      <a-form-model-item>
        <a-row type="flex" :gutter="24" class="buttons_container">
          <a-col :span="12">
            <a-button type="primary" @click="onSubmit">
              {{this.$route.params.catID === undefined ? this.TextLocalization.Add[language] : TextLocalization.Edit[language] }}
            </a-button>
          </a-col>
          <a-col :span="12">
            <a-button type="danger" @click="showModalDelete">
              {{TextLocalization.Delete[language]}}
            </a-button>
          </a-col>
        </a-row>

      </a-form-model-item>
    </a-col>
    <a-modal
        :title="TextLocalization.AreYouSure[language]"
        :visible="visible"
        :confirm-loading="confirmLoading"
        @ok="handleOk"
        @cancel="handleCancel"
    >
      {{ TextLocalization.AreYouSureYouWantToDelete[language] }}
    </a-modal>
  </a-row>
</template>
<script>
import MenuAPI from "../../../api/MenuAPI";
import FormPartnerID from "@/mixins/form-partnerID";

export default {
  mixins: [FormPartnerID],
  data() {
    return {
      visible: false,
      confirmLoading: false,
      form: {
        partner_id: null,
        name: '',
        description: ''
      },
    };
  },
  methods: {
    onSubmit() {
      if (this.$route.params.catID === undefined){
        this.add_category(this.form);
      } else {
        this.edit_category(this.form);
      }
    },
    get_data(category_id){
      MenuAPI.get_category(category_id)
          .then(response => {
            response.data.status === 401 ? this.$store.commit('User/EXIT_USER') : null;
            switch (response.data.status){
              case 200:
                this.form = response.data.data;
                break;
              default:
                this.data = {
                  name: '',
                  description: ''
                };
                break;
            }
          })
          .catch((e) => {
            console.log(e);
          })
    },
    edit_category(data){
      MenuAPI.edit_category(data)
          .then(response => {
            response.status === 401 ? this.$store.commit('User/EXIT_USER') : null;
            switch (response.data.status){
              case 200:
                this.form = response.data.data;
                this.$root.$emit("createAlertGood");
                break;
              default:
                this.data = {
                  name: '',
                  description: ''
                };
                this.$root.$emit("createAlertError");
                break;
            }
          })
          .catch((e) => {
            console.log(e);
          })
    },
    add_category(data){
      MenuAPI.add_category(data)
          .then(response => {
            response.status === 401 ? this.$store.commit('User/EXIT_USER') : null;
            switch (response.data.status){
              case 200:
                this.form = response.data.data;
                this.$store.dispatch('BackButton/setGoBack', -2);
                switch(this.editor){
                  case 0:
                    this.$router.push('/applications/'+data.partner_id+'/category/'+response.data.data.id);
                    break;
                  case 1:
                    this.$router.push('/menu/category/'+response.data.data.id);
                    break;
                }
                this.$root.$emit("createAlertGood");
                break;
              default:
                this.data = {
                  name: '',
                  description: ''
                };
                this.$root.$emit("createAlertError");
                break;
            }
          })
          .catch((e) => {
            console.log(e);
          })
    },
    delete(data){
      MenuAPI.delete_category(data)
          .then(response => {
            response.status === 401 ? this.$store.commit('User/EXIT_USER') : null;
            switch (response.data.status){
              case 200:
                switch(this.$store.getters['BackButton/goBack']){
                  case -1:
                    this.$store.dispatch('BackButton/setGoBack', -3);
                    break;
                  case -2:
                    this.$store.dispatch('BackButton/setGoBack', -4);
                    break;
                }
                console.log(this.editor);
                switch(this.editor){
                  case 0:
                    this.$router.push('/applications/'+this.form.partner_id+'/Menu');
                    break;
                  case 1:
                    this.$router.push('/menu');
                    break;
                }
                break;
              default:
                this.data = {};
                break;
            }
          })
          .catch((e) => {
            console.log(e);
          })
    },
    showModalDelete() {
      this.visible = true;
    },
    handleOk(e) {
      this.confirmLoading = true;
      this.delete({id: this.form.id});
      this.visible = false;
      this.confirmLoading = false;
    },
    handleCancel(e) {
      this.visible = false;
    }
  },
  created() {
    if (this.$route.params.catID !== undefined){
      this.get_data(this.$route.params.catID);
    }
  }
};
</script>

<style>
.ant-form-item-label{
  line-height: inherit!important;
}
.ant-form-item{
  margin-bottom: 0;
}
.buttons_container .ant-btn-primary, .buttons_container .ant-btn-danger{
  width: 100%!important;
  margin: 0!important;
}
</style>