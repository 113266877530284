<template>
  <ReceivedPayments></ReceivedPayments>
</template>

<script>
import ReceivedPayments from '@/components/Reports/ReceivedPayments';

export default {
  components: {
    ReceivedPayments
  }
}
</script>

<style scoped>

</style>