<template>
  <div>
    <table>
      <tr>
        <th>Всего пользователей</th>
        <td>{{ form.all_base }}</td>
      </tr>
      <tr>
        <th>Обьем выбранной группы</th>
        <td>{{ form.group_size }}</td>
      </tr>
    </table>
  </div>
</template>

<script>
export default {
  name: "push-recipients-info",
  props: ['form']
}
</script>

<style scoped lang="scss">
table{
  margin-top: 30px;
  width: 100%;
  & th {
    font-size: 14px;
    font-weight: bold;
    white-space: nowrap;
    cursor: pointer;
    border: 1px solid #d9d9d9;
    background: #fff;
    padding: 10px;
    width: 200px;
  }
  & tr{
    cursor: pointer;
    border: 1px solid #d9d9d9;
    font-size: 14px;
    background: #fff;
    padding: 10px;

    &:hover{
      & td, & th{
        background: #fff5ee;
      }
    }

    & td {
      border: 1px solid #d9d9d9;
      font-size: 14px;
      padding: 10px;
    }
  }
}
</style>