import BaseApi from "./BaseApi";

class ApplicationSpecialOffersApi extends BaseApi {

    get_all(id) {
        return this.axios.post('/application/special-offers/', {partner_id: id});
    }

    get(id) {
        return this.axios.get('/application/special-offers/'+id);
    }

    edit(data) {
        delete data.token;
        return  this.axios.post('/application/special-offers/edit', data);
    }

    add(data) {
        return this.axios.post('/application/special-offers/add', data);
    }

    delete(data) {
        return this.axios.post('/application/special-offers/delete', data);
    }
}

export default new ApplicationSpecialOffersApi();
