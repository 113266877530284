<template>
  <SettingsDeliveryZone></SettingsDeliveryZone>
</template>

<script>
import SettingsDeliveryZone from '@/components/Settings/SettingsDeliveryZone';
export default {
  components: {
    SettingsDeliveryZone
  }
}
</script>

<style scoped>

</style>