<template>
  <a-row type="flex" :gutter="24">
    <a-col :span="24" :md="24">
      <a-input type="hidden" v-model="form.partner_id"/>
      <a-form-model-item :label="TextLocalization.Surname[language]">
        <a-input v-model="form.surname" />
      </a-form-model-item>
      <a-form-model-item :label="TextLocalization.Name[language]">
        <a-input v-model="form.name" />
      </a-form-model-item>
      <a-form-model-item :label="TextLocalization.Lastname[language]">
        <a-input v-model="form.lastname" />
      </a-form-model-item>
      <a-form-model-item :label="TextLocalization.Position[language]">
        <a-input v-model="form.position" />
      </a-form-model-item>
      <a-form-model-item label="Email">
        <a-input v-model="form.email" />
      </a-form-model-item>
      <a-form-model-item :label="TextLocalization.PhoneNumber[language]">
        <a-input v-model="form.phone1" />
      </a-form-model-item>
      <a-form-model-item :label="TextLocalization.PhoneNumber[language]">
        <a-input v-model="form.phone2" />
      </a-form-model-item>
      <a-form-model-item :label="TextLocalization.PhoneNumber[language]">
        <a-input v-model="form.phone3" />
      </a-form-model-item>

      <a-form-model-item>
        <a-row type="flex" :gutter="24" class="buttons_container">
          <a-col :span="24" :lg="this.$route.params.contactID !== undefined ? 12 : 24" :md="24">
            <a-button type="primary" @click="onSubmit">
              {{this.$route.params.contactID === undefined ? this.TextLocalization.Add[this.language] : this.TextLocalization.Edit[this.language]}}
            </a-button>
          </a-col>
          <a-col :span="12" :md="12">
            <a-button type="danger" @click="showModalDelete" v-if="this.$route.params.contactID !== undefined">
              {{ TextLocalization.Delete[language] }}
            </a-button>
          </a-col>
        </a-row>
      </a-form-model-item>
    </a-col>
    <a-modal
        :title="TextLocalization.AreYouSure[language]"
        :visible="visible"
        :confirm-loading="confirmLoading"
        @ok="handleOk"
        @cancel="handleCancel"
    >
      {{ TextLocalization.AreYouSureYouWantToDelete[language] }}
    </a-modal>
  </a-row>
</template>
<script>
import ApplicationContactsApi from "../../../../api/ApplicationContactsApi";
import Config from "@/config";

export default {
  data() {
    return {
      visible: false,
      confirmLoading: false,
      form: {
        partner_id: null,
        name: '',
        surname: '',
        lastname: '',
        email: '',
        position: '',
        phone1: '',
        phone2: '',
        phone3: ''
      },
    };
  },
  methods: {
    onSubmit() {
      if (this.$route.params.contactID === undefined){
        this.add(this.form);
      } else {
        this.edit(this.form);
      }
    },
    get_data(contact_id){
      ApplicationContactsApi.get(contact_id)
          .then(response => {
            response.data.status === 401 ? this.$store.commit('User/EXIT_USER') : null;
            switch (response.data.status){
              case 200:
                this.form = response.data.data;
                break;
              default:
                this.data = {
                  partner_id: null,
                  name: '',
                  surname: '',
                  lastname: '',
                  email: '',
                  position: '',
                  phone1: '',
                  phone2: '',
                  phone3: ''
                };
                break;
            }
          })
          .catch((e) => {
            console.log(e);
          })
    },
    edit(data){
      ApplicationContactsApi.edit(data)
          .then(response => {
            response.data.status === 401 ? this.$store.commit('User/EXIT_USER') : null;
            switch (response.data.status){
              case 200:
                this.form = response.data.data;
                this.$root.$emit('createAlertGood');
                break;
              default:
                this.data = {
                  partner_id: null,
                  name: '',
                  surname: '',
                  lastname: '',
                  email: '',
                  position: '',
                  phone1: '',
                  phone2: '',
                  phone3: ''
                };
                break;
            }
          })
          .catch((e) => {
            console.log(e);
          })
    },
    add(data){
      ApplicationContactsApi.add(data)
          .then(response => {
            response.status === 401 ? this.$store.commit('User/EXIT_USER') : null;
            switch (response.status){
              case 200:
                this.form = response.data.data;
                this.$store.dispatch('BackButton/setGoBack', -2);
                this.$router.push('/applications/'+data.partner_id+'/contact/'+response.data.data.id);
                this.$root.$emit('createAlertGood');
                break;
              default:
                this.data = {
                  partner_id: null,
                  name: '',
                  surname: '',
                  lastname: '',
                  email: '',
                  position: '',
                  phone1: '',
                  phone2: '',
                  phone3: ''
                };
                break;
            }
          })
          .catch((e) => {
            console.log(e);
          })
    },
    delete(data){
      ApplicationContactsApi.delete(data)
          .then(response => {
            response.data.status === 401 ? this.$store.commit('User/EXIT_USER') : null;
            switch (response.data.status){
              case 200:
                switch(this.$store.getters['BackButton/goBack']){
                  case -1:
                    this.$store.dispatch('BackButton/setGoBack', -3);
                    break;
                  case -2:
                    this.$store.dispatch('BackButton/setGoBack', -4);
                    break;
                }
                this.$router.push('/applications/'+this.$route.params.id);
                this.$root.$emit('createAlertGood');
                break;
              default:
                this.data = {};
                break;
            }
          })
          .catch((e) => {
            console.log(e);
          })
    },
    showModalDelete() {
      this.visible = true;
    },
    handleOk(e) {
      this.confirmLoading = true;
      this.delete({id: this.form.id});
      this.visible = false;
      this.confirmLoading = false;
    },
    handleCancel(e) {
      this.visible = false;
    }
  },
  mounted(){
    if (this.$route.params.contactID !== undefined){
      this.get_data(this.$route.params.contactID);
    }
  },
  created() {
    this.form.partner_id = this.$route.params.id
  }
};
</script>

<style>
.ant-form-item-label{
  line-height: inherit!important;
}
.ant-form-item{
  margin-bottom: 0;
}
.buttons_container .ant-btn-primary, .buttons_container .ant-btn-danger{
  width: 100%!important;
  margin: 0!important;
}
</style>
