<template>
  <tr>
    <td>{{ data.id }}</td>
    <td>{{ data.name }}</td>
    <td>{{ data.dimension }}</td>
    <td>{{ formatterSignificantDigits(data.price) }}</td>
  </tr>
</template>

<script>
export default {
  props: ['data']
}
</script>

<style scoped lang="scss">
tr{
  cursor: pointer;

  &:hover{
    & td{
      background: #fff5ee;
    }
  }

  & td{
    border: 1px solid #eee;
    font-size: 14px;
    padding: 10px;
  }
}
</style>