import BaseApi from "./BaseApi";

class OrdersApi extends BaseApi {

  getAllOrders (id) {
    return this.axios.get('/orders/'+id);
  }

  changeOrderStatus(data){
    return this.axios.post('/orders/edit-status', data);
  }

}

export default new OrdersApi();
