<template>
  <a-row type="flex" :gutter="[24,24]" align="stretch">
    <a-col :span="24">
      Баланс на текущий момент: <span :class="data.balance > 0 ? 'positive_balance': 'minus_balance' ">{{formatter(data.balance)}}</span>
    </a-col>
    <a-col :span="24" :md="12">
      <h6>Счета на оплату:</h6>
      <a-table :columns="columns" :data-source="data.invoice" :pagination="false">
        <template slot="dates" slot-scope="dates">
          <h6 class="m-0">
            {{ name }}
          </h6>
        </template>
        <template slot="amount" slot-scope="amount">
          <h6 class="m-0" >
            {{ formatter(amount) }}
          </h6>
        </template>
        <template slot="document" slot-scope="document">
          <div class="download" @click="downloadPDF(document)">скачать PDF</div>
        </template>
      </a-table>
    </a-col>
    <a-col :span="24" :md="12">
      <h6>Акты выполненных работ:</h6>
      <div>
        <a-table :columns="columns" :data-source="data.act" :pagination="false">
          <template slot="dates" slot-scope="dates">
            <h6 class="m-0">
              {{ name }}
            </h6>
          </template>
          <template slot="amount" slot-scope="amount">
            <h6 class="m-0">
              {{ formatter(amount) }}
            </h6>
          </template>
          <template slot="document" slot-scope="document">
            <div class="download" @click="downloadPDF(document)">скачать PDF</div>
          </template>
        </a-table>
      </div>
    </a-col>
  </a-row>
</template>

<script>
import certificateOfCompletionCard from './Cards/СertificateOfCompletionCard'
import ApplicationApi from "../../api/ApplicationApi";
import PartnerID from "@/mixins/partnerID";

const columns = [
  {
    title: 'Период',
    dataIndex: 'name',
    scopedSlots: { customRender: 'name' },
  },
  {
    title: 'Сумма',
    dataIndex: 'amount',
    scopedSlots: { customRender: 'amount' },
  },
  {
    title: 'Документ',
    scopedSlots: { customRender: 'document' },
  },
];

export default {
  mixins: [PartnerID],
  components: {
    certificateOfCompletionCard
  },
  data(){
    return {
      columns,
      data: {}
    }
  },
  created() {
    if (this.partner_id){
      this.get_partner_documents(this.partner_id);
    }
  },
  methods: {
    get_partner_documents: async function (id){
      this.data = (await ApplicationApi.get_documents(id)).data.data;
    },
    downloadPDF: async function (data){
      let pdfMake = require('pdfmake/build/pdfmake.js');
      let pdfFonts = require('pdfmake/build/vfs_fonts.js');
      pdfMake.vfs = pdfFonts.pdfMake.vfs;

      await pdfMake.createPdf(data.document).open();
    }
  }
}
</script>

<style>
.ant-form-item-label{
  line-height: inherit!important;
}
.ant-form-item{
  margin-bottom: 0;
}
.buttons_container .ant-btn-primary, .buttons_container .ant-btn-danger{
  width: 100%!important;
  margin: 0!important;
}
.download{
  cursor: pointer;
}

.positive_balance{
  font-weight: bold;
  color: #1890FF;
}

.minus_balance{
  font-weight: bold;
  color: #F5222D;
}
</style>