<template>
  <SettingsPaymentMethod></SettingsPaymentMethod>
</template>

<script>
import SettingsPaymentMethod from '@/components/Settings/SettingsPaymentMethod';
export default {
  components: {
    SettingsPaymentMethod
  }
}
</script>

<style scoped>

</style>