<template>
  <div>
    <a-row type="flex" :gutter="24" class="buttons_container mb-25">
      <a-col :span="24" :lg="24" :md="24">
        <a-button type="primary" @click="open('add')">
          Добавить шаблон авто пуша
        </a-button>
      </a-col>
    </a-row>
    <table>
      <tr>
        <th style="min-width: 200px ">Название группы</th>
        <th>Описание в карточке</th>
        <th>Отображается?</th>
      </tr>
      <tr v-for="(push, sKey) in pushes" :key="sKey" @click="open(push.id)">
        <td>{{sKey+1}}. {{ push.template_class_name }}</td>
        <td>{{ push.activator_text }}</td>
        <td>{{ push.visible | trueFalse }}</td>
      </tr>
    </table>
  </div>
</template>

<script>
import MarketingAPI from "../../../../api/MarketingAPI";

export default {
  data(){
    return {
      pushes: []
    }
  },
  methods: {
    open: function (id) {
      this.$router.push('/marketing/templates-automatic-push/' + id);
    },
    getData: function () {
      MarketingAPI.get_auto_push_templates()
          .then(response => {
            response.data.status === 401 ? this.$store.commit('User/EXIT_USER') : null;
            this.pushes = response.data.data;
          })
          .catch((e) => {
            console.log(e);
          });
    }
  },
  created(){
    this.getData();
  }
}
</script>

<style scoped lang="scss">
table{
  width: 100%;
  & th {
    font-size: 14px;
    font-weight: bold;
    color: #000;
    border-right: 1px solid #eee;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    position: relative;
    text-align: center;
    user-select: none;
    background: #ddd;
    padding: 10px 0;
  }

  tr{
    cursor: pointer;
    &:hover{
      & td{
        background: #fff5ee;
      }
    }
    & td{
      border: 1px solid #eee;
      font-size: 14px;
      padding: 10px;
    }
  }
}
</style>