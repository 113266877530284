<template>
<div>
  <a-input type="hidden" v-model="form.id"/>
  <a-input type="hidden" v-model="partner_id"/>
  <a-form-model-item :label="TextLocalization.Title[language]">
    <a-input v-model="form.title" />
  </a-form-model-item>
  <a-form-model-item :label="TextLocalization.Description[language]">
    <a-textarea rows="4" v-model="form.description"/>
  </a-form-model-item>

  <a-form-model-item label="Отображать акцию?">
    <a-switch v-model="form.show_in_app" />
  </a-form-model-item>

  <a-form-model-item :label="TextLocalization.Image[language]">
    <input type="file" id="file" ref="file" v-on:change="handleFileUpload()"/>
    <p class="recomendations">Рекомендуемый размер картинки ширина: 582px, высота: 360px</p>
  </a-form-model-item>

  <a-form-model-item class="mt-15">
    <a-row type="flex" :gutter="24" class="buttons_container">
      <a-col :span="24" :lg="12" :md="24">
        <a-button type="primary" @click="edit">
          {{ this.TextLocalization.Edit[this.language]}}
        </a-button>
      </a-col>
      <a-col :span="24" :lg="12" :md="24">
        <a-button type="danger" @click="showModalDelete">
          {{TextLocalization.Delete[this.language]}}
        </a-button>
      </a-col>
    </a-row>
  </a-form-model-item>

  <a-modal
      :title="TextLocalization.AreYouSure[this.language]"
      :visible="visible"
      :confirm-loading="confirmLoading"
      @ok="handleOk"
      @cancel="handleCancel"
  >
    {{ TextLocalization.AreYouSureYouWantToDelete[this.language] }}
  </a-modal>
</div>
</template>

<script>
import ApplicationSpecialOffersApi from "../../../../api/ApplicationSpecialOffersApi";
import axios from "axios";

export default {
  props: ['form', 'partner_id'],
  data() {
    return {
      visible: false,
      confirmLoading: false,
      selectOptions: [
        {
          value: false,
          name: "Нет"
        },
        {
          value: true,
          name: "Да"
        }
      ]
    }
  },
  methods: {
    edit: async function (){
      if(this.file) {
        let formData = new FormData();
        let img_src = null;
        formData.append('myFile', this.file);
        await axios.put('//178.172.136.145:3000/upload/',
            formData,
            {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            }).then(function (result) {
          img_src = result.data.data;
        }).catch(function (e) {
          console.log(e);
        });
        this.form.image = 'https://backend.gastrosoft.by/img/' + img_src;
      }
      ApplicationSpecialOffersApi.edit(this.form)
          .then(response => {
            response.data.status === 401 ? this.$store.commit('User/EXIT_USER') : null;
            this.form = response.data;
            this.$root.$emit('reloadSpecialOffers', {});
            this.$root.$emit("createAlertGood");
          })
          .catch((e) => {
            console.log(e);
          })
    },
    delete(data){
      ApplicationSpecialOffersApi.delete(data)
          .then(response => {
            response.data.status === 401 ? this.$store.commit('User/EXIT_USER') : null;
          })
          .catch((e) => {
            console.log(e);
          })
      this.$root.$emit('reloadSpecialOffers', {});
    },
    showModalDelete() {
      this.visible = true;
    },
    handleOk(e) {
      this.confirmLoading = true;
      this.delete({id: this.form.id});
      this.visible = false;
      this.confirmLoading = false;
    },
    handleCancel(e) {
      this.visible = false;
    },
    handleFileUpload(){
      this.file = this.$refs.file.files[0];
    }
  }
}
</script>

<style scoped>
.recomendations{
  line-height: normal;
  margin-top: 15px;
}
</style>