<template>
  <div>
    <div class="wrapper">
      <div  @click="form.take_pickup_orders = !form.take_pickup_orders; editSettings();">
        <h6>{{ TextLocalization.PickupOrders[language] }}</h6>
        <p>{{ TextLocalization.PickupOrdersDescribeText[language] }}</p>
      </div>
      <div>
        <a-switch default-checked v-model="form.take_pickup_orders" @change="editSettings"/>
      </div>
    </div>

    <div class="wrapper">
      <div @click="form.take_delivery_orders = !form.take_delivery_orders; editSettings();">
        <h6>{{ TextLocalization.CourierOrders[language] }}</h6>
        <p>{{ TextLocalization.CourierOrdersDescribeText[language] }}</p>
      </div>
      <div>
        <a-switch default-checked v-model="form.take_delivery_orders" @change="editSettings"/>
      </div>
    </div>
  </div>
</template>

<script>
import ApplicationApi from "../../../api/ApplicationApi";
import FormPartnerID from "@/mixins/form-partnerID";

export default {
  mixins: [FormPartnerID],
  data(){
    return {
      form: {
        id: null,
        partner_id: null,
        take_pickup_orders: undefined,
        take_delivery_orders: undefined
      }
    }
  },
  created() {
    this.getSettings();
  },
  methods: {
    getSettings: async function () {
      let settings = (await ApplicationApi.get_settings(this.form.partner_id)).data.data;

      this.form = {
        id: settings.id,
        partner_id: this.form.partner_id,
        take_pickup_orders: settings.take_pickup_orders,
        take_delivery_orders: settings.take_delivery_orders
      };
    },
    editSettings: async function(){
      await ApplicationApi.edit_settings(this.form);
      this.$root.$emit('createAlertGood');
    }
  }
}
</script>

<style scoped lang="scss">
.wrapper{
  background-color: #fafafa;
  border: 1px dashed #d9d9d9;
  padding: 15px 10px;
  margin: 10px 0 ;
  cursor: pointer;
  display: flex;

  & > div{
    flex: auto;
    &:nth-child(2){
      flex: none;
    }
  }
  & textarea{
    margin-bottom: 10px;
    padding: 10px;
    &.textBanner{
      margin-top: 10px;
      margin-bottom: 0;
      width: 100%;
    }
  }

  & h6{
    margin-bottom: 10px;
  }
}
</style>