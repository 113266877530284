import BaseApi from "./BaseApi";

class LegalAPI extends BaseApi {

    get_price_list(){
        return this.axios.get('/legal/price-list',);
    }
}

export default new LegalAPI();
