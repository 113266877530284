<template>
  <Finance></Finance>
</template>
<script>
import Finance from '../../components/FinanceReport'
export default {
  components: {
    Finance
  }
}
</script>

