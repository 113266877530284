<template>
  <Orders></Orders>
</template>

<script>
import Orders from '../../components/Orders';
export default {
  components: {
    Orders
  }
}
</script>

<style scoped>

</style>