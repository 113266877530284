<template>
  <div class="auto_push_settings_block">
    <div style="display: flex">
      <div style="display: flex; flex: auto;">
        <div style="font-size: 14px; flex: none; padding: 12px 10px 12px 0">
          {{ ActivatorText }}
        </div>
        <div>
          <a-input style="width: 50px" v-model="data.input_value" type="number" step="1" min="0"/>
        </div>
      </div>
      <div style="font-size: 14px; flex: none; padding-left: 10px">
        <a-switch v-model="data.active"/>
      </div>
    </div>
    <a-form-model-item label="Заголовок">
      <a-input class="regular-input" placeholder="Заголовок" v-model="data.push_header"/>
    </a-form-model-item>
    <a-form-model-item label="Текст">
      <a-textarea placeholder="Текст" v-model="data.push_content"></a-textarea>
    </a-form-model-item>
    <a-button class="mt-10 ant-btn ant-btn-danger" @click="deleteCard(data.id)">Удалить</a-button>
  </div>
</template>

<script>
import MarketingAPI from "../../../../api/MarketingAPI";

export default {
  props: ['ActivatorText', 'cardInfo'],
  data(){
    return{
      data: {}
    }
  },
  watch: {
    data: {
      handler(){
        this.editCard();
      },
      deep: true
    }
  },
  methods: {
    deleteCard(id){
      MarketingAPI.delete_auto_push(id)
          .then(response => {
            this.$root.$emit('updatePushes');
          })
          .catch((e) => {
            console.log(e);
          })
    },
    editCard(){
      this.data.input_value === "" ? this.data.input_value = null : '';
      MarketingAPI.edit_auto_push(this.data)
          .then(response => {
            this.$root.$emit('updatePushes');
          })
          .catch((e) => {
            console.log(e);
          })
    }
  },
  created(){
    this.data = this.cardInfo;
  }

}
</script>

<style scoped lang="scss">
div.auto_push_settings_block{
  max-width: 500px;
  margin: 4px;
  vertical-align: top;
  background-color: #fafafa;
  border: 1px dashed #d9d9d9;
  border-radius: 4px;
  transition: border-color 0.3s ease;
  width: 100%;
  padding: 15px;
  position: relative;
}
</style>