<template>
  <AddEditModifiers></AddEditModifiers>
</template>
<script>
import AddEditModifiers from '../../../../components/Menu/add-edit-modifiers'
export default {
  components: {
    AddEditModifiers
  }
}
</script>

