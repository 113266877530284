<template>
  <div>
    <SettingsSocialMedia></SettingsSocialMedia>

  </div>
</template>

<script>
import SettingsSocialMedia from '../../../components/Settings/SettingsSocialMedia';
export default {
  components: {
    SettingsSocialMedia
  }
}
</script>

<style scoped>

</style>