<template>
  <tr>
    <td>{{ data.surname }}</td>
    <td>{{ data.name }}</td>
    <td>{{ data.lastname }}</td>
    <td>{{ data.email }}</td>
    <td>{{ data.login }}</td>
    <td>{{ data.position }}</td>
    <td class="edit"><a-icon type="edit" @click="goTo(data)"/></td>
  </tr>
</template>

<script>
export default {
  mixins: [],
  name: "employeeCard",
  props: ['data'],
  methods: {
    goTo: function (data){
      switch (this.user_info.type){
        case 0:
          this.$router.push('/users/'+data.id);
          break;
        case 1:
          this.$router.push('/settings/users/'+data.id);
          break;
      }
    }
  },
  created() {
    this.user_info = (this.$store.getters["User/getUserInfo"]);
  }
}
</script>

<style scoped lang="scss">
tr{
  &:hover{
    & td{
      background: #fff5ee;
      &.edit{
        cursor: pointer;
      }
    }
  }

  & td{
    border: 1px solid #eee;
    font-size: 14px;
    padding: 10px;
    & i{
      margin: 0 auto;
      display: block;
    }
  }
}
</style>