<template>
  <SpecialOfferAddEdit></SpecialOfferAddEdit>
</template>

<script>
import SpecialOfferAddEdit from '../../../../components/Marketing/SpecialOffer/add-edit'
export default {
  components: {
    SpecialOfferAddEdit
  },
  name: "edit"
}
</script>

<style scoped>

</style>