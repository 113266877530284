<template>
  <SettingsOrderTransfer></SettingsOrderTransfer>
</template>

<script>
import SettingsOrderTransfer from '@/components/Settings/SettingsOrderTransfer';
export default {
  components: {
    SettingsOrderTransfer
  }
}
</script>

<style scoped>

</style>