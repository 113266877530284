<template>
  <div>
    <a-form-item label="Описание группы">
      <a-input v-model="data.template_class_name"/>
    </a-form-item>
    <a-form-item label="Описание внутри карточки ">
      <a-input v-model="data.activator_text"/>
    </a-form-item>


    <a-row type="flex"  :gutter="24">
      <a-col :span="8" :md="8">
          <a-form-item label="Влияние количества заказов">
            <a-select  v-model="data.settings.influence_orders">
              <a-select-option :value="true" >Да</a-select-option>
              <a-select-option :value="false">Нет</a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
      <a-col :span="8" :md="8">
          <a-form-item label="Кол-во заказов от">
            <a-input v-model="data.settings.min_orders_count" />
          </a-form-item>
        </a-col>
      <a-col :span="8" :md="8">
          <a-form-item label="Кол-во заказов до">
            <a-input v-model="data.settings.max_orders_count"/>
          </a-form-item>
        </a-col>
    </a-row>

    <a-row type="flex"  :gutter="24">
      <a-col :span="24" :md="24">
        <a-form-item label="Авторизован ли пользователь">
          <a-select  v-model="data.settings.user_auth">
            <a-select-option :value="true" >Да</a-select-option>
            <a-select-option :value="false">Нет</a-select-option>
            <a-select-option :value="null">Не важно</a-select-option>
          </a-select>
        </a-form-item>
      </a-col>
    </a-row>

    <a-row type="flex"  :gutter="24">
      <a-col :span="8" :md="8">
        <a-form-item label="Влияние среднего чека">
          <a-select  v-model="data.settings.influence_avg_cost">
            <a-select-option :value="true" >Да</a-select-option>
            <a-select-option :value="false">Нет</a-select-option>
          </a-select>
        </a-form-item>
      </a-col>
      <a-col :span="8" :md="8">
        <a-form-item label="Средний чек от">
          <a-input v-model="data.settings.min_avg_cost"/>
        </a-form-item>
      </a-col>
      <a-col :span="8" :md="8">
        <a-form-item label="Средний чек до">
          <a-input v-model="data.settings.max_avg_cost"/>
        </a-form-item>
      </a-col>
    </a-row>

<!--    <a-row type="flex"  :gutter="24">-->
<!--      <a-col :span="8" :md="8">-->
<!--        <a-form-item label="Влияние частоты заказов">-->
<!--          <a-select  v-model="data.settings.influence_order_frequency">-->
<!--            <a-select-option :value="true" >Да</a-select-option>-->
<!--            <a-select-option :value="false">Нет</a-select-option>-->
<!--          </a-select>-->
<!--        </a-form-item>-->
<!--      </a-col>-->
<!--      <a-col :span="8" :md="8">-->
<!--        <a-form-item label="Частота заказов (месяц) от">-->
<!--          <a-input v-model="data.settings.min_order_frequency"/>-->
<!--        </a-form-item>-->
<!--      </a-col>-->
<!--      <a-col :span="8" :md="8">-->
<!--        <a-form-item label="Частота заказов (месяц) до">-->
<!--          <a-input v-model="data.settings.max_order_frequency"/>-->
<!--        </a-form-item>-->
<!--      </a-col>-->
<!--    </a-row>-->


    <a-form-item label="Функция атрибуции">
      <a-select v-model="data.settings.attribution_feature">
        <a-select-option value="lastOrder">Оформление заказа</a-select-option>
        <a-select-option value="firstVisit">Первый запуск</a-select-option>
        <a-select-option value="visit">Открытие мобильного приложение</a-select-option>
      </a-select>
    </a-form-item>


    <a-form-item label="Отображать клиентам">
      <a-select  v-model="data.visible">
        <a-select-option :value="true" >Да</a-select-option>
        <a-select-option :value="false">Нет</a-select-option>
      </a-select>
    </a-form-item>
    <a-form-item label="Функция обнуления push">
      <a-select v-model="data.reset_function">
        <a-select-option value="allActivity" >Любая активность</a-select-option>
        <a-select-option value="newOrder" >Оформлен заказ</a-select-option>
        <a-select-option value="visitApp" >Посещение мобильного приложения</a-select-option>
        <a-select-option :value="null">Нет</a-select-option>
      </a-select>
    </a-form-item>


    <a-form-model-item class="mt-15">
      <a-row type="flex" :gutter="24" class="buttons_container">
        <a-col :span="24" :lg="this.$route.params.id !== undefined ? 12 : 24" :md="24">
          <a-button type="primary" @click="onSubmit">
            {{this.$route.params.id === undefined ? this.TextLocalization.Add[this.language] : this.TextLocalization.Edit[this.language]}}
          </a-button>
        </a-col>
        <a-col :span="24" :lg="12" :md="24" v-if="this.$route.params.id !== undefined">
          <a-button type="danger" @click="showModalDelete">
            {{TextLocalization.Delete[language]}}
          </a-button>
        </a-col>
      </a-row>
    </a-form-model-item>
  </div>
</template>

<script>
  import MarketingAPI from "../../../../api/MarketingAPI";

  export default {
    data(){
      return {
        data: {
          template_class_name: null,
          activator_text: null,
          reset_function: null,
          visible: false,
          settings: {
            user_auth: null,
            influence_orders: false,
            min_orders_count: null,
            max_orders_count: null,
            influence_avg_cost: false,
            min_avg_cost: null,
            max_avg_cost: null,
            attribution_feature: undefined,
            influence_order_frequency: false,
            min_order_frequency: null,
            max_order_frequency: null
          }
        }
      }
    },
    methods: {
      onSubmit: function(){
        console.log(this.data);
        switch(this.$route.name){
          case 'MarketingTemplatesAutomaticPushAdd':
            this.createData();
            break;
          case 'MarketingTemplatesAutomaticPushEdit':
            this.updateData();
            break;
        }
      },
      createData: function (){
        MarketingAPI.add_auto_push_template(this.data)
            .then(response => {

            })
            .catch((e) => {
              console.log(e);
            })
      },
      updateData: function (){
        MarketingAPI.edit_auto_push_template(this.data)
            .then(response => {

            })
            .catch((e) => {
              console.log(e);
            })
      },
      showModalDelete: function(){

      },
      getData: function (id) {
        MarketingAPI.get_auto_push_template(id)
            .then(response => {
              response.data.status === 401 ? this.$store.commit('User/EXIT_USER') : null;
              this.data = response.data.data;
            })
            .catch((e) => {
              console.log(e);
            });
      }
    },
    created(){
      switch(this.$route.name){
        case 'MarketingTemplatesAutomaticPushAdd':
          break;
        case 'MarketingTemplatesAutomaticPushEdit':
          this.getData(this.$route.params.id);
          break;
      }
    }
  }
</script>

<style scoped>

</style>