<template>
  <div>
    <div class="info" v-for="(branch, branchKey) in this.branches" @click="goTo(branch.id)">
      <b>{{ TextLocalization.Address[language] }}:</b> {{branch.address}}<br>
    </div>
    <div class="create" @click="goTo('add')">
      {{ TextLocalization.AddBranch[language] }}
    </div>
  </div>
</template>

<script>
import Config from "@/config";
import ApplicationApi from "../../../../api/ApplicationApi";

export default {
  data() {
    return {
      editor: null,
      branches: [],
    };
  },
  methods: {
    goTo: function (pageName){
      this.$router.push(this.$route.path+'/'+pageName);
    },
    get_partner_data(partner_id){
      ApplicationApi.get_data(partner_id)
          .then(response => {
            response.data.status === 401 ? this.$store.commit('User/EXIT_USER') : null;
            switch (response.data.status){
              case 200:
                this.form = response.data.data;

                this.users = response.data.users;
                this.branches = response.data.branch;
                this.contacts = response.data.contacts;

                this.form.work_model = (this.form.work_model).toString();
                this.form.order_transfer = (this.form.order_transfer).toString();
                this.form.balance = this.formatter(response.data.data.balance);
                break;
              default:
                this.data = {
                  name: '',
                  business_name: '',
                  business_id: '',
                  iban: '',
                  bank: '',
                  business_address:'',
                  post_address: '',
                  token: '',
                  balance: '',
                  order_transfer: 0
                };
                this.$router.push('/applications/');
                break;
            }
          })
          .catch((e) => {
            console.log(e);
          })
    },

  },
  created() {
    let AuthInfo = (this.$store.getters["User/getUserInfo"]);
    this.editor = AuthInfo.type;

    let id = undefined;
    switch(this.editor){
      case 0:
        id = this.$route.params.id;
        break;
      case 1:
        id = AuthInfo.partner_id;
        break;
    }
    if (id !== undefined){
      this.get_partner_data(id);
    }
  }
}
</script>

<style scoped lang="scss">
.create, .info{
  background-color: #fafafa;
  border: 1px dashed #d9d9d9;
  font-size: 80%;
  border-radius: 4px;
  cursor: pointer;
  width: 100%;
  padding: 15px 10px;
  font-weight: bold;
  transition: border-color 0.3s ease;
  margin-top: 15px;

}
.info{
  &:hover{
    background-color: rgba(24, 144, 255, 0.15);
    border: 1px dashed #1890FF;
  }
}
.create{
  text-align: center;
  &:hover{
    background-color: rgba(24, 144, 255, 0.15);
    color: #1890FF;
    border: 1px dashed #1890FF;
    text-decoration: underline;
  }
}
.info{
  color: #000;
  line-height: 1.5;
  font-weight: initial;
}

</style>
