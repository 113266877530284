<template>
  <div>
    <h6>Код виджета:</h6>
      <code>
      {{ JS }}
      </code>

    <a-form-model :model="form">
<!--      <a-form-model-item>-->
<!--        <a-checkbox v-model="form.widget__encode1251" @change="editSettings">-->
<!--          Использовать кодировку Windows-1251-->
<!--        </a-checkbox>-->
<!--      </a-form-model-item>-->
      <a-form-model-item>
        <a-checkbox v-model="form.widget__right_position" @change="editSettings">
          Отображать справа
        </a-checkbox>
      </a-form-model-item>
<!--      <a-form-model-item>-->
<!--        <a-checkbox v-model="form.widget__hide_on_mobile" @change="editSettings">-->
<!--          Скрывать баннер в мобильной версии-->
<!--        </a-checkbox>-->
<!--      </a-form-model-item>-->
      <a-form-model-item :label="this.TextLocalization.Header[this.language]">
        <a-input v-model="form.widget__title"  @focusout="editSettings" placeholder="Скачай мобильное приложение GastroSoft!"/>
      </a-form-model-item>
      <a-form-model-item :label="this.TextLocalization.Description[this.language]">
        <a-input v-model="form.widget__description" @focusout="editSettings" placeholder="Получай промо-коды, скидки, специальные акции первым!"/>
      </a-form-model-item>
    </a-form-model>
  </div>
</template>

<script>
import FormPartnerID from '@/mixins/form-partnerID';
import ApplicationApi from "../../../../api/ApplicationApi";

export default {
  mixins: [FormPartnerID],
  data() {
    return {
      editor: null,
      form: {
        partner_id: null,
        widget__title: "",
        widget__description: "",
        // widget__encode1251: false,
        widget__right_position: false,
        // widget__hide_on_mobile: false
      },
    }
  },
  watch:{
    form: {
      handler(val) {
        let pos;
        val.widget__right_position === true ? pos = 'right' : pos = '';
        this.JS = "<script>(function(a,b,c,d,e,f,g,h){a[f]={id:c,title:d,placement:h,text:g};let el=b.createElement(\"script\"),today=new Date;el.async=1;el.src=e+\"?v=\"+today.getFullYear()+\"-\"+today.getMonth()+\"-\"+today.getDate();b.head.appendChild(el)})(window,document,\""+val.partner_id+"\",\""+val.widget__title+"\",\"https://app.gastrosoft.by/widget/smart.js?v2\",\"fpsmart\",\""+val.widget__description+"\",\""+pos+"\")<\/script>"
      },
      deep: true,
      immediate: true,
    }
  },
  created() {
    this.getSettings();
  },
  methods: {
    getSettings: async function () {
      this.form = await this.getData();
    },
    getData: async function () {
      let settings = (await ApplicationApi.get_settings(this.form.partner_id)).data.data;

      return {
        id: settings.id,
        partner_id: this.form.partner_id,
        widget__title: settings.widget__title,
        widget__description: settings.widget__description,
        // widget__encode1251: settings.widget__encode1251,
        widget__right_position: settings.widget__right_position,
        // widget__hide_on_mobile: settings.widget__hide_on_mobile,
      };
    },
    editSettings: async function(){
      let data = await this.getData();
      let formData = {
        id: data.id,
        partner_id: this.form.partner_id,
        widget__title: this.form.widget__title,
        widget__description: this.form.widget__description,
        // widget__encode1251: this.form.widget__encode1251,
        widget__right_position: this.form.widget__right_position,
        // widget__hide_on_mobile: this.form.widget__hide_on_mobile,
      };


        await ApplicationApi.edit_settings(formData);
        this.$root.$emit('createAlertGood');
    }
  }
}
</script>

<style scoped >

code {
  background-color: #eee;
  border: 1px solid #999;
  display: block;
  /*padding: 20px;*/
  overflow-x: auto;
  padding: 25px;
  /*background: #F0F0F0;*/
  color: #000;
  margin: 15px 0 15px 0;
  border-radius: 5px;
}
</style>