<template>
  <Users></Users>
</template>

<script>
import Users from '@/components/Users'
export default {
  components: {
    Users
  }
}
</script>

<style scoped>

</style>