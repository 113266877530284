<template>
  <API/>
</template>

<script>
import API from '../../../components/Settings/SettingsAPI';
export default {
  name: "SettingsAPI",
  components: {
    API
  }
}
</script>

<style scoped>

</style>