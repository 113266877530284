import BaseApi from "./BaseApi";

class DishApi extends BaseApi {

    get(id) {
        return this.axios.get('/Menu/category/dish/'+id);
    }

    edit(data) {
        delete data.token;
        return this.axios.post('/Menu/category/dish/edit', data);
    }

    add(data) {
        return this.axios.post('/Menu/category/dish/add', data);
    }

    delete(data) {
        return this.axios.post('/Menu/category/dish/delete', data);
    }
}

export default new DishApi();
