<template>
  <a-row type="flex" :gutter="24">
    <a-col :span="8">
      <a-form-model-item :label="TextLocalization.ReportStartDate[language]">
        <a-input type="date" v-model="form.startDate" ref="startDate" @keyup.enter="enterClick"/>
      </a-form-model-item>
    </a-col>
    <a-col :span="8">
      <a-form-model-item :label="TextLocalization.ReportEndDate[language]">
        <a-input type="date" v-model="form.finishDate" ref="finishDate" @keyup.enter="enterClick"/>
      </a-form-model-item>
    </a-col>
    <a-col :span="8">
      <a-form-model-item label="">
        <a-button type="primary" @click="onSubmit" class="button_generate_report"  @keyup.enter="enterClick">
          {{ TextLocalization.GenerateAReport[language] }}
        </a-button>
      </a-form-model-item>

    </a-col>
    <a-col :span="24" class="content_wrapper" v-if="content.length > 0">
      <table>
        <tr>
          <th>№</th>
          <th>{{ TextLocalization.Date[language] }}</th>
          <th>{{ TextLocalization.Payer[language] }}</th>
          <th>{{ TextLocalization.Amount[language] }}</th>
          <th>{{ TextLocalization.Description[language] }}</th>
          <th></th>
        </tr>
        <tr v-for="(data, key) in content">
          <td>{{data.id}}</td>
          <td>{{data.registration_time | formatDate}}</td>
          <td>{{data.business_name}} - {{data.name}}</td>
          <td>{{formatter(data.amount)}}</td>
          <td>{{data.description}}</td>
          <td class="rubbish_container" @click="deletePayment({id: data.id, partner_id: data.partner_id})">
            <svg viewBox="-47 1 511 511.999" xmlns="http://www.w3.org/2000/svg"><path d="m209.140625 186c-8.28125 0-15 6.714844-15 15v235c0 8.285156 6.71875 15 15 15 8.285156 0 15-6.714844 15-15v-235c0-8.285156-6.714844-15-15-15zm0 0"/><path d="m279.78125 186.015625c-8.257812-.386719-15.273438 6.070313-15.625 14.347656l-10 235c-.355469 8.277344 6.070312 15.269531 14.347656 15.625.21875.007813.433594.011719.648438.011719 7.992187 0 14.632812-6.300781 14.976562-14.359375l10-235c.351563-8.28125-6.074218-15.273437-14.347656-15.625zm0 0"/><path d="m138.503906 186.015625c-8.277344.351563-14.699218 7.347656-14.347656 15.621094l10 235c.34375 8.0625 6.984375 14.363281 14.972656 14.363281.214844 0 .433594-.003906.652344-.015625 8.273438-.351563 14.699219-7.347656 14.347656-15.621094l-10-235c-.351562-8.277343-7.328125-14.726562-15.625-14.347656zm0 0"/><path d="m402.417969 185.136719h-34.164063l1.726563-29.398438h12.054687c8.28125 0 15-6.714843 15-15v-32.867187c0-24.8125-20.1875-45-45-45h-49.828125v-7.871094c0-30.328125-24.675781-55-55-55h-76.128906c-30.328125 0-55 24.671875-55 55v7.867188h-49.828125c-24.8125 0-45 20.1875-45 45v32.871093c0 8.285157 6.714844 15 15 15h12.050781l1.730469 29.398438h-34.53125c-8.285156 0-15 6.714843-15 15 0 8.28125 6.714844 15 15 15h36.296875l16.632813 282.742187c.464843 7.929688 7.03125 14.121094 14.972656 14.121094h251.480468c7.941407 0 14.507813-6.191406 14.972657-14.121094l16.632812-282.742187h35.929688c8.285156 0 15-6.71875 15-15 0-8.285157-6.714844-15-15-15zm-256.339844-130.136719c0-13.785156 11.214844-25 25-25h76.125c13.785156 0 25 11.214844 25 25v7.867188h-126.125zm-94.828125 52.867188c0-8.269532 6.730469-15 15-15h285.78125c8.273438 0 15 6.730468 15 15v17.871093h-315.78125zm269.488281 374.132812h-223.191406l-19.191406-326.261719h261.574219zm0 0"/></svg>
          </td>
        </tr>
      </table>
    </a-col>
  </a-row>

</template>

<script>
import FinanceApi from "../../../api/FinanceAPI";

export default {
  data(){
    return {
      form: {
        startDate: null,
        finishDate: null
      },
      content: [],
    }
  },
  mounted() {
    this.$refs.startDate.focus();
  },
  methods: {
    enterClick: function (){
      let focusChanged = false;
      if(this.form.startDate === null && !focusChanged){
        this.$refs.startDate.focus();
      }

      if(this.form.finishDate === null && !focusChanged){
        this.$refs.finishDate.focus();
      }

      this.onSubmit();
    },
    onSubmit: function (){
      this.getPayments(this.form);
    },
    deletePayment: function (data){
      FinanceApi.deletePayment(data)
          .then(response => {
            response.data.status === 401 ? this.$store.commit('User/EXIT_USER') : null;
            switch (response.data.status){
              case 200:
                this.onSubmit();
                break;
              default:
                break;
            }
          })
          .catch((e) => {
            console.log(e);
          });
    },
    getPayments: function (data){
      FinanceApi.registerReceivedPayments(data)
          .then(response => {
            response.data.status === 401 ? this.$store.commit('User/EXIT_USER') : null;
            switch (response.data.status){
              case 200:
                this.content = response.data.data;
                break;
              default:
                this.partners = [];
                break;
            }
          })
          .catch((e) => {
            console.log(e);
          });
    }
  }
}
</script>

<style scoped lang="scss">
.button_generate_report{
  width: 100%;
  margin-top: 26px;
}

table{
  width: 100%;


  & th {
    font-size: 14px;
    font-weight: bold;
    color: #000;
    border-right: 1px solid #eee;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    position: relative;
    text-align: center;
    user-select: none;
    background: #ddd;
    padding: 10px 0;
  }
}
tr{
  &:hover{
    & td{
      background: #fff5ee;
    }
  }

  & td{
    border: 1px solid #eee;
    font-size: 14px;
    padding: 10px;
    &.budget_error{
      color: red;
      font-weight: bold;
    }
    &.bold{
      font-weight: bold;
    }
  }
}
.content_wrapper{
  margin-top: 20px;
}
.rubbish_container{
  width: 20px;
  padding: 5px 25px;
  cursor: pointer;
  & svg{
    height: 17px;
    width: 17px;
    margin: auto;
  }
}
</style>