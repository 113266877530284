<template>
  <div>
    <div class="wrapper">
      <div  @click="form.cash_payment_method = !form.cash_payment_method; editSettings();">
        <h6>{{ TextLocalization.CashPayment[language] }}</h6>
        <p>{{ TextLocalization.CashPaymentDescribeText[language] }}</p>
      </div>
      <div>
        <a-switch default-checked v-model="form.cash_payment_method" @change="editSettings"/>
      </div>
    </div>

    <div class="wrapper">
      <div  @click="form.card_payment_method = !form.card_payment_method; editSettings()">
        <h6>{{ TextLocalization.CardPayment[language] }}</h6>
        <p>{{ TextLocalization.CardPaymentDescribeText[language] }}</p>
      </div>
      <div>
        <a-switch default-checked v-model="form.card_payment_method" @change="editSettings"/>
      </div>
    </div>
  </div>
</template>

<script>
import ApplicationApi from "../../../api/ApplicationApi";
import FormPartnerID from '@/mixins/form-partnerID';

export default {
  mixins: [FormPartnerID],
  data(){
    return {
      form: {
        id: null,
        partner_id: null,
        cash_payment_method: undefined,
        card_payment_method: undefined
      }
    }
  },
  created() {
    this.getSettings();
  },
  methods: {
    getSettings: async function () {
      let settings = (await ApplicationApi.get_settings(this.form.partner_id)).data.data;

      this.form = {
        id: settings.id,
        partner_id: this.form.partner_id,
        card_payment_method: settings.card_payment_method,
        cash_payment_method: settings.cash_payment_method
      };
    },
    editSettings: async function(){
      await ApplicationApi.edit_settings(this.form);
      this.$root.$emit('createAlertGood');
    }
  }
}
</script>

<style scoped lang="scss">
.wrapper{
  background-color: #fafafa;
  border: 1px dashed #d9d9d9;
  padding: 15px 10px;
  margin: 10px 0 ;
  cursor: pointer;
  display: flex;

  & > div{
    flex: auto;
    &:nth-child(2){
      flex: none;
    }
  }
  & textarea{
    margin-bottom: 10px;
    padding: 10px;
    &.textBanner{
      margin-top: 10px;
      margin-bottom: 0;
      width: 100%;
    }
  }

  & h6{
    margin-bottom: 10px;
  }
}
</style>