<template>
  <div>
    <a-row type="flex" :gutter="24">
      <a-col :span="24" :md="24">
        <a-form-model-item label="Промокод">
          <a-input v-model="form.code" />
        </a-form-model-item>


        <a-form-model-item label="Единица измерения скидки?">
          <a-select v-model="form.promo_type">
            <a-select-option :value="0" >%</a-select-option>
            <a-select-option :value="1">руб.</a-select-option>
          </a-select>
        </a-form-model-item>

        <a-form-model-item label="Размер скидки">
          <a-input v-model="form.promo_content" />
        </a-form-model-item>


        <a-form-model-item class="mt-15">
          <a-row type="flex" :gutter="24" class="buttons_container">
            <a-col :span="24" :lg="this.$route.params.id !== undefined ? 12 : 24" :md="24">
              <a-button type="primary" @click="onSubmit">
                {{this.$route.params.id === undefined ? this.TextLocalization.Add[this.language] : this.TextLocalization.Edit[this.language]}}
              </a-button>
            </a-col>
            <a-col :span="24" :lg="12" :md="24" v-if="this.$route.params.id !== undefined">
              <a-button type="danger" @click="showModalDelete">
                {{TextLocalization.Delete[language]}}
              </a-button>
            </a-col>
          </a-row>
        </a-form-model-item>
      </a-col>

      <a-modal
          :title="TextLocalization.AreYouSure[language]"
          :visible="visible"
          :confirm-loading="confirmLoading"
          @ok="handleOk"
          @cancel="handleCancel"
      >
        {{ TextLocalization.AreYouSureYouWantToDelete[language] }}
      </a-modal>
    </a-row>
  </div>
</template>

<script>
import PromocodeAPI from "../../../../../api/PromocodeAPI";

export default {
  name: "promocode-add-edit",
  data(){
    return {
      visible: false,
      confirmLoading: false,
      form: {
        id: null,
        code: null,
        promo_type: 0,
        promo_content: null
      }
    }
  },
  methods: {
    onSubmit: function (){
      if (this.$route.params.id === undefined){
        this.add();
      } else {
        this.edit();
      }
    },
    get(id){
      PromocodeAPI.get(id)
          .then(response => {
            switch (response.data.status){
              case 200:
                this.form = response.data.data;
                break;
              default:
                this.data = {};
                break;
            }
          })
          .catch((e) => {
            console.log(e);
          })
    },
    add(){
      PromocodeAPI.add(this.form)
          .then(response => {
            switch (response.data.status){
              case 200:
                this.form = response.data.data;
                this.$router.push('/marketing/promocode/'+response.data.data.id);
                break;
              default:
                this.data = {};
                break;
            }
            this.$root.$emit("createAlertGood");
          })
          .catch((e) => {
            console.log(e);
          })
    },
    edit(){
      PromocodeAPI.edit(this.form)
          .then(response => {
            switch (response.data.status){
              case 200:
                this.form = response.data.data;
                break;
              default:
                this.data = {};
                break;
            }
            this.$root.$emit("createAlertGood");
          })
          .catch((e) => {
            console.log(e);
          })
    },
    delete(data){
      PromocodeAPI.edit({
        id: this.form.id,
        is_deleted: true
      })
          .then(response => {
            this.$router.push('/marketing/promocode');
          })
          .catch((e) => {
            console.log(e);
          })
    },
    showModalDelete() {
      this.visible = true;
    },
    handleOk(e) {
      this.confirmLoading = true;
      this.delete({id: this.form.id});
      this.visible = false;
      this.confirmLoading = false;
    },
    handleCancel(e) {
      this.visible = false;
    },

  },
  mounted() {
    this.get(this.$route.params.id);
  }
}
</script>

<style scoped>

</style>