<template>
  <div>
    <a-row type="flex" :gutter="24" class="buttons_container mb-25">
      <a-col :span="24" :lg="24" :md="24">
        <a-button type="primary" @click="addTemplate">
          Добавить шаблон аудитории
        </a-button>
      </a-col>
    </a-row>
    <table>
      <tr>
        <th style="min-width: 200px ">Название</th>
        <th>Мин. зак.</th>
        <th>Макс. зак.</th>
        <th>Мин. ср. чек.</th>
        <th>Макс. ср. чек.</th>
        <th>Мин. посл. зак.</th>
        <th>Макс. посл. зак.</th>
      </tr>
      <tr v-for="(segment, sKey) in segments" :key="sKey" @click="openSegment(segment.id)">
        <td>{{ segment.name }}</td>
        <td>{{ segment.min_orders }}</td>
        <td>{{ segment.max_orders }}</td>
        <td>{{ segment.min_avg_cost }}</td>
        <td>{{ segment.max_avg_cost }}</td>
        <td>{{ segment.min_last_order_days }}</td>
        <td>{{ segment.max_last_order_days }}</td>
      </tr>
    </table>
  </div>
</template>

<script>
import MarketingAPI from "../../../../../api/MarketingAPI";
import config from '@/config'

export default {
  data(){
    return {
      segments: [],
    }
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData: function (){
      MarketingAPI.get_default_segments()
          .then(response => {
            response.data.status === 401 ? this.$store.commit('User/EXIT_USER') : null;
            this.segments = response.data.data;
          })
          .catch((e) => {
            console.log(e);
          })
    },
    addTemplate(){
      this.$router.push('/marketing/templates-segments/add');
    },
    openSegment: function (id){
      this.$router.push('/marketing/templates-segments/'+id);
    }
  }
}
</script>

<style scoped lang="scss">
table{
  width: 100%;
  & th {
      font-size: 14px;
      font-weight: bold;
      color: #000;
      border-right: 1px solid #eee;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      position: relative;
      text-align: center;
      user-select: none;
      background: #ddd;
      padding: 10px 0;
    }

  tr{
    cursor: pointer;
    &:hover{
      & td{
        background: #fff5ee;
      }
    }
    & td{
      border: 1px solid #eee;
      font-size: 14px;
      padding: 10px;
    }
  }
}
</style>