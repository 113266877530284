<template>
  <table v-if="pushHistory.length > 0" >
    <tr>
      <th>Дата</th>
      <th>Заголовок</th>
      <th>Текст</th>
      <th>Клиенты</th>
    </tr>
    <pushContent v-for="(push, key) in pushHistory" :key="key" :push="push"></pushContent>
  </table>
</template>

<script>
import pushContent from "./push-table-content"
import MarketingAPI from "../../../../api/MarketingAPI";

export default {
  components: {
    pushContent
  },
  props: ['partner_id', 'language', 'pushHistory'],
  methods: {
    getPushHistory: function (){
      MarketingAPI.get_push_history(this.partner_id)
          .then(response => {
            response.status === 401 ? this.$store.commit('User/EXIT_USER') : null;
            this.pushHistory = response.data.data;
          })
          .catch((e) => {
            console.log(e);
          })
    },
  },
  watch: {
    partner_id: {
      handler (newPI, oldPI) {
        this.getPushHistory( newPI );
      },
      immediate: true
    }
  },
  mounted() {
    this.$root.$on('getActualPushHistory', this.getPushHistory);
    this.getPushHistory(  );
  }
}
</script>

<style scoped lang="scss">
table{
  width: 100%;
  & th {
      font-size: 14px;
      font-weight: bold;
      color: #000;
      border-right: 1px solid #eee;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      position: relative;
      text-align: center;
      user-select: none;
      background: #ddd;
      padding: 10px 15px;
    }
}
</style>