<template>
  <DeliveryTerms></DeliveryTerms>
</template>

<script>
import DeliveryTerms from '@/components/Settings/SettingsDeliveryTerms';
export default {
  components: {
    DeliveryTerms
  }
}
</script>

<style scoped>

</style>