import BaseApi from "./BaseApi";

class SizeAPI extends BaseApi {

    get(id) {
        return this.axios.get('/Menu/category/dish/size/'+id);
    }

    edit(data) {
        delete data.token;
        return this.axios.post('/Menu/category/dish/size/edit', data);
    }

    add(data) {
        return this.axios.post('/Menu/category/dish/size/add', data);
    }

    delete(data) {
        return this.axios.post('/Menu/category/dish/size/delete', data);
    }
}

export default new SizeAPI();
