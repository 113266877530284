<template>
  <SettingsWaysOrdering></SettingsWaysOrdering>
</template>

<script>
import SettingsWaysOrdering from '../../../components/Settings/SettingsWaysOrdering';
export default {
  components: {
    SettingsWaysOrdering
  }
}
</script>

<style scoped>

</style>