<template>
  <div class="push_preview">
    <iphone-preview
        :text-body="form.content"
        :text-title="form.header"
        background-color="#05a545"
        appearanceMode="light"
        textApplicationName="GastroSoft"
    >
      <template slot="header">
        <div class="time-container">
          <p>{{ new Date().getHours() }}:{{ new Date().getMinutes() }}</p>
        </div>
      </template>
    </iphone-preview>
  </div>
</template>

<script>

import {
  // AndroidPreview,
  IphonePreview
} from 'vue-push-notification-preview';
import 'vue-push-notification-preview/src/assets/devices/iphone_x.scss';

export default {
  name: "push-preview",
  props: ['form'],
  components: {
    // AndroidPreview,
    IphonePreview
  },
}
</script>

<style lang="scss" scoped>
.time-container {
  height: 65%;
  display: flex;
  justify-content: center;
  align-items: flex-end;

  p {
    font-size: 50px;
    color: #ffffff;
  }
}
.vpnp-wrapper-iphone_x{
  margin: 0 auto;
}
</style>