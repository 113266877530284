<template>
  <div>
    <a-row type="flex" :gutter="12">
      <a-col :span="24" :lg="5" class="mb-24">
        <a-input-group compact style="width: 100%">
          <a-select v-model="filters.branch">
            <a-select-option v-for="(branch, branchKey) in branches" :value="branch.id" :key="branchKey">
              {{branch.name}}
            </a-select-option>

          </a-select>
        </a-input-group>
      </a-col>
      <a-col :span="24" :lg="3" class="mb-24">
        <a-input-group compact style="width: 100%">
          <a-select  v-model="filters.transferType">
            <a-select-option v-for="(transport_method, transportMethodsKey) in transport_methods" :value="transport_method.id" :key="transportMethodsKey">
              {{transport_method.name}}
            </a-select-option>
          </a-select>
        </a-input-group>
      </a-col>
      <a-col :span="24" :lg="4" class="mb-24">
        <template>
          <a-input placeholder="Имя клиента" v-model="filters.customerName"/>
        </template>
      </a-col>

      <a-col :span="24" :lg="4" class="mb-24">
        <template>
          <a-input placeholder="Номер телефона" v-model="filters.customerPhone"/>
        </template>
      </a-col>
      <a-col :span="24" :lg="4" class="mb-24">
        <template>
          <a-input placeholder="Адрес доставки" v-model="filters.deliveryAddress"/>
        </template>
      </a-col>

      <a-col :span="24" :lg="4" class="mb-24">
        <a-input-group compact style="width: 100%">
          <a-select v-model="filters.orderStatus">
            <a-select-option v-for="(status, statusKey) in statuses" :value="status.id" :key="statusKey">
              {{status.name}}
            </a-select-option>
          </a-select>
        </a-input-group>
      </a-col>
    </a-row>
    <div class="orders">
      <table>
        <tr>
          <th>Дата</th>
          <th>Филлиал</th>
          <th>Тип</th>
          <th>Клиент</th>
          <th>Телефон</th>
          <th>Заказ</th>
          <th>Итого</th>
          <th>Статус</th>
        </tr>
        <tbody>
          <order v-for="(order, orderKey) in filteredOrders" :data="order" :key="orderKey"/>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import order from './Orders/order-item';
import OrdersAPI from "../../api/OrdersAPI";
import ApplicationBranchesApi from "../../api/ApplicationBranchesApi";
import partnerIDMixin from '@/mixins/partnerID'

export default {
  mixins: [partnerIDMixin],
  data(){
    return {
      filters: {
        branch: -1,
        transferType: -1,
        customerName: '',
        customerPhone: '',
        deliveryAddress: '',
        orderStatus: 0
      },
      filteredOrders: [],
      orders: [],
      partner_id: null,
      branches: [{id: -1, name:'Все'}],
      payment_methods: [{id: -1, name:'Все'},{id: 1, name: 'Наличные'}, {id: 2, name: 'Картой'}],
      transport_methods: [{id: -1, name:'Все'},{id: 1, name: 'Самовывоз'},{id: 0, name: 'Доставка'}],
      statuses: [{id: -1, name: 'Все'},{id: 0, name: 'Все активные'}, {id: 1, name: 'Новый'}, {id: 10, name: 'Принят'}, {id: 20, name: 'Готов'}, {id: 30, name: 'Получен курьером'}, {id: 40, name: 'Выдан / Доставлен'}, {id: 50, name: 'Отменен', }]

    }
  },
  watch: {
    filters: {
      handler(val){
        this.filterData();
      },
      deep: true
    }
  },
  async mounted() {
    await this.setPartnerBranches()
    await this.getOrders();
    setInterval(async function (){
      await this.getOrders();
    }.bind(this), 30000);
  },
  components: {
    order
  },
  methods: {
    setPartnerBranches: async function (){
      ApplicationBranchesApi.partnerBranches({id: this.partner_id})
          .then(response => {
            response.status === 401 ? this.$store.commit('User/EXIT_USER') : null;
            switch (response.data.status){
              case 200:
                (response.data.data).forEach(function (branch, key){

                  (this.branches).push({
                    id: branch.id,
                    name: branch.address
                  });
                }.bind(this));
                break;

              default:
                this.orders = []
                break;
            }
          })
          .catch((e) => {
            console.log(e);
          })



  },
    filterData: async function(){
      let orders = this.orders;

      if(this.filters.branch !== -1){
        orders = orders.filter(order => order.pickup_point_id === this.filters.branch)
      }

      if(this.filters.transferType !== -1){
        orders = orders.filter(order => order.type === this.filters.transferType)
      }

      if (this.filters.customerPhone){
        orders = orders.filter(order => ((order.customer_phone).toLowerCase()).indexOf((this.filters.customerPhone).toLowerCase()) !== -1);
      }

      if (this.filters.customerName){
        orders = orders.filter(order => ((order.customer_name).toLowerCase()).indexOf((this.filters.customerName).toLowerCase()) !== -1);
      }

      if (this.filters.deliveryAddress){
        orders = orders.filter(order => ((order.delivery_address).toLowerCase()).indexOf((this.filters.deliveryAddress).toLowerCase()) !== -1);
      }

      switch( this.filters.orderStatus){
        case -1:
          break;
        case 0:
          orders = orders.filter(order => (order.status > 0 && order.status < 40))
          break;
        default:
          orders = orders.filter(order => (order.status === this.filters.orderStatus))
            break;
      }

      this.filteredOrders = orders;
    },
    getOrders: async function(){
      OrdersAPI.getAllOrders(this.partner_id)
          .then(response => {
            response.status === 401 ? this.$store.commit('User/EXIT_USER') : null;
            switch (response.data.status){
              case 200:
                this.orders = response.data.data;
                this.filterData();
                break;
              default:
                this.orders = []
                break;
            }
          })
          .catch((e) => {
            console.log(e);
          })
    },
    setCustomerToFilters: function (data){
      this.filters.customerPhone = data.phone;
      this.filters.customerName = data.name;
      this.filterData();
    },
    changeOrderStatus: function (data){
      OrdersAPI.changeOrderStatus({id: data.id, status: data.status})
          .then(response => {
            response.status === 401 ? this.$store.commit('User/EXIT_USER') : null;
            switch (response.data.status){
              case 200:

                (this.orders).forEach(function (val, key){
                  if(val.id === data.id){
                    this.orders[key].status = data.status;
                  }
                }.bind(this));
                this.filterData();
                break;

              default:
                this.orders = []
                break;
            }
          })
          .catch((e) => {
            console.log(e);
          })

    }
  },
  created: function() {
    this.$root.$on('setCustomerToFilters', this.setCustomerToFilters);
    this.$root.$on('changeOrderStatus', this.changeOrderStatus);

  }
}
</script>

<style scoped lang="scss">
table{
  width: 100%;


  & th {
    font-size: 14px;
    font-weight: bold;
    color: #000;
    border-right: 1px solid #eee;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    position: relative;
    text-align: center;
    user-select: none;
    background: #ddd;
    padding: 10px 0;
  }
}
</style>