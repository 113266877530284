<template>
  <yandex-map
      :coords="coords"
      :zoom="11"
      @click="onClick"
      style="height: 750px"
      :controls="['zoomControl']"
  >

  </yandex-map>
</template>

<script>

import { yandexMap, ymapMarker } from 'vue-yandex-maps'

export default {
  data: () => ({
    coords: [
      53.901309,
      27.550772
    ],

    settings:  {
      apiKey: '3ada3000-4fe5-4450-8bd0-9840db3bf539',
      lang: 'ru_RU',
      coordorder: 'latlong',
      version: '2.1'
    }
  }),
  components: { yandexMap, ymapMarker },
  methods: {
    onClick(e) {
      this.coords = e.get('coords');
    },
  }
};
</script>

<style scoped>

</style>