<template>
  <a-row type="flex" :gutter="24">
    <a-col :span="24" :md="24">
      <a-input type="hidden" v-model="form.id"/>
      <a-input type="hidden" v-model="form.dish_id"/>
      <a-form-model-item :label="TextLocalization.Title[language]">
        <a-input v-model="form.name" />
      </a-form-model-item>
      <a-form-model-item :label="TextLocalization.Description[language]">
        <a-textarea rows="4" v-model="form.description"/>
      </a-form-model-item>
      <a-form-model-item :label="TextLocalization.MinimumNumberOfOptionsSelected[language]">
        <a-input v-model="form.min" />
      </a-form-model-item>
      <a-form-model-item :label="TextLocalization.MaximumNumberOfOptionsSelected[language]">
        <a-input v-model="form.max" />
      </a-form-model-item>

      <a-form-model-item>
        <a-row type="flex" :gutter="24" class="buttons_container">
          <a-col :span="24" :lg="this.$route.params.modifierGroupID !== undefined ? 12 : 24" :md="24">
            <a-button type="primary" @click="onSubmit">
              {{this.$route.params.modifierGroupID === undefined ? this.TextLocalization.Add[this.language] : this.TextLocalization.Edit[this.language]}}
            </a-button>
          </a-col>
          <a-col :span="24" :lg="12" :md="24" v-if="this.$route.params.modifierGroupID !== undefined">
            <a-button type="danger" @click="showModalDelete">
              {{TextLocalization.Delete[language]}}
            </a-button>
          </a-col>
        </a-row>

      </a-form-model-item>
    </a-col>
    <a-modal
        :title="TextLocalization.AreYouSure[language]"
        :visible="visible"
        :confirm-loading="confirmLoading"
        @ok="handleOk"
        @cancel="handleCancel"
    >
      {{ TextLocalization.AreYouSureYouWantToDelete[language] }}
    </a-modal>
  </a-row>
</template>
<script>
import ModifiersGroupApi from "../../../api/ModifiersGroupApi";
import partnerIDMixin from "@/mixins/partnerID";

export default {
  mixins: [partnerIDMixin],
  data() {
    return {
      visible: false,
      confirmLoading: false,
      form: {
        id: null,
        dish_id: null,
        name: '',
        description: '',
        min: null,
        max: null
      },
    };
  },
  methods: {
    onSubmit() {
      if (this.$route.params.modifierGroupID === undefined){
        this.add(this.form);
      } else {
        this.edit(this.form);
      }
    },
    get_data(category_id){
      ModifiersGroupApi.get(category_id)
          .then(response => {
            response.status === 401 ? this.$store.commit('User/EXIT_USER') : null;
            switch (response.data.status){
              case 200:
                this.form = response.data.data;
                break;
              default:
                this.data = {};
                break;
            }
          })
          .catch((e) => {
            console.log(e);
          })
    },
    edit(data){
      ModifiersGroupApi.edit(data)
          .then(response => {
            response.status === 401 ? this.$store.commit('User/EXIT_USER') : null;
            switch (response.data.status){
              case 200:
                this.form = response.data.data;
                this.$root.$emit("createAlertGood");
                break;
              default:
                this.data = {};
                this.$root.$emit("createAlertError");
                break;
            }
          })
          .catch((e) => {
            console.log(e);
          })
    },
    delete(data){
      ModifiersGroupApi.delete(data)
          .then(response => {
            response.status === 401 ? this.$store.commit('User/EXIT_USER') : null;
            switch (response.data.status){
              case 200:
                switch(this.$store.getters['BackButton/goBack']){
                  case -1:
                    this.$store.dispatch('BackButton/setGoBack', -3);
                    break;
                  case -2:
                    this.$store.dispatch('BackButton/setGoBack', -4);
                    break;
                }
                switch(this.$route.meta.layout){
                  case 'administrator':
                    this.$router.push('/applications/'+this.partner_id+'/category/'+this.$route.params.catID+'/dish/'+this.$route.params.dishID);
                    break;
                  case 'manager':
                    this.$router.push('/menu/category/'+this.$route.params.catID+'/dish/'+this.$route.params.dishID);
                    break;
                }
                break;
              default:
                this.data = {};
                break;
            }
          })
          .catch((e) => {
            console.log(e);
          })
    },
    showModalDelete() {
      this.visible = true;
    },
    handleOk(e) {
      this.confirmLoading = true;
      this.delete(this.form);
      this.visible = false;
      this.confirmLoading = false;
    },
    handleCancel(e) {
      this.visible = false;
    },
    add(data){
      ModifiersGroupApi.add(data)
          .then(response => {
            response.status === 401 ? this.$store.commit('User/EXIT_USER') : null;
            switch (response.data.status){
              case 200:
                this.form = response.data.data;
                this.$store.dispatch('BackButton/setGoBack', -2);
                // TODO:
                switch(this.$route.meta.layout){
                  case 'administrator':
                    this.$router.push('/applications/'+this.partner_id+'/category/'+this.$route.params.catID+'/dish/'+this.$route.params.dishID+'/modifier-group/'+response.data.data.id);

                    break;
                  case 'manager':
                    this.$router.push('/menu/category/'+this.$route.params.catID+'/dish/'+this.$route.params.dishID+'/modifier-group/'+response.data.data.id);
                    break;
                }
                this.$root.$emit("createAlertGood");
                break;
              default:
                this.data = {};
                this.$root.$emit("createAlertError");
                break;
            }
          })
          .catch((e) => {
            console.log(e);
          })
    },
    goTo: function (link){
      this.$router.push(link);
    }
  },
  created() {
    this.form.id = this.$route.params.modifierGroupID
    this.form.dish_id = this.$route.params.dishID;

    if (this.$route.params.modifierGroupID !== undefined){
      this.get_data(this.$route.params.modifierGroupID);
    }
  }
};
</script>

<style>
.ant-form-item-label{
  line-height: inherit!important;
}
.ant-form-item{
  margin-bottom: 0;
}
.buttons_container .ant-btn-primary, .buttons_container .ant-btn-danger{
  width: 100%!important;
  margin: 0!important;
}
</style>

<style scoped>
.create, .info{
  background-color: #fafafa;
  border: 1px dashed #d9d9d9;
  font-size: 80%;
  border-radius: 4px;
  cursor: pointer;
  width: 100%;
  padding: 15px 10px;
  transition: border-color 0.3s ease;
  margin-top: 10px!important;
}
.create{
  text-align: center;
  width: 100%;
  margin-left: 0!important;
}
.info{
  color: #000;
  line-height: 1.5;
  font-weight: initial;
}
</style>
