<template>
  <MarketingPush></MarketingPush>
</template>

<script>
import MarketingPush from "@/components/Marketing/push/marketing-push"

export default {
  components: {
    MarketingPush
  }
}
</script>