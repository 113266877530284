<template>
  <div>
      <div v-for="(error, errorKey) in errors" class="error__container">
        {{error}}
      </div>
      <a-form-model-item label="Получатели">
        <a-select  v-model="form.recipients">
          <a-select-option value="all" >Все клиенты</a-select-option>
          <a-select-option v-for="(dSegment, key) in segments.defaultSegments" :key="key" :value="'default-'+dSegment.id" >{{dSegment.name}}</a-select-option>
          <a-select-option v-for="(dSegment, key) in segments.segments" :key="key" :value="'custom-'+dSegment.id" >{{dSegment.name}}</a-select-option>
        </a-select>
      </a-form-model-item>
      <div class="wrapper">
        <a-form-model-item label="Заголовок">
          <a-input class="regular-input" placeholder="Заголовок" v-model="form.header"/>
        </a-form-model-item>
      </div>
    <a-form-model-item label="Текст">
      <a-textarea placeholder="Текст"  v-model="form.content"></a-textarea>
    </a-form-model-item>


    <a-button  class="mt-15" type="primary" @click="addPush">Отправить Push-уведомление</a-button>
  </div>
</template>

<script>
import Config from "@/config";
import MarketingAPI from "../../../../api/MarketingAPI";

export default {
  name: "PushForm",
  props: ['partner_id', 'language', 'form', 'segments'],
  data(){
    return {
      errors: [],
      editor: null,
      TextLocalization: Config.TextLocalization,
    }
  },

  methods: {
    addPush: function (){

      let data = this.form;
      data.partner_id = this.partner_id;

      this.errors = [];

      if (!data.header) {
        this.errors.push('Требуется указать заголовок.');
      }
      if (!data.content) {
        this.errors.push('Требуется указать текст уведомления.');
      }

      if(this.errors.length === 0){
        MarketingAPI.add_push(data)
            .then(response => {
              response.status === 401 ? this.$store.commit('User/EXIT_USER') : null;
              this.$root.$emit('getActualPushHistory');
              this.form.header = '';
              this.form.content = '';
            })
            .catch((e) => {
              console.log(e);
            })
      }
    }
  }
}
</script>

<style scoped lang="scss">

  h6{
    //margin-bottom: 5px;
  }
  .ant-select{
    margin-top: 10px;
  }

  input,
  textarea,
  .ant-select{
    //margin-bottom: 15px;
  }
  textarea{
    height: 100px;
  }
  button{
    width: 100%;
    text-transform: uppercase;
  }
div {
  & .error__container {
    box-sizing: border-box;
    margin: 10px 0;
    color: rgba(0, 0, 0, 0.65);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5;
    list-style: none;
    font-feature-settings: 'tnum';
    position: relative;
    padding: 8px 15px 8px 37px;
    word-wrap: break-word;
    border-radius: 4px;
    background-color: #fff1f0;
    border: 1px solid #ffa39e;

    &:last-child {
      margin-bottom: 20px;
    }
  }
}
  .wrapper {
    position: relative;
    width: 100%;
    display: inline-block;
  }
  .regular-input {
    width: 100%;
  }

  .regular-input:focus {
    box-shadow: 0 0 0 3px rgba(66,153,225,.5);
  }

</style>