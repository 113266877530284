import BaseApi from "./BaseApi";

class PromocodeAPI extends BaseApi {

    get(id) {
        return this.axios.get('/marketing/promocode/'+id);
    }

    edit(data) {
        return this.axios.post('/marketing/promocode/edit', data);
    }

    add(data) {
        return this.axios.post('/marketing/promocode/add', data);
    }
}

export default new PromocodeAPI();
