import axios from 'axios';
import Cookie from 'js-cookie';

export default class BaseApi {
  constructor () {
    let Cookies = Cookie.get();

    this.axios = axios.create({
      baseURL: 'https://backend.gastrosoft.by/',
      headers: {'Authorization' : `Bearer ${Cookies.token}`}
    });
  }

  refreshData(){
    window.location.reload();
  }
}
