<template>
  <tr @click="openDishInfo = !openDishInfo">
    <td>{{data.registration_time | formatDate}}</td>
    <td>{{data.branch_address}}</td>
    <td>{{ data.type === 1 ? 'Самовывоз' : 'Доставка' }}</td>
    <td class="customer" @click="openCustomer(data.customer_phone,data.customer_name )">{{data.customer_name}}</td>
    <td>{{data.customer_phone}}</td>
    <td class="delivery_address" v-if="openDishInfo === false">{{data.delivery_address}}</td>
    <td class="dishInfo" v-else>
      <span>

        <span v-if="data.type === 1">{{data.branch_address}}<br></span>
        <span v-if="data.type !== 1">{{data.delivery_address}}<br></span>
        <span v-if="data.type !== 1">---<br></span>
        <span v-if="data.type !== 1">Подьезд: {{data.entrance}}<br></span>
        <span v-if="data.type !== 1">Этаж: {{data.flor}}<br></span>
        <span v-if="data.type !== 1">Квартира / Помещение: {{data.office}}<br></span>
        ---<br>
        <div v-for="(dish, dishKey) in data.order_content" style="margin-top: 10px">
          {{dishKey+1}}. {{dish.name}} ({{dish.sizeName}})<br>
          {{formatter(dish.price)}} × {{dish.quantity}} шт.<br>
          <div v-for="(option, optionKey) in dish.options" style="margin-left: 15px;margin-bottom: 5px">
            {{dishKey+1}}.{{optionKey+1}}. {{option.name}}<br>
            {{formatter(option.price)}} × {{option.quantity}} шт.
          </div>
        </div>
        ---<br>
        <span v-if="data.comment">Комментарий: {{ data.comment }}<br>---<br></span>

        Форма оплаты: {{data.payment | paymentMethodFormatter }} <br>
        Сумма заказа: {{ formatter(data.product_cost) }} <br>
        <span v-if="data.delivery_fee > 0">
          Стоимость доставки: {{data.delivery_fee}} <br>
          Итого: {{ formatter(data.amount) }} <br>
        </span>
      </span>



    </td>
    <td class="summ">{{formatter(data.amount)}}</td>
    <td class="status finished_success">
      <a-dropdown>
        <a class="ant-dropdown-link" @click="e => e.preventDefault()">
          {{ data.status | orderStatusName }}
        </a>
        <a-menu slot="overlay">
          <a-menu-item v-for="(status, statusKey) in pickupStatuses" v-if="data.type === 0" :key="statusKey">
            <a @click="changeStatus(status.id);">{{ status.name }}</a>
          </a-menu-item>

          <a-menu-item v-for="(status, statusKey) in deliveryStatuses" v-if="data.type === 1" :key="statusKey">
            <a @click="changeStatus(status.id);">{{ status.name }}</a>
          </a-menu-item>

        </a-menu>
      </a-dropdown>
    </td>
  </tr>
</template>

<script>
export default {
  name: "order-item",
  props: ['data'],
  data() {
    return {
      openDishInfo: false,
      pickupStatuses: [{id: 1, name: 'Новый'},{id: 10, name: 'Принят'},{id: 20, name: 'Готов'},{id: 40, name: 'Выдан клиенту'},{id: 50, name: 'Отменен'}],
      deliveryStatuses: [{id: 1, name: 'Новый'},{id: 10, name: 'Принят'},{id: 20, name: 'Готов'},{id: 30, name: 'Передан курьеру'},{id: 40, name: 'Выдан клиенту'},{id: 50, name: 'Отменен'}],
    }
  },
  methods: {
    changeStatus: function (id){
      this.$root.$emit('changeOrderStatus', {id: this.data.id, status: id})
    },
    openCustomer: function (phone, name){
      this.$root.$emit('setCustomerToFilters', {phone:phone, name: name})
    },
  }
}
</script>

<style scoped lang="scss">
tr{
  cursor: pointer;

  &:hover{
    & td{
      background: #fff5ee;
    }
  }

  & td{
    border: 1px solid #eee;
    font-size: 14px;
    padding: 10px;
    &.customer{
      cursor: pointer;
      color: #3EA1EC;
      &:hover{
        text-decoration: underline;
      }
    }
    &.delivery_address{
      cursor: pointer;
    }
    &.summ{
      text-align: center;
    }
    &.status{
      text-align: center;
      cursor: pointer;
      position: relative;
      &.finished_success{
        color: #05a545;
      }

      &:hover{
        text-decoration: underline;
        &:after{
          content: "";
          width: 15px;
          height: 15px;
          background: url(data:image/svg+xml,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22UTF-8%22%3F%3E%0A%3Csvg%20width%3D%228px%22%20height%3D%224px%22%20viewBox%3D%220%200%208%204%22%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%3E%0A%20%20%20%20%3C%21--%20Generator%3A%20Sketch%2048.2%20%2847327%29%20-%20http%3A%2F%2Fwww.bohemiancoding.com%2Fsketch%20--%3E%0A%20%20%20%20%3Ctitle%3ECombined%20Shape%20Copy%3C%2Ftitle%3E%0A%20%20%20%20%3Cdesc%3ECreated%20with%20Sketch.%3C%2Fdesc%3E%0A%20%20%20%20%3Cdefs%3E%3C%2Fdefs%3E%0A%20%20%20%20%3Cg%20id%3D%22iPhone-SE-Copy-16%22%20stroke%3D%22none%22%20stroke-width%3D%221%22%20fill%3D%22none%22%20fill-rule%3D%22evenodd%22%20transform%3D%22translate%28-263.000000%2C%20-172.000000%29%22%3E%0A%20%20%20%20%20%20%20%20%3Cpath%20d%3D%22M266.999787%2C174.550279%20L269.55056%2C172%20L270.257598%2C172.707175%20L267.00042%2C175.963723%20L266.999719%2C175.963023%20L266.999018%2C175.963723%20L263.742402%2C172.707107%20L264.449509%2C172%20L266.999787%2C174.550279%20Z%22%20id%3D%22Combined-Shape-Copy%22%20fill%3D%22%23000000%22%20fill-rule%3D%22nonzero%22%3E%3C%2Fpath%3E%0A%20%20%20%20%3C%2Fg%3E%0A%3C%2Fsvg%3E) no-repeat 50% / 8px;
          position: absolute;
          margin: auto 0;
          top: 9px;
          right: 5px;
          opacity: .5;
          transition: opacity 200ms;
        }
      }
    }
  }
}
.dishInfo{
  line-height: 1.5;
  padding: 10px;
  font-size: 90%;
  width: 25%;
}
.delivery_address{
  width: 25%;
}
</style>