<template>
  <a-row type="flex" :gutter="24">
    <a-col :span="24" :md="24">
      <a-input type="hidden" v-model="form.id"/>
      <a-input type="hidden" v-model="form.partner_id"/>
      <a-form-model-item :label="TextLocalization.Title[language]">
        <a-input v-model="form.title" />
      </a-form-model-item>
      <a-form-model-item :label="TextLocalization.Description[language]">
        <a-textarea rows="4" v-model="form.description"/>
      </a-form-model-item>

      <a-form-model-item label="Отображать акцию?">
        <a-select v-model="form.show_in_app">
          <a-select-option :value="true" >Да</a-select-option>
          <a-select-option :value="false">Нет</a-select-option>
        </a-select>
      </a-form-model-item>

      <a-form-model-item :label="TextLocalization.Image[language]" v-if="this.$route.params.specialOfferID !== undefined">
        <input type="file" id="file" ref="file" v-on:change="handleFileUpload()"/>
        <p>Рекомендуемый размер картинки ширина: 582px, высота: 360px</p>
<!--        <br>-->
<!--        <img :src="form.image" alt="" style="max-height: 200px; max-width: 200px">-->
      </a-form-model-item>

      <a-form-model-item class="mt-15">
        <a-row type="flex" :gutter="24" class="buttons_container">
          <a-col :span="24" :lg="this.$route.params.specialOfferID !== undefined ? 12 : 24" :md="24">
            <a-button type="primary" @click="onSubmit">
              {{this.$route.params.specialOfferID === undefined ? this.TextLocalization.Add[this.language] : this.TextLocalization.Edit[this.language]}}
            </a-button>
          </a-col>
          <a-col :span="24" :lg="12" :md="24" v-if="this.$route.params.specialOfferID !== undefined">
            <a-button type="danger" @click="showModalDelete">
              {{TextLocalization.Delete[language]}}
            </a-button>
          </a-col>
        </a-row>
      </a-form-model-item>
    </a-col>
<!--    <a-col :span="8" :md="8">-->
<!--&lt;!&ndash;      <div class="preview" >&ndash;&gt;-->
<!--&lt;!&ndash;        <img :src="form.image" alt="">&ndash;&gt;-->
<!--&lt;!&ndash;        <h2>{{form.title}}</h2>&ndash;&gt;-->
<!--&lt;!&ndash;        <p>{{form.description}}</p>&ndash;&gt;-->
<!--&lt;!&ndash;      </div>&ndash;&gt;-->
<!--    </a-col>-->
    <a-modal
        :title="TextLocalization.AreYouSure[language]"
        :visible="visible"
        :confirm-loading="confirmLoading"
        @ok="handleOk"
        @cancel="handleCancel"
    >
      {{ TextLocalization.AreYouSureYouWantToDelete[language] }}
    </a-modal>
  </a-row>
</template>

<script>
import axios from "axios";
import ApplicationSpecialOffersApi from "../../../../api/ApplicationSpecialOffersApi";
import FormPartnerID from '@/mixins/form-partnerID';

export default {
  mixins: [FormPartnerID],
  data() {
    return {
      file: null,
      visible: false,
      confirmLoading: false,
      form: {
        show_in_app: false,
        title: '',
        description: '',
        image: '',
        partner_id: null
      }
    }
  },
  methods: {
    onSubmit() {
      if (this.$route.params.specialOfferID === undefined){
        this.add(this.form);
      } else {
        this.edit(this.form);
      }
    },
    get_data(id){
      ApplicationSpecialOffersApi.get(id)
          .then(response => {
            response.data.status === 401 ? this.$store.commit('User/EXIT_USER') : null;
            switch (response.data.status){
              case 200:
                this.form = response.data.data;
                break;
              default:
                this.data = {};
                break;
            }
          })
          .catch((e) => {
            console.log(e);
          })
    },
    async edit(data){
      if(this.file) {
        let formData = new FormData();
        let img_src = null;
        formData.append('myFile', this.file);
        await axios.put('//178.172.136.145:3000/upload/',
            formData,
            {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            }).then(function (result) {
          img_src = result.data.data;
        }).catch(function (e) {
          console.log(e);
        });

        data.image = 'https://backend.gastrosoft.by//img/' + img_src;
      }
      ApplicationSpecialOffersApi.edit(data)
          .then(response => {
            response.data.status === 401 ? this.$store.commit('User/EXIT_USER') : null;
            this.form = response.data;
            this.$root.$emit("createAlertGood");
          })
          .catch((e) => {
            console.log(e);
          })
    },
    async add(data){
      if(this.file) {
        let formData = new FormData();
        let img_src = null;
        formData.append('myFile', this.file);
        await axios.put('https://backend.gastrosoft.by/upload/',
            formData,
            {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            }).then(function (result) {
          img_src = result.data.data;
        }).catch(function (e) {
          console.log(e);
        });

        data.image = 'https://backend.gastrosoft.by/img/' + img_src;
      }

      ApplicationSpecialOffersApi.add(data)
          .then(response => {
            response.data.status === 401 ? this.$store.commit('User/EXIT_USER') : null;
            switch (response.data.status){
              case 200:
                this.form = response.data.data;
                this.$store.dispatch('BackButton/setGoBack', -2);
                switch(this.$route.meta.layout){
                  case 'administrator':
                    this.$router.push('/applications/'+this.partner_id+'/banners/'+response.data.data.id);
                    break;
                  case 'manager':
                    this.$router.push('/marketing/special-offers/');
                    break;
                }
                break;
              default:
                this.data = {};
                break;
            }
          })
          .catch((e) => {
            console.log(e);
          })
    },
    goTo(link){
      this.$router.push(link);
    },
    delete(data){
      ApplicationSpecialOffersApi.delete(data)
          .then(response => {
            response.data.status === 401 ? this.$store.commit('User/EXIT_USER') : null;
            switch (response.data.status){
              case 200:
                switch(this.$store.getters['BackButton/goBack']){
                  case -1:
                    this.$store.dispatch('BackButton/setGoBack', -3);
                    break;
                  case -2:
                    this.$store.dispatch('BackButton/setGoBack', -4);
                    break;
                }

                switch(this.$route.meta.layout){
                  case 'administrator':
                    this.goTo('/applications/'+this.partner_id+'/banners/');
                    break;
                  case 'manager':
                    this.goTo('/marketing/special-offers/')
                    break;
                }

                break;
              default:
                this.data = {};
                break;
            }
          })
          .catch((e) => {
            console.log(e);
          })
    },
    showModalDelete() {
      this.visible = true;
    },
    handleOk(e) {
      this.confirmLoading = true;
      this.delete({id: this.form.id});
      this.visible = false;
      this.confirmLoading = false;
    },
    handleCancel(e) {
      this.visible = false;
    },
    handleFileUpload(){
      this.file = this.$refs.file.files[0];
    }
  },
  mounted(){
    if (this.$route.params.specialOfferID !== undefined){
      this.get_data(this.$route.params.specialOfferID);
    }
  },
  created() {
    this.form.id = this.$route.params.specialOfferID
  }
}
</script>

<style scoped lang="scss">
.preview{
  position: relative;
  width: 375px;
  padding: 19px 16px;
  background: #fff;
  border-radius: 28px 28px 0 0;
  box-shadow: 0px 2px 15px rgb(0 0 0 / 15%);
  margin: 0 auto;
  & img{
    width: 100%;
  }
  & h2{
    font-family: 'SF UI Text';
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 21px;
    margin-top: 10px;
  }
  & p{
    font-family: 'SF UI Text';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    margin-top: 10px;
  }
}
</style>