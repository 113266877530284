<template>
  <div>
    <wrapper_navigation
        element-header="Публичный договор"
        description=""
        @click.native="openPage('/legal/public-contract')"
    />

    <wrapper_navigation
        element-header='Действующие тарифы ООО "ГастроСофт"'
        description=""
        @click.native="openPage('/legal/tariffs')"
    />

    <wrapper_navigation
        element-header="Политика конфиденциальности"
        description=""
        @click.native="openPage('/legal/privacy-policy')"
    />

  </div>
</template>

<script>
import wrapper_navigation from '@/components/elements/wrapper_navigation';

export default {
  components: {
    wrapper_navigation
  },
  methods: {
    openPage(page){
      this.$router.push(page);
    }
  }
}
</script>

<style scoped>

</style>