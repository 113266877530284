<template>
  <div style="color: #000;line-height: 1.5; text-align: justify;">
    Настоящая Политика конфиденциальности персональных данных (далее - Политика конфиденциальности) действует в отношении всей информации, которую мобильное приложение, расположенное на платформах Google Рlау и Арр Stoге, может получить о Пользователе во время использования мобильного приложения.
    <br>
    <br><b>1 . ОПРЕДЕЛЕНИЕ ТЕРМИНОВ</b>
    <br>
    <br>1.1 В настоящей Политике конфиденциальности используются следующие термины:
    <br>1.1.1. «Администрация мобильного приложения» - уполномоченные сотрудники на управление мобильным приложением, действующие от имени заведения, которые организуют и (или) осуществляет обработку персональных данных, а также определяет цели обработки персональных данных, состав персональных данных, подлежащих обработке, действия (операции), совершаемые с персональными данными.
    <br>1.1.2. «Персональные данные» - любая информация, относящаяся к прямо или косвенно определенному или определяемому физическому лицу (субъекту персональных данных).
    <br>1.1.3. «Обработка персональных данных» - любое действие (операция) или совокупность действий (операций), совершаемых с использованием средств автоматизации или без использования таких средств с персональными данными, включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (распространение, предоставление, доступ), обезличивание, блокирование, удаление, уничтожение персональных данных.
    <br>1.1.4. «Конфиденциальность персональных данных» - обязательное для соблюдения Оператором или иным получившим доступ к персональным данным лицом требование не допускать их распространения без согласия субъекта персональных данных или наличия иного законного основания.
    <br>1.1.5. «Пользователь мобильного приложения (далее - Пользователь)» -лицо, имеющее доступ к мобильному приложению посредством сети Интернет и использующее приложение.
    <br>1.1.6. «Сооkies» небольшой фрагмент данных, отправленный веб-сервером и хранимый на компьютере пользователя, который веб-клиент или веб-браузер каждый раз пересылает веб-серверу в НТТР-запросе при  попытке открыть страницу соответствующего сайта.
    <br>1.1.7. «IP-адрес» уникальный сетевой адрес узла в компьютерной сети, построенной по протоколу IP.
    <br>
    <br><b>2. ОБЩИЕ ПОЛОЖЕНИЯ</b>
    <br>
    <br>2.1. Использование Пользователем мобильного приложения означает согласие с настоящей Политикой конфиденциальности и условиями обработки персональных данных Пользователя.
    <br>2.2. В случае несогласия с условиями Политики конфиденциальности Пользователь должен прекратить использование мобильного приложения.
    <br>2.3. Настоящая Политика конфиденциальности применяется к мобильному приложению.
    <br>2.4. Администрация мобильного приложения не проверяет достоверность персональных данных, предоставляемых Пользователем приложения.
    <br>
    <br> <b>З. ПРЕДМЕТ ПОЛИТИКИ КОНФИДЕНЦИАЛЬНОСТИ</b>
    <br>
    <br> 3.1. Настоящая Политика конфиденциальности устанавливает обязательства Администрации мобильного приложения по неразглашению и обеспечению режима защиты конфиденциальности персональных данных, которые Пользователь предоставляет по запросу Администрации мобильного приложения при регистрации в приложении или при оформлении заказа для приобретения Товара.
    <br>3.2. Персональные данные, разрешённые к обработке в рамках настоящей Политики конфиденциальности, предоставляются Пользователем путём заполнения регистрационной формы в мобильном приложении включают в себя следующую информацию:
    <br>3.2.1. фамилию, имя Пользователя;
    <br>3.2.2. контактный телефон Пользователя;
    <br>3.2.3. адрес доставки Товара;
    <br>3.3. Мобильное приложение осуществляет сбор статистики об IР-адресах своих посетителей. Данная информация используется с целью выявления и решения технических проблем, для контроля законности проводимых финансовых платежей.
    <br>3.4. Любая иная персональная информация не оговоренная выше (история заказов и т.д.) подлежит надежному хранению и нераспространению, за исключением случаев, предусмотренных в п.п. 5.2. и 5.3. настоящей Политики конфиденциальности.
    <br>
    <br> <b>4. ЦЕЛИ СБОРА ПЕРСОНАЛЬНОЙ ИНФОРМАЦИИ ПОЛЬЗОВАТЕЛЯ</b>
    <br>
    <br> 4.1. Персональные данные Пользователя Администрация мобильного приложения может использовать в целях:
    <br> 4.1.1. Идентификации Пользователя, зарегистрированного в мобильном приложении, для оформления заказа.
    <br> 4.1.2. Установления с Пользователем обратной связи, включая направление уведомлений, запросов, касающихся использования мобильного приложения, оказания услуг, обработка запросов и заявок от Пользователя.
    <br> 4.1.3. Определения места нахождения Пользователя для обеспечения безопасности, предотвращения мошенничества.
    <br> 4.1.4. Подтверждения достоверности и полноты персональных данных, предоставленных Пользователем.
    <br> 4.1.5. Уведомления Пользователя мобильного приложения о состоянии Заказа.
    <br> 4.1.6. Обработки и получения платежей Пользователя.
    <br> 4.1.7. Предоставления Пользователю эффективной клиентской и технической поддержки при возникновении проблем связанных с использованием мобильного приложения.
    <br>4.1.8. Предоставления Пользователю с его согласия, обновлений продукции, специальных предложений, информации о ценах, новостной рассылки и иных сведений от мобильного приложения.
    <br>4.1.9. Осуществления рекламной деятельности с согласия Пользователя.
    <br>
    <br><b>5. СПОСОБЫ И СРОКИ ОБРАБОТКИ ПЕРСОНАЛЬНОЙ ИНФОРМАЦИИ</b>
    <br>
    <br>5.1. Oбработка персональных данных Пользователя осуществляется без ограничения срока, любым законным способом, в том числе в информационных системах персональных данных с использованием средств автоматизации или без использования таких средств.
    <br>5.2. Пользователь соглашается с тем, что Администрация мобильного приложения вправе передавать персональные данные третьим лицам, в частности, курьерским службам, организациями почтовой связи, операторам электросвязи, исключительно в целях выполнения заказа Пользователя, оформленного в мобильном приложении, включая доставку Товара.
    <br>5.3. Персональные данные Пользователя могут быть переданы уполномоченным органам государственной власти РБ только по основаниям и в порядке, установленным законодательством РБ.
    <br>5.4. При утрате или разглашении персональных данных Администрация мобильного приложения информирует Пользователя об утрате или разглашении персональных данных.
    <br>5.5. Администрация мобильного приложения принимает необходимые организационные и технические меры для защиты персональной информации Пользователя от неправомерного или случайного доступа, уничтожения, изменения, блокирования, копирования, распространения, а также от иных неправомерных действий третьих лиц.
    <br> 5.6. Администрация мобильного приложения совместно с Пользователем принимает все необходимые меры по предотвращению убытков или иных отрицательных последствий, вызванных утратой или разглашением персональных данных Пользователя.
    <br>
    <br><b>6. ОБЯЗАТЕЛЬСТВА СТОРОН</b>
    <br>
    <br>6.1. Пользователь обязан:
    <br>6.1.1. Предоставить информацию о персональных данных, необходимую для пользования мобильным приложением.
    <br>6.1.2. Обновить, дополнить предоставленную информацию о персональных данных в случае изменения данной информации.
    <br>6.2. Администрация мобильного приложения обязана:
    <br>6.2.1. Использовать полученную информацию исключительно для целей, указанных в п. 4 настоящей Политики конфиденциальности.
    <br>6.2.2. Обеспечить хранение конфиденциальной информации в тайне, не разглашать без предварительного письменного разрешения Пользователя, а также не существлять продажу, обмен, опубликование, либо разглашение иными возможными способами переданных персональных данных Пользователя, за исключением п.п. 5.2. и 5.3. настоящей Политики Конфиденциальности.
    <br>6.2.3. Принимать меры предосторожности для защиты конфиденциальности персональных данных Пользователя согласно порядку, обычно используемого для защиты такого рода информации в существующем деловом обороте.
    <br>6.2.4. Осуществить блокирование персональных данных, относящихся к соответствующему Пользователю, с момента обращения или запроса Пользователя или его законного представителя либо уполномоченного органа по защите прав субъектов персональных данных на период проверки, в случае выявления недостоверных персональных данных или неправомерных действий.
    <br>
    <br><b>7. ОТВЕТСТВЕННОСТЬ СТОРОН</b>
    <br>
    <br>7.1. Администрация мобильного приложения, не исполнившая свои обязательства, несёт ответственность за убытки, понесенные Пользователем в связи с неправомерным использованием персональных данных, в соответствии с законодательством РБ, за исключением случаев, предусмотренных п.п. 5.2., 5.3. и 7.2. настоящей Политики Конфиденциальности.
    <br>7.2. В случае утраты или разглашения Конфиденциальной информации Администрация мобильного приложения не несет ответственность, если данная конфиденциальная информация:
    <br> 7.2.1. Стала публичным достоянием до её утраты или разглашения.
    <br> 7.2.2. Была получена от третьей стороны до момента её получения Администрацией мобильного приложения.
    <br> 7.2.3. Была разглашена с согласия Пользователя.
    <br>
    <br> <b>8. РАЗРЕШЕНИЕ СПОРОВ</b>
    <br>
    <br>  8.1. До обращения в суд с иском по спорам, возникающим из отношений между Пользователем и Администрацией мобильного приложения, обязательным является предъявление претензии (письменного предложения о добровольном урегулировании спора).
    <br>  8.2 Получатель претензии в течение ЗО календарных дней со дня получения претензии, письменно уведомляет заявителя претензии о результатах рассмотрения претензии.
    <br>  8.3. При недостижении соглашения спор будет передан на рассмотрение в судебный орган в соответствии с действующим законодательством РБ.
    <br>  8.4. К настоящей Политике конфиденциальности и отношениям между Пользователем и Администрацией мобильного приложения применяется действующее законодательство РБ.
    <br>
    <br> <b>9. ДОПОЛНИТЕЛЬНЫЕ УСЛОВИЯ</b>
    <br>
    <br>9.1. Администрация мобильного приложения вправе вносить изменения в настоящую Политику конфиденциальности без согласия Пользователя.
    <br>9.2. Новая Политика конфиденциальности вступает в силу с момента ее размещения в мобильном приложении, если иное не предусмотрено новой редакцией Политики конфиденциальности.
    <br> 9.3. Все предложения или вопросы по настоящей Политике конфиденциальности следует сообщать на странице www.gastrosoft.by.

  </div>
</template>

<script>
export default {
  name: "PrivacyPolicy"
}
</script>

<style scoped>

</style>