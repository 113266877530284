<template>
  <div>
    <a-row type="flex" :gutter="12">
      <a-col :span="24" :lg="4" class="mb-24">
        <template>
          <a-input :placeholder="TextLocalization.Surname[language]" v-model="filters.surname"/>
        </template>
      </a-col>

      <a-col :span="24" :lg="4" class="mb-24">
        <template>
          <a-input :placeholder="TextLocalization.Name[language]" v-model="filters.name"/>
        </template>
      </a-col>

      <a-col :span="24" :lg="4" class="mb-24">
        <template>
          <a-input :placeholder="TextLocalization.Lastname[language]" v-model="filters.lastname"/>
        </template>
      </a-col>

      <a-col :span="24" :lg="4" class="mb-24">
        <template>
          <a-input :placeholder="TextLocalization.PhoneNumber[language]" v-model="filters.phone"/>
        </template>
      </a-col>
      <a-col :span="24" :lg="4" class="mb-24">
        <template>
          <a-input placeholder="Email" v-model="filters.email"/>
        </template>
      </a-col>
      <a-col :span="24" :lg="4" class="mb-24">
        <a-button type="primary" style="width: 100%" @click="goTo('add')">
          {{ TextLocalization.AddUser[language] }}
        </a-button>
      </a-col>


    </a-row>
    <table>
      <tr>
        <th>{{ TextLocalization.Surname[language] }}</th>
        <th>{{ TextLocalization.Name[language] }}</th>
        <th>{{ TextLocalization.Lastname[language] }}</th>
        <th>Email</th>
        <th>{{ TextLocalization.Login[language] }}</th>
        <th>{{ TextLocalization.Position[language] }}</th>
        <th></th>
      </tr>
      <tbody>
        <employeeCard v-for="(employee, employeeKey) in filteredEmployees" :data="employee" :key="employeeKey"></employeeCard>
      </tbody>
    </table>
  </div>
</template>

<script>
import employeeCard from '@/components/users/employeeCard';
import UserApi from "../../api/UserApi";

export default {
  name: "Users",
  components: {
    employeeCard
  },
  data(){
    return {
      employees: [],
      filteredEmployees: [],
      filters: {
        name: '',
        surname: '',
        lastname: '',
        phone: '',
        email: '',
      }
    }
  },
  watch: {
    filters: {
      handler(val){
        this.filterData();
      },
      deep: true
    }
  },
  async mounted() {
    let AuthInfo = (this.$store.getters["User/getUserInfo"]);
    this.editor = AuthInfo.type;

    if(this.editor === 1){
      this.partner_id = AuthInfo.partner_id;
    }

    await this.getEmployees();
    await this.filterData();
  },
  methods: {
    getEmployees: async function () {
      switch (this.editor){
        case 0:
          this.employees = (await UserApi.getAllSystemUsers()).data.data;
          break;
        case 1:
          this.employees = (await UserApi.getAllRestaurantUsers(this.partner_id)).data.data;
          break;
      }
    },
    filterData: async function(){
      let employees = this.employees;

      if (this.filters.name){
        employees = employees.filter(employee => ((employee.name).toLowerCase()).indexOf((this.filters.name).toLowerCase()) !== -1);
      }

      if (this.filters.surname){
        employees = employees.filter(employee => ((employee.surname).toLowerCase()).indexOf((this.filters.surname).toLowerCase()) !== -1);
      }

      if (this.filters.lastname){
        employees = employees.filter(employee => ((employee.lastname).toLowerCase()).indexOf((this.filters.lastname).toLowerCase()) !== -1);
      }

      if (this.filters.phone){
        employees = employees.filter(employee => ((employee.phone).toLowerCase()).indexOf((this.filters.phone).toLowerCase()) !== -1);
      }

      if (this.filters.email){
        employees = employees.filter(employee => ((employee.email).toLowerCase()).indexOf((this.filters.email).toLowerCase()) !== -1);
      }

      this.filteredEmployees = employees;
    },
    goTo: function (link){
      switch (this.editor){
        case 0:
          this.$router.push('/users/'+link);
          break;
        case 1:
          this.$router.push('/settings/users/'+link);
          break;
      }
    }
  }
}
</script>

<style scoped lang="scss">
table{
  width: 100%;


  & th {
    font-size: 14px;
    font-weight: bold;
    color: #000;
    border-right: 1px solid #eee;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    position: relative;
    text-align: center;
    user-select: none;
    background: #ddd;
    padding: 10px 0;
  }
}
</style>