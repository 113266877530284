<template>
  <div class="SellingPushBlock" >
    <div class="icon">
      <img src="@/assets/documents.png" alt="">
    </div>
    <div class="text">Скачать PDF материал "10 продающих Push-уведомлений"</div>
    <div class="clear"></div>
  </div>
</template>

<script>
export default {
  name: "marketing-selling-push-notifications",
  methods: {

  }
}
</script>

<style scoped lang="scss">
.SellingPushBlock{
  margin: 25px 0;
  display: block;
  cursor: pointer;
  & .icon{
    background: #FADB14;
    width: 100px;
    height: 100px;
    border-radius: 50px;
    float: left;
    & img{
      width: 70px;
      height: 70px;
      margin: 15px auto!important;
      display: block;
    }
  }
  & .text{
    margin: 25px 0;
    margin-left: -10px;
    padding: 20px;
    font-weight: bold;
    display: block;
    background: #FADB14;
    float: left;
    width: calc(100% - 90px);
  }
}
.clear{
  clear: both;
}
</style>