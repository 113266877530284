import { render, staticRenderFns } from "./add-edit-sizes.vue?vue&type=template&id=1b7e4ef6&scoped=true&"
import script from "./add-edit-sizes.vue?vue&type=script&lang=js&"
export * from "./add-edit-sizes.vue?vue&type=script&lang=js&"
import style0 from "./add-edit-sizes.vue?vue&type=style&index=0&lang=css&"
import style1 from "./add-edit-sizes.vue?vue&type=style&index=1&id=1b7e4ef6&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1b7e4ef6",
  null
  
)

export default component.exports