<template>
  <div>
    <div class="wrapper" v-if="form.take_delivery_orders">
      <div @click="form.delivery_is_active = !form.delivery_is_active; editSettings()">
        <h6>{{ TextLocalization.DeliveryActive[language]}}</h6>
        <p>{{ TextLocalization.DeliveryActiveDescribeText[language]}}</p>
      </div>
      <div>
        <a-switch default-checked v-model="form.delivery_is_active" @change="editSettings"/>
      </div>
    </div>

    <div class="wrapper">
      <div>
        <div @click="form.show_text_banner = !form.show_text_banner; editSettings()">
          <h6>{{ TextLocalization.TextBanner[language]}}</h6>
          <p>{{ TextLocalization.TextBannerDescribeText[language]}}</p>
        </div>
        <a-textarea v-show="form.show_text_banner" class="textBanner" v-model="form.text_banner_content" @focusout="editSettings"></a-textarea>
      </div>
      <div>
        <a-switch default-checked v-model="form.show_text_banner" @change="editSettings"/>
      </div>
    </div>

    <div class="wrapper">
      <div>
        <h6>{{ TextLocalization.MessageAfterOrder[language]}}</h6>
        <a-textarea placeholder="Вы получите уведомление, когда наш оператор его примет." v-model="form.message_after_order" @focusout="editSettings"></a-textarea>
        <p>{{ TextLocalization.MessageAfterOrderDescribeText[language]}}</p>
      </div>
    </div>

    <div class="wrapper">
      <div>
        <h6>{{ TextLocalization.RestaurantDescription[language]}}</h6>
        <a-textarea placeholder="" v-model="form.about_restaurant_text" @focusout="editSettings"></a-textarea>
        <p>{{ TextLocalization.RestaurantDescriptionDescribeText[language]}}</p>
      </div>
    </div>

    <div class="wrapper">
      <div>
        <h6>{{ TextLocalization.SupportPhoneNumber[language]}}</h6>
        <p>{{ TextLocalization.SupportPhoneNumberDescribeText[language]}}</p>
        <a-input v-model="form.support_phone" @focusout="editSettings" />
      </div>

    </div>

    <div class="wrapper">
      <div class="">
        <h6>Настройка основного цвета приложения</h6>
        <a-row type="flex" :gutter="24">
          <a-col>
            <chrome-picker v-model="form.main_color" @input="editSettings"/>
          </a-col>
          <a-col>
            <div class="preview_container">
              <div class="dish_container">
                <div class="dish_image" style="background-image: url('https://backend.gastrosoft.by/img/1el23qn-1631868246889.png')"></div>
                <div class="dish_content">
                  <span class="header">Сет Все включено мини</span>
                  <span class="text">3500г.</span>
                </div>
                <div class="add_content">
                  <div class="price">110,00 BYN</div>
                  <div class="add_btn">
                    <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <rect x="0.988281" y="0.422363" width="30" height="30" rx="9" :fill="this.form.main_color.hex8"/>
                      <path d="M20.9883 15.4324C20.9883 15.9847 20.5406 16.4344 19.9883 16.4344H16.9883V19.4424C16.9883 19.9947 16.5406 20.4424 15.9883 20.4424V20.4424C15.436 20.4424 14.9883 19.9947 14.9883 19.4424V16.4344H11.9883C11.436 16.4344 10.9883 15.9847 10.9883 15.4324V15.4324C10.9883 14.8801 11.436 14.4304 11.9883 14.4304H14.9883V11.4224C14.9883 10.8701 15.436 10.4224 15.9883 10.4224V10.4224C16.5406 10.4224 16.9883 10.8701 16.9883 11.4224V14.4304H19.9883C20.5406 14.4304 20.9883 14.8801 20.9883 15.4324V15.4324Z" fill="white"/>
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </a-col>
        </a-row>

      </div>
    </div>


    <div class="wrapper">
      <div @click="form.black_theme = !form.black_theme; editSettings()">
        <h6>Черная тема бокового меню мобильного приложения</h6>
        <p>{{ form.black_theme ? 'Установлена черная тема' : 'Установлена белая тема'}}</p>
      </div>
      <div>
        <a-switch default-checked v-model="form.black_theme" @change="editSettings"/>
      </div>
    </div>


  </div>
</template>

<script>
import ApplicationApi from "../../../api/ApplicationApi";
import FormPartnerID from '@/mixins/form-partnerID';
import { Chrome } from 'vue-color'
import {debounce} from "@/helpers";

export default {
  mixins: [FormPartnerID],
  components: {
    'chrome-picker': Chrome ,
  },
  data(){
    return {
      form: {
        id: null,
        partner_id: null,
        delivery_is_active: undefined,
        show_text_banner: undefined,
        text_banner_content: '',
        message_after_order: '',
        about_restaurant_text: '',
        support_phone: '',
        take_delivery_orders: undefined,
        black_theme: undefined,
        main_color: {
          hex8: null
        }
      }
    }
  },
  created() {
    this.getSettings();
  },
  methods: {
    getSettings: async function () {
      let settings = (await ApplicationApi.get_settings(this.form.partner_id)).data.data;

      this.form = {
        id: settings.id,
        partner_id: this.form.partner_id,
        delivery_is_active: settings.delivery_is_active,
        show_text_banner: settings.show_text_banner,
        text_banner_content: settings.text_banner_content,
        message_after_order: settings.message_after_order,
        about_restaurant_text: settings.about_restaurant_text,
        support_phone: settings.support_phone,
        take_delivery_orders: settings.take_delivery_orders,
        main_color: settings.main_color,
        black_theme: settings.black_theme
      };
    },
    edit: async function(){
      await ApplicationApi.edit_settings(this.form);
      this.$root.$emit('createAlertGood');
    },
    editSettings: debounce(function (){
      this.edit();
    }, 750)
  },
}
</script>

<style scoped lang="scss">
.wrapper{
  background-color: #fafafa;
  border: 1px dashed #d9d9d9;
  padding: 15px 10px;
  margin: 10px 0 ;
  cursor: pointer;
  display: flex;

  & > div{
    flex: auto;
    &:nth-child(2){
      flex: none;
    }
  }

  & textarea{
    margin-bottom: 10px;
    padding: 10px;
    &.textBanner{
      margin-top: 10px;
      margin-bottom: 0;
      width: 100%;
    }
  }

  & input{
    margin-bottom: 10px;
    padding: 10px;
    margin-top: 10px;
  }

& h6{
    margin-bottom: 10px;
  }
}

.preview_container{
  width: 100%; height: 100%;padding: 10px;
  & .dish_container{
    width: 164px;
    height: 198px;
    margin: 10px auto;
    display: block;
    background: #FFFFFF;
    border-radius: 14px;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.15);
    position: relative;
    & .dish_image{
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      height: 97px;
      border-radius: 14px 14px 0 0;
    }
    & .dish_content{
      padding: 9px 16px 0 16px;
      & .header{
        font-family: 'SF UI Text';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: #000000;
        max-height: 34px;
        width: 100%;
        display: block;
        overflow: hidden;
      }
      & .text{
        font-family: 'SF UI Text';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 13px;
        margin: 5px 0;
        width: 100%;
        display: block;
      }
    }

    & .add_content{
      bottom: 0;
      padding: 0 16px 10px 16px;
      display: flex;
      position: absolute;
      width: 100%;

      & .price{
        font-family: 'SF UI Text';
        font-style: normal;
        font-weight: 500;
        color: #000000;
        font-size: 13px;
        line-height: 33px;
        flex: auto;
      }

      & .add_btn{
        flex: none;
      }
    }
  }
}

</style>