<template>
  <div>
    <a href="/pdf/public-contract-20-12-2021.pdf">
      Актуальная версия публичного договора от 20 декабря 2021 года
    </a>
  </div>
</template>

<script>
export default {
  name: "PublicContract"
}
</script>

<style scoped>

</style>