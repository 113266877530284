import BaseApi from "./BaseApi";

class ApplicationBranchesApi extends BaseApi {

    get(id) {
        return  this.axios.get('/application/branch/'+id);
    }

    edit(data) {
        delete data.token;
        data.timesheet = {timesheet: data.timesheet};
        return  this.axios.post('/application/branch/edit', data);
    }

    add(data) {
        data.timesheet = {timesheet: data.timesheet};
        return this.axios.post('/application/branch/add', data);
    }

    delete(data) {
        return this.axios.post('/application/branch/delete', data);
    }

    partnerBranches(data){
        return this.axios.post('/application/branch/partner-branches', data);
    }
}

export default new ApplicationBranchesApi();
