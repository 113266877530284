<template>
  <div>
    <a-row type="flex" :gutter="12">
      <a-col :span="24" :lg="24" class="mb-5" >
        <a-form-item label="Название шаблона">
          <a-input placeholder="Название шаблона" v-model="form.name"/>
        </a-form-item>
      </a-col>
      <a-col :span="24" :lg="12" class="mb-5">
        <a-form-item label="Мин. заказов">
          <a-input placeholder="Мин. заказов" v-model="form.min_orders" />
        </a-form-item>
      </a-col>
      <a-col :span="24" :lg="12" class="mb-5">
        <a-form-item label="Макс. заказов">
        <a-input placeholder="Макс. заказов" v-model="form.max_orders" />
        </a-form-item>
      </a-col>

      <a-col :span="24" :lg="12" class="mb-5">
        <a-form-item label="Мин. выручка">
        <a-input placeholder="Мин. выручка" v-model="form.min_proceeds" />
        </a-form-item>
      </a-col>
      <a-col :span="24" :lg="12" class="mb-5">
        <a-form-item label="Макс. выручка">
        <a-input placeholder="Макс. выручка" v-model="form.max_proceeds" />
        </a-form-item>
      </a-col>

      <a-col :span="24" :lg="12" class="mb-5">
        <a-form-item label="Мин. средний чек">
        <a-input placeholder="Мин. средний чек" v-model="form.min_avg_cost"/>
        </a-form-item>
      </a-col>
      <a-col :span="24" :lg="12" class="mb-5">
        <a-form-item label="Макс. средний чек">
        <a-input placeholder="Макс. средний чек" v-model="form.max_avg_cost"/>
        </a-form-item>
      </a-col>

      <a-col :span="24" :lg="12" class="mb-5">
        <a-form-item label="Первый заказ с">
        <a-input type="date" placeholder="Первый заказ с" v-model="form.min_first_order"/>
        </a-form-item>
      </a-col>
      <a-col :span="24" :lg="12" class="mb-5">
        <a-form-item label="Первый заказ по">
        <a-input type="date" placeholder="Первый заказ по" v-model="form.max_first_order"/>
        </a-form-item>
      </a-col>

      <a-col :span="24" :lg="12" class="mb-5">
        <a-form-item label="Последний заказ с">
        <a-input placeholder="Последний заказ с" v-model="form.min_last_order_days" />
        </a-form-item>
      </a-col>
      <a-col :span="24" :lg="12" class="mb-5">
        <a-form-item label="Последний заказ по">
        <a-input placeholder="Последний заказ по" v-model="form.max_last_order_days"/>
        </a-form-item>
      </a-col>

      <a-col :span="24" :lg="12" class="mb-5">
        <a-form-item label="Последние посещение с">
        <a-input placeholder="Последние посещение с" />
        </a-form-item>
      </a-col>
      <a-col :span="24" :lg="12" class="mb-5">
        <a-form-item label="Последний посещение по">
        <a-input placeholder="Последний посещение по" />
        </a-form-item>
      </a-col>

      <a-col :span="24" class="mb-24">
        <a-row type="flex" :gutter="24" class="buttons_container">
          <a-col :span="24" :lg="this.$route.params.id !== undefined ? 12 : 24" :md="24">
            <a-button type="primary" @click="onSubmit">
              {{this.$route.params.id === undefined ? this.TextLocalization.Add[this.language] : this.TextLocalization.Edit[this.language]}}
            </a-button>
          </a-col>
          <a-col :span="24" :lg="12" :md="24" v-if="this.$route.params.id !== undefined">
            <a-button type="danger" @click="showModalDelete">
              {{TextLocalization.Delete[language]}}
            </a-button>
          </a-col>
        </a-row>
      </a-col>

    </a-row>
    <a-modal
        :title="TextLocalization.AreYouSure[language]"
        :visible="visible"
        :confirm-loading="confirmLoading"
        @ok="handleOk"
        @cancel="handleCancel"
    >
      {{ TextLocalization.AreYouSureYouWantToDelete[language] }}
    </a-modal>
  </div>
</template>

<script>
import MarketingAPI from "../../../../../api/MarketingAPI";

let config = require('@/config');
export default {
  data() {
    return {
      visible: false,
      confirmLoading: false,
      form: {
        id: null,
        name: null,
        min_orders: null,
        max_orders: null,
        min_proceeds: null,
        max_proceeds: null,
        min_avg_cost: null,
        max_avg_cost: null,
        min_first_order: null,
        max_first_order: null,
        min_last_order_days: null,
        max_last_order_days: null,
      }
    }
  },
  created() {
    this.user_info = this.$store.getters['User/getUserInfo'];
    switch (this.$route.meta.layoutClass){
      case 'MarketingTemplatesSegmentEdit':
        MarketingAPI.get_default_segment(this.$route.params.id)
            .then(response => {
              response.data.status === 401 ? this.$store.commit('User/EXIT_USER') : null;
              this.form = response.data.data;
            })
            .catch((e) => {
              console.log(e);
            })
        break;
        case 'SegmentEdit':
          MarketingAPI.get_custom_segment(this.$route.params.id, {partner_id: this.user_info.partner_id })
              .then(response => {
                response.data.status === 401 ? this.$store.commit('User/EXIT_USER') : null;
                this.form = response.data.data;
              })
              .catch((e) => {
                console.log(e);
              })
          break;
    }
  },
  methods: {
    onSubmit: function (){
      this.user_info = this.$store.getters['User/getUserInfo'];
      let data;
      switch (this.$route.meta.layoutClass) {
        case 'MarketingTemplatesSegmentEdit':
          data = this.form;
          MarketingAPI.edit_default_segments(data)
              .then(response => {
                response.data.status === 401 ? this.$store.commit('User/EXIT_USER') : null;
                this.form = response.data.data;
                this.$root.$emit("createAlertGood");
              })
              .catch((e) => {
                console.log(e);
              })
          break;
        case 'MarketingTemplatesSegmentAdd':
          data = this.form;
          delete data.id;
          MarketingAPI.add_default_segment(data)
              .then(response => {
                response.data.status === 401 ? this.$store.commit('User/EXIT_USER') : null;
                this.form = response.data.data;
                this.$root.$emit("createAlertGood");
              })
              .catch((e) => {
                console.log(e);
              })
          break;
        case 'SegmentEdit':
          data = this.form;
          data.partner_id = this.user_info.partner_id;

          MarketingAPI.edit_custom_segments(data)
              .then(response => {
                response.data.status === 401 ? this.$store.commit('User/EXIT_USER') : null;
                this.form = response.data.data;
                this.$root.$emit("createAlertGood");
              })
              .catch((e) => {
                console.log(e);
              })
          break;
        case 'SegmentAdd':
          data = this.form;
          data.partner_id = this.user_info.partner_id;

          MarketingAPI.add_custom_segments(data)
              .then(response => {
                response.data.status === 401 ? this.$store.commit('User/EXIT_USER') : null;
                this.form = response.data.data;
                this.$root.$emit("createAlertGood");
              })
              .catch((e) => {
                console.log(e);
              })
          break;
      }
    },
    delete(){
      let data;
      this.user_info = this.$store.getters['User/getUserInfo'];
      switch (this.$route.meta.layoutClass) {
        case 'MarketingTemplatesSegmentEdit':
          data = this.form;
          data.is_deleted = true;

          MarketingAPI.edit_default_segments(data)
              .then(response => {
                response.data.status === 401 ? this.$store.commit('User/EXIT_USER') : null;
                this.form = response.data.data;
                this.$root.$emit("createAlertGood");
                this.$router.push('/marketing/templates-segments');
              })
              .catch((e) => {
                console.log(e);
              })
          break;
        case 'SegmentEdit':
          data = this.form;
          data.partner_id = this.user_info.partner_id;
          data.is_deleted = true;

          MarketingAPI.edit_custom_segments(data)
              .then(response => {
                response.data.status === 401 ? this.$store.commit('User/EXIT_USER') : null;
                this.form = response.data.data;
                this.$root.$emit("createAlertGood");
                this.$router.push('/clients');
              })
              .catch((e) => {
                console.log(e);
              })
          break;
      }
    },
    showModalDelete() {
      this.visible = true;
    },
    handleOk(e) {
      this.confirmLoading = true;
      this.delete();
      this.visible = false;
      this.confirmLoading = false;
    },
    handleCancel(e) {
      this.visible = false;
    },
  }
}
</script>

<style scoped>

</style>