<template>
  <AddPayment></AddPayment>
</template>

<script>
import AddPayment from '@/components/Reports/AddPayment';

export default {
  components: {
    AddPayment
  }
}
</script>

<style scoped>

</style>