<template>
  <AddCompletedWorkAct></AddCompletedWorkAct>
</template>

<script>
import AddCompletedWorkAct from '@/components/Reports/AddCompletedWorkAct';

export default {
  components: {
    AddCompletedWorkAct
  }
}
</script>

<style scoped>

</style>