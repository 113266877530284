<template>
  <tr>
    <td class="CustomerName">{{ data.name }}</td>
    <td>{{ data.phone}}</td>
    <td>{{ data.orders_count }}</td>
    <td>{{ formatter(data.orders_amount) }}</td>
    <td>{{ data.first_order_date | formatDate }}</td>
    <td>{{ data.last_order_date | formatDate }}</td>
    <td>{{
        isNaN((data.orders_count/((new Date().getTime() - new Date(data.first_order_date).getTime())/1000/60/60/24/31)).toFixed(2)) ?
            (0).toFixed(2) : (data.orders_count/((new Date().getTime() - new Date(data.first_order_date).getTime())/1000/60/60/24/31)).toFixed(2)
      }}</td>
  </tr>
</template>

<script>
export default {
  props: ['data', 'index'],
}
</script>

<style scoped lang="scss">
tr{
  cursor: pointer;

  &:hover{
    & td{
      background: #fff5ee;
    }
  }

  & td{
    border: 1px solid #eee;
    font-size: 14px;
    padding: 10px;
    &.CustomerName{
      max-width: 150px;
      white-space: nowrap; /* Запрещаем перенос строк */
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

</style>