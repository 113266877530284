<template>
  <AddEditCategory></AddEditCategory>
</template>
<script>
import AddEditCategory from '../../../../components/Menu/add-edit-category'
export default {
  components: {
    AddEditCategory
  }
}
</script>

