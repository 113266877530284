<template>
  <a-row type="flex" :gutter="24">
    <a-col :span="8">
      <a-form-model-item :label="TextLocalization.ReportStartDate[language]">
        <a-input type="date" v-model="form.startDate" ref="startDate" @keyup.enter="enterClick"/>
      </a-form-model-item>
    </a-col>
    <a-col :span="8">
      <a-form-model-item :label="TextLocalization.ReportEndDate[language]">
        <a-input type="date" v-model="form.finishDate" ref="finishDate" @keyup.enter="enterClick"/>
      </a-form-model-item>
    </a-col>
    <a-col :span="8">
      <a-form-model-item label="">
        <a-button type="primary" @click="onSubmit" class="button_generate_report"  @keyup.enter="enterClick">
          {{ TextLocalization.GenerateAReport[language] }}
        </a-button>
      </a-form-model-item>

    </a-col>
    <a-col :span="24" class="content_wrapper" v-if="content.length > 0">
      <table>
        <tr>
          <th>№</th>
          <th>{{ TextLocalization.Date[language] }}</th>
          <th>{{ TextLocalization.Payer[language] }}</th>
          <th>{{ TextLocalization.Amount[language] }}</th>
          <th>{{ TextLocalization.Description[language] }}</th>
          <th></th>
        </tr>
        <tr v-for="(data, key) in content">
          <td>{{ key + 1 }}</td>
          <td>{{data.create_date | formatDate}}</td>
          <td>{{data.business_name}} ({{data.business_id}}) - {{data.partner_name}}</td>
          <td>{{formatter(data.amount)}}</td>
          <td>{{data.name}}</td>
          <td @click="downloadPDF(data.document)">{{ TextLocalization.Download[language] }}</td>
        </tr>
      </table>
    </a-col>
  </a-row>

</template>

<script>
import FinanceApi from "../../../api/FinanceAPI";

export default {
  data(){
    return {
      form: {
        startDate: null,
        finishDate: null
      },
      content: [],
    }
  },
  methods: {
    enterClick: function (){
      let focusChanged = false;
      if(this.form.startDate === null && !focusChanged){
        this.$refs.startDate.focus();
      }

      if(this.form.finishDate === null && !focusChanged){
        this.$refs.finishDate.focus();
      }

      this.onSubmit();

    },
    onSubmit: function (){
      this.getData(this.form);
    },
    getData: function (data){
      FinanceApi.registerCompletedWorks(data)
          .then(response => {
            response.data.status === 401 ? this.$store.commit('User/EXIT_USER') : null;
            switch (response.data.status){
              case 200:
                this.content = response.data.data;
                break;
              default:
                this.partners = [];
                break;
            }
          })
          .catch((e) => {
            console.log(e);
          });
    },
    downloadPDF: async function (data){
      let pdfMake = require('pdfmake/build/pdfmake.js');
      let pdfFonts = require('pdfmake/build/vfs_fonts.js');
      pdfMake.vfs = pdfFonts.pdfMake.vfs;
      await pdfMake.createPdf(data).open();
    }
  }
}
</script>

<style scoped lang="scss">
.button_generate_report{
  width: 100%;
  margin-top: 26px;
}
table{
  width: 100%;
  & th {
    font-size: 14px;
    font-weight: bold;
    color: #000;
    border-right: 1px solid #eee;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    position: relative;
    text-align: center;
    user-select: none;
    background: #ddd;
    padding: 10px 0;
  }
}
tr{
  &:hover{
    & td{
      background: #fff5ee;
    }
  }

  & td{
    border: 1px solid #eee;
    font-size: 14px;
    padding: 10px;
    &.budget_error{
      color: red;
      font-weight: bold;
    }
    &.bold{
      font-weight: bold;
    }
  }
}
.content_wrapper{
  margin-top: 20px;
}

</style>