export default function(value) {
    let textStatus = '';
    switch (value){
        case 0:
            textStatus = '%'
            break;
        case 1:
            textStatus = 'BYN';
            break;
    }
    return textStatus;
};