<template>
  <div>
    <div class="wrapper_send_push">
      <div style="width:70%;">
        <div style="display: flex" class="mb-24">
          <div style="width: 40%">
            <PushFormComponent :form.sync="form" :language="language" :partner_id="partner_id" :segments="pushSegments"></PushFormComponent>
          </div>
          <div style="width: 60%" class="ml-10">
            <PushRecipientsInfo :form.sync="form"></PushRecipientsInfo>
            <img style="cursor: pointer" @click="downloadPDF" src="@/assets/pushBanner.png" alt="" width="100%" class="mb-15 mt-15">
          </div>
        </div>
        <PushHistoryComponent :language="language" :partner_id="partner_id" :pushHistory="pushHistory"></PushHistoryComponent>
      </div>

      <div style="width: 30%">
        <PushPreview :form.sync="form"></PushPreview>
      </div>
    </div>
  </div>
</template>

<script>
import PushFormComponent from "./push-form"
import PushHistoryComponent from "./push-history";
import PushPreview from "./push-preview";
import PushRecipientsInfo from "./push-recipients-info";
import MarketingSellingPushNotifications from './marketing-selling-push-notifications'
import MarketingAPI from "../../../../api/MarketingAPI";
import PartnerID from "@/mixins/partnerID";

export default {
  mixins: [PartnerID],
  components: {
    PushFormComponent,
    PushHistoryComponent,
    PushPreview,
    PushRecipientsInfo,
    MarketingSellingPushNotifications
  },
  data(){
    return {
      errors: [],
      partner_id: null,
      pushSegments: {
        segments: [],
        defaultSegments: [],
      },
      editor: null,
      form: {
        recipients: 'all',
        header: '',
        content: '',
        all_base: null,
        group_size: null
      },
      pushHistory: []
    }
  },
  watch: {
    'form.recipients': {
      handler(){

        MarketingAPI.get_push_segment_info({
          target: this.form.recipients,
        })
            .then(response => {
              response.data.status === 401 ? this.$store.commit('User/EXIT_USER') : null;
              this.form.all_base = response.data.data.all;
              this.form.group_size = response.data.data.segment;
            })
            .catch((e) => {
              console.log(e);
            })
      },
      immediate: true
    }
  },
  methods: {
    getDefaultSegment: function () {
      MarketingAPI.get_default_segments({partner_id: this.partner_id})
          .then(response => {
            console.log(response.data);
            response.status === 401 ? this.$store.commit('User/EXIT_USER') : null;
            this.pushSegments.defaultSegments = response.data.data;
          })
          .catch((e) => {
            console.log(e);
          })
    },
    getSegment: function (partner_id) {
      MarketingAPI.get_custom_segments({partner_id: partner_id})
          .then(response => {
            response.status === 401 ? this.$store.commit('User/EXIT_USER') : null;
            this.pushSegments.segments = response.data.data;
          })
          .catch((e) => {
            console.log(e);
          })
    },
    downloadPDF: function (){
      window.open('/pdf/marketing_push.pdf', '_blank');
    }
  },
  mounted() {
    this.getSegment(this.partner_id);
    this.getDefaultSegment();
  }
}
</script>

<style scoped lang="scss">
.wrapper_send_push {
  background: #eee;
  display: flex;
  width: 100%;
  padding: 20px;
  margin-bottom: 20px;
  & > * {
    padding: 10px;
  }
}
</style>