import { render, staticRenderFns } from "./RestorePasswordStep1.vue?vue&type=template&id=3835d816&scoped=true&"
import script from "./RestorePasswordStep1.vue?vue&type=script&lang=js&"
export * from "./RestorePasswordStep1.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3835d816",
  null
  
)

export default component.exports