export default function(value) {
    let textStatus = '';
    switch (value){
        case 0:
            textStatus = 'Наличные'
            break;
        case 1:
            textStatus = 'Картой при получении';
            break;
    }
    return textStatus;
};