<template>
  <div>
    <wrapper_navigation
    :ElementHeader="TextLocalization.SpecialOffers[language]"
    Description="Баннеры размещаються в мобильном приложении"
    @click.native="open_page('special-offers')"
    />
    <wrapper_navigation
        :ElementHeader="TextLocalization.PushNotifications[language]"
        Description="Пуш — важнейший инструмент маркетинга. Это прямые сообщения клиентам, которые скачали приложение и разрешили отправлять им такие сообщения."
        @click.native="open_page('push')"
    />
    <wrapper_navigation
        ElementHeader="Автоматизированные Push-уведомления"
        Description="Отправляйте пуш уведомления группам пользователей в автоматическом режиме"
        @click.native="open_page('auto-push')"
    />
<!--    <wrapper_navigation-->
<!--        ElementHeader="SMS-рассылка"-->
<!--        Description="Отправляйте пуш уведомления группам пользователей в автоматическом режиме"-->
<!--        @click.native="open_page('sms')"-->
<!--    />-->
<!--    <wrapper_navigation-->
<!--        ElementHeader="Автоматизированные SMS-уведомления"-->
<!--        Description="Отправляйте пуш уведомления группам пользователей в автоматическом режиме"-->
<!--        @click.native="open_page('auto-sms')"-->
<!--    />-->
    <wrapper_navigation
        ElementHeader="Программа лояльности"
        Description="Мотивируйте клиентов заказывать чаще и приглашать своих друзей!"
        @click.native="open_page('loyalty')"
    />
    <wrapper_navigation
        ElementHeader="Промокоды"
        Description="Мотивируйте клиентов заказывать чаще и приглашать своих друзей!"
        @click.native="open_page('promocode')"
    />
    <wrapper_navigation
        :ElementHeader="TextLocalization.DownloadMobileApplicationWidget[language]"
        Description="Получите код для размещения виджета на вашем сайте"
        @click.native="open_page('widget')"
    />
<!--    <wrapper_navigation-->
<!--        ElementHeader="Бесплатный рекламный набор"-->
<!--        Description="Пакет продвижения внутри заведения"-->
<!--        @click.native="openInHouseMarketing"-->
<!--    />-->
    <wrapper_navigation
        ElementHeader="Статистика"
        Description="Пакет продвижения внутри заведения"
        @click.native="open_page('statistics')"
    />
  </div>
</template>

<script>
import wrapper_navigation from '@/components/elements/wrapper_navigation';

export default {
  components: {
    wrapper_navigation
  },
  methods: {
    open_page: function (pageName){
      this.$router.push(this.$route.path+'/'+pageName);
    },
    openInHouseMarketing(){
      window.open('/pdf/inhouse_marketing.pdf', '_blank');
    }
  },
}
</script>

<style scoped lang="scss">

</style>