<template>
  <a-row type="flex" :gutter="24">
    <a-col :span="24" :md="24">
      <a-input type="hidden" v-model="form.dish_id"/>
      <a-form-model-item :label="TextLocalization.SizeName[language]">
        <a-input v-model="form.name" />
      </a-form-model-item>
      <a-form-model-item :label="TextLocalization.Price[language]">
        <a-input type="numeric" step="0.01" v-model="form.price"/>
      </a-form-model-item>
      <a-form-model-item :label="TextLocalization.OldPrice[language]">
        <a-input type="numeric" step="0.01" v-model="form.old_price"/>
      </a-form-model-item>
      <a-form-model-item :label="TextLocalization.Weight[language]">
        <a-input type="text" v-model="form.weight"/>
      </a-form-model-item>
      <a-form-model-item :label="TextLocalization.Quantity[language]">
        <a-input type="text" v-model="form.quantity"/>
      </a-form-model-item>
      <a-form-model-item>
        <a-row type="flex" :gutter="24" class="buttons_container">
          <a-col :span="24" :lg="this.$route.params.sizeID !== undefined ? 12 : 24" :md="24" >
            <a-button type="primary" @click="onSubmit">
              {{this.$route.params.sizeID === undefined ? this.TextLocalization.Add[this.language] : this.TextLocalization.Edit[this.language]}}
            </a-button>
          </a-col>
          <a-col :span="24" :lg="12" :md="24" v-if="this.$route.params.sizeID !== undefined" >
            <a-button type="danger" @click="showModalDelete">
              {{TextLocalization.Delete[language]}}
            </a-button>
          </a-col>
        </a-row>
      </a-form-model-item>
    </a-col>
    <a-modal
        :title="TextLocalization.AreYouSure[language]"
        :visible="visible"
        :confirm-loading="confirmLoading"
        @ok="handleOk"
        @cancel="handleCancel"
    >
      {{ TextLocalization.AreYouSureYouWantToDelete[language] }}
    </a-modal>
  </a-row>
</template>
<script>
import SizeApi from "../../../api/SizeApi";
import partnerIDMixin from "@/mixins/partnerID";

export default {
  mixins: [partnerIDMixin],
  data() {
    return {
      visible: false,
      confirmLoading: false,
      form: {
        id: null,
        dish_id: null,
        price: null,
        old_price: null,
        name: '',
        weight: '',
        quantity: ''
      },
    };
  },
  methods: {
    onSubmit() {
      if (this.$route.params.sizeID === undefined){
        this.add(this.form);
      } else {
        this.edit(this.form);
      }
    },
    get_data(category_id){
      SizeApi.get(category_id)
          .then(response => {
            response.status === 401 ? this.$store.commit('User/EXIT_USER') : null;
            switch (response.data.status){
              case 200:
                this.form = response.data.data;
                break;
              default:
                this.data = {};
                break;
            }
          })
          .catch((e) => {
            console.log(e);
          })
    },
    edit(data){
      SizeApi.edit(data)
          .then(response => {
            response.status === 401 ? this.$store.commit('User/EXIT_USER') : null;
            switch (response.data.status){
              case 200:
                this.form = response.data.data;
                this.$root.$emit("createAlertGood");
                break;
              default:
                this.data = {};
                this.$root.$emit("createAlertError");
                break;
            }
          })
          .catch((e) => {
            console.log(e);
          })
    },
    add(data){
      SizeApi.add(data)
          .then(response => {
            response.status === 401 ? this.$store.commit('User/EXIT_USER') : null;
            switch (response.data.status){
              case 200:
                this.form = response.data.data;
                this.$store.dispatch('BackButton/setGoBack', -2);
                switch(this.$route.meta.layout){
                  case 'administrator':
                    this.$router.push('/applications/'+this.partner_id+'/category/'+this.menu_category_id+'/dish/'+response.data.data.dish_id+'/size/'+response.data.data.id);
                    break;
                  case 'manager':
                    this.$router.push('/menu/category/'+this.menu_category_id+'/dish/'+response.data.data.dish_id+'/size/'+response.data.data.id);
                    break;
                }
                this.$root.$emit("createAlertGood");
                break;

              default:
                this.data = {};
                this.$root.$emit("createAlertError");
                break;
            }
          })
          .catch((e) => {
            console.log(e);
          })
    },
    delete(data){
      SizeApi.delete(data)
          .then(response => {
            response.status === 401 ? this.$store.commit('User/EXIT_USER') : null;
            switch (response.data.status){
              case 200:
                switch(this.$store.getters['BackButton/goBack']){
                  case -1:
                    this.$store.dispatch('BackButton/setGoBack', -3);
                    break;
                  case -2:
                    this.$store.dispatch('BackButton/setGoBack', -4);
                    break;
                }
                switch(this.$route.meta.layout){
                  case 'administrator':
                    this.$router.push('/applications/'+this.partner_id+'/category/'+this.$route.params.catID+'/dish/'+this.$route.params.dishID);
                    break;
                  case 'manager':
                    this.$router.push('/menu/category/'+this.$route.params.catID+'/dish/'+this.$route.params.dishID);
                    break;
                }
                break;
              default:
                this.data = {};
                break;
            }
          })
          .catch((e) => {
            console.log(e);
          })
    },
    showModalDelete() {
      this.visible = true;
    },
    handleOk(e) {
      this.confirmLoading = true;
      this.delete(this.form);
      this.visible = false;
      this.confirmLoading = false;
    },
    handleCancel(e) {
      this.visible = false;
    },
  },
  created() {
    this.form.id = this.$route.params.sizeID
    this.form.dish_id = this.$route.params.dishID
    this.menu_category_id = this.$route.params.catID;

    if (this.$route.params.sizeID !== undefined){
      this.get_data(this.$route.params.sizeID);
    }
  }
};
</script>

<style>
.ant-form-item-label{
  line-height: inherit!important;
}
.ant-form-item{
  margin-bottom: 0;
}
.buttons_container .ant-btn{
  width: 100%!important;
  margin: 0!important;
}
</style>

<style scoped>
.content_columns{
  margin-top: 25px;
}
.create{
  margin: 4px;
  vertical-align: top;
  background-color: #fafafa;
  border: 1px dashed #d9d9d9;
  border-radius: 4px;
  cursor: pointer;
  transition: border-color 0.3s ease;
  width: 100%;
  padding: 15px;
  font-weight: bold;
  text-align: center;
}
</style>
