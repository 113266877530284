<template>
  <AddPromisedPayment></AddPromisedPayment>
</template>

<script>
import AddPromisedPayment from '@/components/Reports/AddPromisedPayment';

export default {
  components: {
    AddPromisedPayment
  }
}
</script>

<style scoped>

</style>