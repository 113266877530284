<template>
  <tr>
    <td>{{ push.create_date | formatDate }}</td>
    <td>{{ push.push_header }}</td>
    <td>{{ push.push_content }}</td>
    <td>{{ push.number_of_recipients }}</td>
  </tr>
</template>

<script>
export default {
  name: "push-table-content",
  props: [
    'push'
  ]
}
</script>

<style scoped lang="scss">
tr{
  cursor: pointer;

  &:hover{
    & td{
      background: #fff5ee;
    }
  }

  & td {
    border: 1px solid #eee;
    background: #fff;
    font-size: 14px;
    padding: 10px;
  }
}
</style>