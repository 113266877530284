import BaseApi from "./BaseApi";

class ApplicationUsersApi extends BaseApi {

    get(id) {
        return this.axios.get('/application/administrators/'+id);
    }

    edit(data) {
        delete data.token;
        return this.axios.post('/application/administrators/edit', data);
    }

    add(data) {
        return this.axios.post('/application/administrators/add', data);
    }

    delete(data) {
        return this.axios.post('/application/administrators/delete', data);
    }
}

export default new ApplicationUsersApi();
