<template>
  <a-row type="flex" :gutter="24">
    <a-col :span="18">
      <a-input type="hidden" v-model="form.id"/>
      <a-input type="hidden" v-model="form.menu_category_id"/>
      <a-form-model-item :label="TextLocalization.DishName[language]">
        <a-input v-model="form.name" />
      </a-form-model-item>
      <a-form-model-item :label="TextLocalization.Description[language]">
        <a-textarea rows="4" v-model="form.description"/>
      </a-form-model-item>

      <a-form-model-item :label="TextLocalization.Image[language]">
        <input type="file" id="file" ref="file" v-on:change="handleFileUpload()"/>
        <p>Рекомендуемый размер картинки ширина: 328px, высота: 194px</p>
<!--        <br>-->
<!--        <img :src="form.image" alt="" style="max-height: 200px; max-width: 200px">-->
      </a-form-model-item>

      <a-form-model-item>
        <a-row type="flex" :gutter="24" class="buttons_container">
          <a-col :span="24" :lg="this.$route.params.dishID !== undefined ? 12 : 24" :md="24">
            <a-button type="primary" @click="onSubmit">
              {{this.$route.params.dishID === undefined ? this.TextLocalization.Add[this.language] : this.TextLocalization.Edit[this.language]}}
            </a-button>
          </a-col>
          <a-col :span="24" :lg="12" :md="24" v-if="this.$route.params.dishID !== undefined">
            <a-button type="danger" @click="showModalDelete">
              {{TextLocalization.Delete[language]}}
            </a-button>
          </a-col>
        </a-row>


      </a-form-model-item>
    </a-col>
    <a-col :span="6" >
      <div class="preview_container">
        <div class="dish_container">
          <div class="dish_image" :style="{ backgroundImage: 'url(' + form.image + ')' }">

          </div>
          <div class="dish_content">
            <span class="header">{{form.name}}</span>
            <span class="text">{{ ( size.length > 0 ? size[0].weight : ' ') }}</span>
          </div>
          <div class="add_content">
            <div class="price">{{formatter(( size.length > 0 ? size[0].price : 0))}}</div>
            <div class="add_btn">
              <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="0.988281" y="0.422363" width="30" height="30" rx="9" fill="#61CF00"/>
                <path d="M20.9883 15.4324C20.9883 15.9847 20.5406 16.4344 19.9883 16.4344H16.9883V19.4424C16.9883 19.9947 16.5406 20.4424 15.9883 20.4424V20.4424C15.436 20.4424 14.9883 19.9947 14.9883 19.4424V16.4344H11.9883C11.436 16.4344 10.9883 15.9847 10.9883 15.4324V15.4324C10.9883 14.8801 11.436 14.4304 11.9883 14.4304H14.9883V11.4224C14.9883 10.8701 15.436 10.4224 15.9883 10.4224V10.4224C16.5406 10.4224 16.9883 10.8701 16.9883 11.4224V14.4304H19.9883C20.5406 14.4304 20.9883 14.8801 20.9883 15.4324V15.4324Z" fill="white"/>
              </svg>
            </div>
          </div>
        </div>
      </div>
    </a-col>
    <a-col v-if="this.$route.params.dishID !== undefined" :span="24" :lg="12" :md="24" class="content_columns">
      <h6>{{ TextLocalization.DishSizes[language] }}</h6>
      <div class="info" v-for="(size, sizeKey) in this.size" @click="open_size(form.id,size.id)" :key="sizeKey">
        <b>{{ TextLocalization.Title[language] }}:</b> {{size.name}}<br>
        <b>{{ TextLocalization.Price[language] }}:</b> {{formatter(size.price)}}<br>
      </div>
      <div class="create" @click="open_size(form.id,'add')">
        {{ TextLocalization.AddSize[language] }}
      </div>
    </a-col>
    <a-col v-if="this.$route.params.dishID !== undefined" :span="24" :lg="12" :md="24" class="content_columns">
      <h6>{{ TextLocalization.Modifiers[language] }}</h6>

      <draggable v-model="modifiers" draggable=".info" style="width: 100%" class="menu_category_dishes_wrapper">
        <div class="info" v-for="(modifierGroup, modifierGroupKey) in this.modifiers" :key="modifierGroupKey">
          <div @click="open_modifier_group(form.id, modifierGroup.id)" >
            <b>{{ TextLocalization.Title[language] }}:</b> {{modifierGroup.name}}<br>
            <b>{{ TextLocalization.Description[language] }}:</b> {{modifierGroup.description}}<br>
            <b>{{ TextLocalization.Min[language] }}:</b> {{modifierGroup.min}}<br>
            <b>{{ TextLocalization.Max[language] }}:</b> {{modifierGroup.max}}<br><br>
          </div>

          <div>
            <a-row type="flex" :gutter="24">
              <draggable v-model="modifiers[modifierGroupKey].modifiers" draggable=".modifier_info" style="width: 100%" class="menu_category_dishes_wrapper">
                <a-col class="modifier_info" :span="24" :lg="12" :md="24" v-for="(modifier, modifierKey) in modifierGroup.modifiers" @click="open_modifier(form.id, modifierGroup.id, modifier.id)" :key="modifierKey">
                  <div class="info white_background">
                    <b>{{ modifier.name }} </b> - {{ formatter(modifier.price)}}
                  </div>
                </a-col>
              </draggable>
              <a-col :span="24" :lg="12" :md="24" @click="open_modifier(form.id, modifierGroup.id, 'add')">
                <div class="create white_background">
                  {{ TextLocalization.AddModifier[language] }}
                </div>
              </a-col>
            </a-row>
          </div>

        </div>
      </draggable>

      <div class="create" @click="open_modifier_group(form.id, 'add')">
        {{ TextLocalization.AddModifiersGroup[language] }}
      </div>
    </a-col>
    <a-modal
        :title="TextLocalization.AreYouSure[language]"
        :visible="visible"
        :confirm-loading="confirmLoading"
        @ok="handleOk"
        @cancel="handleCancel"
    >
      {{ TextLocalization.AreYouSureYouWantToDelete[language] }}
    </a-modal>
  </a-row>
</template>
<script>
import DishApi from "../../../api/DishApi";
import ModifiersGroupApi from "../../../api/ModifiersGroupApi";
import ModifiersApi from "../../../api/ModifiersApi";

import axios from "axios";
import draggable from 'vuedraggable'

import PartnerID from "@/mixins/partnerID";

export default {
  mixins: [PartnerID],
  components: {
    draggable
  },
  data() {
    return {
      file: null,
      visible: false,
      confirmLoading: false,
      partner_id: null,
      modifiers: null,
      size: [],
      form: {
        id: null,
        image: null,
        menu_category_id: null,
        name: '',
        description: ''
      },
    };
  },
  watch: {
    modifiers: {
      handler (newModifiers, oldModifiers) {
        if (oldModifiers !== null){
          this.modifiers = newModifiers;
          let modGroupIndex = 0;

          (this.modifiers).forEach(async function (modifiersGroup, modifiersGroupKey){

            let modIndex = 0;
            modifiersGroup.modifiers.forEach(async function (modifiers, modifiersKey){
              ModifiersApi.edit({
                id: modifiers.id,
                index: modIndex
              });

              modIndex++;
            });
            ModifiersGroupApi.edit({
              id: modifiersGroup.id,
              index: modGroupIndex
            });

            modGroupIndex++;
          });

          this.$root.$emit("createAlertGood");
        }
      },
      deep: true
    },
  },
  methods: {
    onSubmit() {
      if (this.$route.params.dishID === undefined){
        this.add_category(this.form);
      } else {
        this.edit_category(this.form);
      }
    },
    get_data(category_id){
      DishApi.get(category_id)
          .then(response => {
            response.status === 401 ? this.$store.commit('User/EXIT_USER') : null;
            switch (response.data.status){
              case 200:
                this.form = response.data.data;
                this.size = response.data.size;
                this.modifiers = response.data.modifiers;
                break;
              default:
                this.data = {};
                break;
            }
          })
          .catch((e) => {
            console.log(e);
          })
    },
    async edit_category(data){
      let formData = new FormData();
      let img_src = null;
      if(this.file){
        formData.append('myFile', this.file);
        await axios.put( 'https://backend.gastrosoft.by/upload/',
            formData,
            {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            }).then(function(result){
          img_src = result.data.data;
        }).catch(function(e){
          console.log(e);
        });
        data.image = 'https://backend.gastrosoft.by/img/'+img_src;
      }

      DishApi.edit(data)
          .then(response => {
            response.status === 401 ? this.$store.commit('User/EXIT_USER') : null;
            switch (response.data.status){
              case 200:
                this.form = response.data.data;
                this.$root.$emit("createAlertGood");
                break;
              default:
                this.$root.$emit("createAlertError");
                this.data = {};
                break;
            }
          })
          .catch((e) => {
            console.log(e);
          });
    },
    add_category(data){
      DishApi.add(data)
          .then(response => {
            response.status === 401 ? this.$store.commit('User/EXIT_USER') : null;
            switch (response.data.status){
              case 200:
                this.form = response.data.data;
                this.$store.dispatch('BackButton/setGoBack', -2);
                switch(this.$route.meta.layout){
                  case 'administrator':
                    this.$router.push('/applications/'+this.partner_id+'/category/'+response.data.data.menu_category_id+'/dish/'+response.data.data.id);
                    break;
                  case 'manager':
                    this.$router.push('/menu/category/'+response.data.data.menu_category_id+'/dish/'+response.data.data.id);
                    break;
                }
                this.$root.$emit("createAlertGood");
                break;
              default:
                this.$root.$emit("createAlertError");
                this.data = {};
                break;
            }
          })
          .catch((e) => {
            console.log(e);
          })
    },
    goTo(link){
      this.$router.push(link);
    },
    delete(data){
      DishApi.delete(data)
          .then(response => {
            response.status === 401 ? this.$store.commit('User/EXIT_USER') : null;
            switch (response.data.status){
              case 200:
                switch(this.$store.getters['BackButton/goBack']){
                  case -1:
                    this.$store.dispatch('BackButton/setGoBack', -3);
                    break;
                  case -2:
                    this.$store.dispatch('BackButton/setGoBack', -4);
                    break;
                }
                switch(this.editor){
                  case 0:
                    this.$router.push('/applications/'+this.partner_id+'/menu');
                    break;
                  case 1:
                    this.$router.push('/menu');

                    break;
                }
                break;
              default:
                this.data = {};
                break;
            }
          })
          .catch((e) => {
            console.log(e);
          })
    },
    showModalDelete() {
      this.visible = true;
    },
    handleOk(e) {
      this.confirmLoading = true;
      this.delete({id: this.form.id});
      this.visible = false;
      this.confirmLoading = false;
    },
    handleCancel(e) {
      this.visible = false;
    },
    open_modifier(dishID,modGroupID,modID){
      switch(this.$route.meta.layout){
        case 'administrator':
          this.goTo('/applications/'+this.$route.params.id+'/category/'+this.$route.params.catID+'/dish/'+dishID+'/modifier-group/'+modGroupID+'/modifier/'+modID)
          break;
        case 'manager':
          this.goTo('/menu/category/'+this.$route.params.catID+'/dish/'+dishID+'/modifier-group/'+modGroupID+'/modifier/'+modID)
          break;
      }
    },
    open_modifier_group(dishID,modGroupID){
      switch(this.$route.meta.layout){
        case 'administrator':
          this.goTo('/applications/'+this.$route.params.id+'/category/'+this.$route.params.catID+'/dish/'+dishID+'/modifier-group/'+modGroupID);
          break;
        case 'manager':
          this.goTo('/menu/category/'+this.$route.params.catID+'/dish/'+dishID+'/modifier-group/'+modGroupID);
          break;
      }
    },
    open_size(dishID,sizeID){
      switch(this.$route.meta.layout){
        case 'administrator':
          this.goTo('/applications/'+this.$route.params.id+'/category/'+this.$route.params.catID+'/dish/'+dishID+'/size/'+sizeID)
          break;
        case 'manager':
          this.goTo('/menu/category/'+this.$route.params.catID+'/dish/'+dishID+'/size/'+sizeID)
          break;
      }
    },
    handleFileUpload(){
      this.file = this.$refs.file.files[0];
    }
  },
  created() {
    this.form.id = this.$route.params.dishID
    this.form.menu_category_id = this.$route.params.catID;

    if (this.$route.params.dishID !== undefined){
      this.get_data(this.$route.params.dishID);
    }
  },

};
</script>

<style scoped lang="scss">
.ant-form-item-label{
  line-height: inherit!important;
}
.ant-form-item{
  margin-bottom: 0;
}
.buttons_container{
  margin: 15px 0!important;
  & .ant-col{
    padding: 0!important;
    &:nth-child(1){
      padding-right: 20px!important;
    }
  }
}
.buttons_container .ant-btn-primary, .buttons_container .ant-btn-danger{
  width: 100%!important;
  margin: 0!important;
}
</style>
<style scoped lang="scss">
.content_columns{
  margin-top: 25px;
}
.create{
  vertical-align: top;
  background-color: #fafafa;
  border: 1px dashed #d9d9d9;
  border-radius: 4px;
  cursor: pointer;
  transition: border-color 0.3s ease;
  width: 100%;
  padding: 15px;
  font-weight: bold;
  text-align: center;
}
.info{
  color: #000;
}
.create, .info{
  background-color: #fafafa;
  border: 1px dashed #d9d9d9;
  font-size: 80%;
  border-radius: 4px;
  cursor: pointer;
  width: 100%;
  padding: 15px 10px;
  line-height: 1.2;
  transition: border-color 0.3s ease;
  margin-top: 10px;
}
.create{
  text-align: center;
}
.white_background{
  background: #fff;
}
.info{
  &:hover{
    background-color: rgba(24, 144, 255, 0.15);
    border: 1px dashed #1890FF;
  }
}
.create{
  text-align: center;
  &:hover{
    background-color: rgba(24, 144, 255, 0.15);
    color: #1890FF;
    border: 1px dashed #1890FF;
    text-decoration: underline;
  }
}
.preview_container{
  width: 100%; height: 100%;padding: 10px;
  & .dish_container{
    width: 164px;
    height: 198px;
    margin: 10px auto;
    display: block;
    background: #FFFFFF;
    border-radius: 14px;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.15);
    position: relative;
    & .dish_image{
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      height: 97px;
      border-radius: 14px 14px 0 0;
    }
    & .dish_content{
      padding: 9px 16px 0 16px;
      & .header{
        font-family: 'SF UI Text';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: #000000;
        max-height: 34px;
        width: 100%;
        display: block;
        overflow: hidden;
      }
      & .text{
        font-family: 'SF UI Text';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 13px;
        margin: 5px 0;
        width: 100%;
        display: block;
      }
    }

    & .add_content{
      bottom: 0;
      padding: 0 16px 10px 16px;
      display: flex;
      position: absolute;
      width: 100%;

      & .price{
        font-family: 'SF UI Text';
        font-style: normal;
        font-weight: 500;
        color: #000000;
        font-size: 13px;
        line-height: 33px;
        flex: auto;
      }

      & .add_btn{
        flex: none;
      }
    }
  }
}

</style>