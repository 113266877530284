export default function(value) {
    let textStatus = '';
    switch (value){
        case 0:
            textStatus = ''
            break;
        case 1:
            textStatus = 'Новый';
            break;
        case 10:
            textStatus = 'Принят';
            break;
        case 20:
            textStatus = 'Готов';
            break;
        case 30:
            textStatus = 'Передан курьеру';
            break;
        case 40:
            textStatus = 'Выдан клиенту';
            break;
        case 50:
            textStatus = 'Отменен';
            break;
    }
    return textStatus;
}