<template>
  <AddEditSizes></AddEditSizes>
</template>
<script>
import AddEditSizes from '../../../../components/Menu/add-edit-sizes'
export default {
  components: {
    AddEditSizes
  }
}
</script>

