<template>
    <SettingsBasic></SettingsBasic>
</template>

<script>
import SettingsBasic from '@/components/Settings/SettingsBasic';
export default {
  components: {
    SettingsBasic
  }
}
</script>

<style scoped>

</style>