<template>
  <div>
    <a-row type="flex" :gutter="24" class="contacts_block">
      <a-col :span="24" :md='12' :lg="12">
        <h2>Единый номер:</h2>
        <h3>{{ phone }}</h3>
      </a-col>
      <a-col :span="24" :md='12' :lg="12" class="operation_time">
        <h2>Время работы:</h2>
        <p><b>пн-чт:</b> 9:00 - 18:00</p>
        <p><b>пт:</b> 9:00 - 17:00</p>
        <p><b>сб-вс:</b> выходной</p>
      </a-col>
      <a-col :span="24" :md='12' :lg="12">
        <h2>Техническая поддержка:</h2>
        <p class="support_info">Техподдержка работает круглосуточно по единому номеру телефона {{ phone }} и электронной почте</p>
        <p class="support_email"><a :href="'mailto:'+ support ">{{ support }}</a></p>
      </a-col>
      <a-col :span="24" :md='12' :lg="12">
        <h2>Сообщить о нарушении:</h2>
        <p class="abuse_email"><a :href="'mailto:'+ abuse ">{{ abuse }}</a></p>
      </a-col>
      <a-col :span="24" :md='24' :lg="24">
        <h2>Наш адрес:</h2>
        <p class="address_text">{{ address }}</p>
        <yandex-map
            :coords="coords"
            :zoom="17"
            style="height: 400px"
            :controls="['zoomControl']"
        >

        </yandex-map>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import { yandexMap, ymapMarker } from 'vue-yandex-maps'
import Config from "@/config";

export default {
  data: () => ({
    phone: Config.contacts.phone,
    abuse: Config.contacts.abuse,
    support: Config.contacts.support,
    address: Config.contacts.address,
    coords: Config.contacts.coords,
    settings:  {
      apiKey: '3ada3000-4fe5-4450-8bd0-9840db3bf539',
      lang: 'ru_RU',
      coordorder: 'latlong',
      version: '2.1'
    }
  }),
  components: { yandexMap, ymapMarker }
};
</script>

<style scoped lang="scss">
.contacts_block{
  & > div {
    padding: 10px 15px;
    &.operation_time{
      & > p{
        font-size: 14px;
        line-height: 24px;
        font-weight: normal;
        letter-spacing: 0;
        & > b{
          font-size: 14px;
          line-height: 24px;
          color: rgba(51, 57, 76, 0.75);
          font-weight: bold;
          text-transform: uppercase;
          letter-spacing: 0;
        }
      }
    }
    & p{
      font-size: 15px;
      &.support_info{
        padding-right: 100px;
      }
      &.support_email{
        margin-top: 10px;
        font-size: 18px;
      }
      &.abuse_email{
        margin-top: 10px;
        font-size: 18px;
      }
      &.address_text{
        margin-bottom: 15px;
      }
    }
    & h2{
      line-height: 20px;
      color: #000000;
      font-weight: bold;
      letter-spacing: 0;
      margin-top: 20px;
      margin-bottom: 20px;
      display: block;
      font-size: 20px;
    }
  }

}
</style>