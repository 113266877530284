<template>
  <div>
    <a-form-item label="Размер кешбека (%)" >
      <a-input type="number" min="0" max="100" v-model="data.amount" @change="this.changeData"/>
    </a-form-item>
    <p class="mb-35 font-italic">Крайне не рекомендуем часто менять условия начисления кешбека пользователя!</p>
  </div>
</template>

<script>
import {debounce} from "@/helpers";

export default {
  props: ['data'],
  methods: {
    changeData: debounce(function (e) {
      this.$root.$emit('changeDataCashback');
    }, 750),
  }
}
</script>

<style scoped>

</style>