<template>
  <DeletePayment></DeletePayment>
</template>

<script>
import DeletePayment from '@/components/Reports/DeletePayment';

export default {
  components: {
    DeletePayment
  }
}
</script>

<style scoped>

</style>