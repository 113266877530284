<template>
  <div>
    <a-row type="flex">
      <a-col :md="12"></a-col>
    </a-row>
    <a-row type="flex" :gutter="24">
      <a-col :span="24" :lg="8" class="mb-24">
        <template>
          <a-input :placeholder="TextLocalization.Application[language]"  v-model="filters.application_name"/>
        </template>
      </a-col>
      <a-col :span="24" :lg="6" class="mb-24">
        <template>
          <a-input :placeholder="TextLocalization.Company[language]"  v-model="filters.company_name"/>
        </template>
      </a-col>
      <a-col :span="24" :lg="6" class="mb-24">
          <a-input-group compact style="width: 100%">
            <a-select default-value="Option1" v-model="filters.budget_sign" style="width: 30%!important;">
              <a-select-option value="Option1">
                {{ TextLocalization.More[language]}}
              </a-select-option>
              <a-select-option value="Option2">
                {{ TextLocalization.Smaller[language]}}
              </a-select-option>
              <a-select-option value="Option3">
                {{ TextLocalization.Equals[language]}}
              </a-select-option>
            </a-select>
            <a-input style="width: 70%"  v-model="filters.budget_amount"/>
          </a-input-group>
      </a-col>

      <a-col :span="24" :lg="4" class="mb-24">
        <a-button type="primary" style="width: 100%" @click="goTo('/applications/0')">
          {{ TextLocalization.CreateAMobileApp[language] }}
        </a-button>
      </a-col>
    </a-row>
    <a-table :columns="columns" :data-source="filtered_data" :pagination="false">


    <template slot="application" slot-scope="application">
      <h6 class="m-0">
        <img :src="application.logo" width="25" style="margin-right: 10px;">
        {{ application.name }}
      </h6>
    </template>

    <template slot="company" slot-scope="company">
      <h6 class="m-0">
        {{ company.name }}
      </h6>
    </template>


    <template slot="budget" slot-scope="budget">
      <h6 class="m-0" :class="budget > 0?'ok':'budget_error'">
        {{ formatter(budget) }}
      </h6>
    </template>

    <template slot="editBtn" slot-scope="row">
      <router-link type="link" :data-id="row.key" :to="'/applications/'+row.id">
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path class="fill-gray-7" d="M13.5858 3.58579C14.3668 2.80474 15.6332 2.80474 16.4142 3.58579C17.1953 4.36683 17.1953 5.63316 16.4142 6.41421L15.6213 7.20711L12.7929 4.37868L13.5858 3.58579Z"/>
          <path class="fill-gray-7" d="M11.3787 5.79289L3 14.1716V17H5.82842L14.2071 8.62132L11.3787 5.79289Z"/>
        </svg>
      </router-link>
    </template>

  </a-table>
  </div>

</template>
<script>
import ApplicationApi from "../../../api/ApplicationApi";
import Config from "@/config";


export default {

  data() {
    return {
      data: [],
      filtered_data: [],
      columns: [
        {
          title: Config.TextLocalization.Application[(this.$store.getters["User/getUserLanguage"])],
          dataIndex: 'application',
          scopedSlots: { customRender: 'application' },
        },
        {
          title: Config.TextLocalization.Company[(this.$store.getters["User/getUserLanguage"])],
          dataIndex: 'company',
          scopedSlots: { customRender: 'company' },
        },
        {
          title: Config.TextLocalization.actualBalance[(this.$store.getters["User/getUserLanguage"])],
          dataIndex: 'budget',
          scopedSlots: { customRender: 'budget' },
        },
        {
          title: '',
          scopedSlots: { customRender: 'editBtn' },
          width: 50,
        },
      ],
      filters:{
        company_name: null,
        application_name: null,
        budget_sign: null,
        budget_amount: null
      }
    };
  },
  mounted() {
    this.get_all();
  },
  watch:{
    filters: {
      handler(val){
        this.filterData();
      },
      deep: true
    }
  },
  methods: {
    filterData: function (){
      let data = this.data;
      if(this.filters.budget_amount){
        switch (this.filters.budget_sign){
          case 'Option1':
            data = data.filter(app => app.budget > this.filters.budget_amount)
            break;
          case 'Option2':
            data = data.filter(app => app.budget < this.filters.budget_amount)
            break;
          case 'Option3':
            data = data.filter(app => app.budget === this.filters.budget_amount)
            break;
        }
      }

      if(this.filters.application_name){
          data = data.filter(app => ((app.application.name).toLowerCase()).indexOf((this.filters.application_name).toLowerCase()) !== -1);
      }
      if (this.filters.company_name){
        data = data.filter(app => ((app.company.name).toLowerCase()).indexOf((this.filters.company_name).toLowerCase()) !== -1);
      }

      this.filtered_data = data;
    },
    get_all: function (){
      ApplicationApi.get_all()
          .then(response => {
            response.data.status === 401 ? this.$store.commit('User/EXIT_USER') : null;
            switch (response.data.status){
              case 200:
                this.data = response.data.data;
                this.filtered_data = this.data;
                break;
              case 404:
                this.data = [];
                break;
              default:
                this.data = [];
                break;
            }
          })
          .catch((e) => {
            console.log(e);
          })
    },
    goTo: function (link){
      this.$router.push(link);
    }
  }
};
</script>

<style>
.ant-form-item-label{
  line-height: inherit!important;
}
.ant-form-item{
  margin-bottom: 0;
}
.buttons_container .ant-btn-primary, .buttons_container .ant-btn-danger{
  width: 100%!important;
  margin: 0!important;
}
</style>

<style scoped>
.budget_error{
  color: red;
  font-weight: bold;
}
</style>